import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { set_mouseoversidebar_data } from '../../../../core/data/redux/action';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';

const ProviderSidebar = () => {
  const routes = all_routes;

  const location = useLocation();
  const toggle_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const dispatch = useDispatch();
  const [subdroptoggle, setsubdroptoggle] = useState(false);
  const [subdroptoggle2, setsubdroptoggle2] = useState(false);
  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };
  const activeRouterPath2 = () => {
    return location.pathname.includes('settings');
  };
  // useEffect(() => {
  //   activeRouterPath
  // }, [])

  const toogle = () => {
    dispatch(set_mouseoversidebar_data(toggle_data ? false : true));
  };
  return (
    <div
      onMouseEnter={toogle}
      onMouseLeave={toogle}
      className="sidebar"
      id="sidebar"
    >
      <div className="sidebar-inner slimscroll">
        <Scrollbars>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li
                className={`${
                  activeRouterPath('/providers/dashboard') ? 'active' : ''
                }`}
              >
                <Link to="/providers/dashboard">
                  <Icon.Grid className="react-feather-icon" />{' '}
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-service')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-service">
                  <Icon.Briefcase className="react-feather-icon" />{' '}
                  <span>My Services</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-booking')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-booking">
                  <Icon.Calendar className="react-feather-icon" />{' '}
                  <span>Bookings </span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-payout') ? 'active' : ''
                }`}
              >
                <Link to={'/providers/provider-payout'}>
                  <Icon.CreditCard className="react-feather-icon" />{' '}
                  <span>Payout</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-availability')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-availability">
                  <Icon.Clock className="react-feather-icon" />{' '}
                  <span>Availability</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-holiday')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-holiday">
                  <Icon.Calendar className="react-feather-icon" />{' '}
                  <span>Holidays &amp; Leave</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-coupons')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-coupons">
                  <Icon.Bookmark className="react-feather-icon" />{' '}
                  <span>Coupons</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-subscription')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-subscription">
                  <Icon.DollarSign className="react-feather-icon" />
                  <span>Subscription</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-offer') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-offer">
                  <Icon.Percent className="react-feather-icon" />{' '}
                  <span>Offers</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-review') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-review">
                  <Icon.Star className="react-feather-icon" />{' '}
                  <span>Reviews</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-earnings')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-earnings">
                  <Icon.DollarSign className="react-feather-icon" />{' '}
                  <span>Earnings</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-chat') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-chat">
                  <Icon.MessageCircle className="react-feather-icon" />{' '}
                  <span>Chat</span>
                </Link>
              </li>
              <li className={`submenu ${activeRouterPath2() ? 'active' : ''}`}>
                <Link
                  to="#"
                  onClick={() => setsubdroptoggle(!subdroptoggle)}
                  className={`${subdroptoggle ? 'subdrop' : ''}`}
                >
                  <Icon.Settings className="react-feather-icon" />{' '}
                  <span>Settings</span> <span className="menu-arrow" />
                </Link>
                <ul style={{ display: subdroptoggle ? 'block' : 'none' }}>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-appointment-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-appointment-settings">
                      Appointment Settings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-profile-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-profile-settings">
                      Account Settings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-social-profile',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-social-profile">
                      Social Profiles
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-security-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-security-settings">
                      Security Setting
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath('/providers/settings/provider-plan')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-plan">
                      Plan &amp; Billings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath('/providers/settings/payment-setting')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/payment-setting">
                      Payment Settings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-notification',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-notification">
                      Notifications
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-connected-apps',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-connected-apps">
                      Connected Apps
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-appointment-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/verification">
                      Profile Verification
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={routes.homeOne}>
                  <Icon.LogOut className="react-feather-icon" />{' '}
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
            <div className="menu-bottom">
              <div className="menu-user">
                <div className="menu-user-img avatar-online">
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-02.jpg"
                    alt="user"
                  />
                </div>
                <div className="menu-user-info">
                  <h6>John Smith</h6>
                  <p>johnsmith@example.com</p>
                </div>
              </div>
              <Link
                to="#"
                onClick={() => setsubdroptoggle2(!subdroptoggle2)}
                className={` select-set ${subdroptoggle2 ? 'subdrop' : ''}`}
              >
                <Icon.Settings className="react-feather-icon" />
              </Link>
              {/* <div className="dropdown-menu user-drop" id="dropboxes" style={{display:subdroptoggle2?'block':'none'}}>
                <div className="menu-user">
                  <div className="menu-user-img avatar-online">
                    <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" />
                  </div>
                  <div className="menu-user-info">
                    <h6>John Smith</h6>
                    <p>Active</p>
                  </div>
                </div>
                <div className="set-user">
                  <p>Set as Away</p>
                  <div className="status-toggle">
                    <input type="checkbox" id="active-user" className="check" />
                    <label htmlFor="active-user" className="checktoggle">
                      checkbox
                    </label>
                  </div>
                </div>
                <ul className="set-menu">
                  <li>
                    <Link to="/provider-security-settings">
                    <Icon.Settings className="react-feather-icon me-2" />  Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="/provider-profile-settings">
                    <Icon.User className="react-feather-icon me-2" /> Your Account
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                    <Icon.CheckCircle className="react-feather-icon me-2" /> Identity
                      Verification
                    </Link>
                  </li>
                </ul>
                <ul className="help-menu">
                  <li>
                    <Link to="#">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/terms-condition">Terms of Condition</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </Scrollbars>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProviderSidebar;
