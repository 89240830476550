import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './style/bookinglogin.css';
import { TKN_OTPInput } from '../../../components/inputfields/input';
import Footer from '../../../components/footer/footer';
import { validateEmptyValuenumber, validateOTP } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import en from '../../../../en/en';
import { TitleSubtitleDescription } from '../../../components/about_us/about_us_extra';
import { AccountHaveComponent } from '../../../components/login/login_component';
import { ButtonTextOnly_BlueoverlayLinkTag } from '../../../components/buttons/button';


const ForgotPasswordOtp = () => {

    const routes = all_routes;
    const navigateTo = useNavigate();

    const userdetails = useSelector((state: RootState) => state.useremail);

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const email = userdetails.email;
    // const Userrole = Vendordetails.userrole;

    const handleSubmit = () => {
        let isValid = true;

        const fieldsToValidate = [
            { value: otp, errorSetter: setError, validationFunction: [validateEmptyValuenumber, validateOTP], fieldName: 'OTP' },
        ];

        fieldsToValidate.forEach((field) => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error || '');
                    isValid = false;
                    break;
                }
            }
        });

        if (isValid) {
            setLoading(true);
            sendOTPVerificationRequest();
        }
    };

    const sendOTPVerificationRequest = () => {
        const payload = otpRequest();

        apiService.registerotp(payload)
            .then((response: any) => {

                getOtpResponse(response?.data);
            })
            .catch((error: any) => {
                console.error('Error fetching User details', error);
                setError('Failed to fetch user details');
            });
    };

    const otpRequest = () => ({
        userid: userdetails.userid,
        user_otp: otp,
        email: userdetails.email,
    });

    const getOtpResponse = (response: any) => {

        if (response) {

            const status = response?.status;
            if (status && status === true) {

                const userId = response?.data?.userid;

                if (userId) {

                    navigateTo(routes.resetPassword)
                }

            } else {

                setError(en.INVALID_OTP);
            }
        }
    };

    const handleOtpChange = (Otpvalue: any) => {

        setOtp(Otpvalue)
        if (error) {

            setError('')
        }
    }
    const OtpDescription = [
        'An OTP has been sent to your email address.',
        'If it is not in your inbox, please check your spam folder.'
    ]
    return (
        <>
            <PagesAuthHeader />
            <div className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 mx-auto">
                            <div className="login-wrapotp otp-wrap">
                                <TitleSubtitleDescription title='OTP Verification' description={OtpDescription} alignment='center' classname='otp-titlesubtitle-custom' descriptionclassname='otp-description-custom' />
                                <div className="col-md-12" style={{ paddingBottom: "15px" }}>
                                </div>
                                <form action={routes.login}>
                                    <div className="form-group">
                                        <div className="col-md-11">

                                            <div className="d-flex passcode-wrap digit-group passcode-verified d-flex flex-column align-items-center">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={handleOtpChange}
                                                    numInputs={6}
                                                    inputStyle={{
                                                        width: '2rem',
                                                        height: '3rem',
                                                        margin: '0 0.5rem',
                                                        fontSize: '1.5rem',
                                                        borderRadius: '4px',
                                                        border: '1px solid rgba(0,0,0,0.3)',
                                                    }}
                                                    renderSeparator={<span> </span>}
                                                    renderInput={(props) => <input {...props} className='passcode-input-custom' />}
                                                />
                                                {error && <div className="errorText d-flex flex-column align-items-center">{error}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <AccountHaveComponent title='Didn&apos;t get the OTP?' linktext='Resend OTP' classname='hide' />
                                    <div className="col-md-12 d-flex flex-column align-items-center ">
                                        <ButtonTextOnly_BlueoverlayLinkTag label={'Verify & Proceed'} fullWidth={false} className='btn w-50 btn-verifypass-custom custom-text-button btn-custom-center signupheight-custom' route='' onClick={handleSubmit} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ForgotPasswordOtp;
