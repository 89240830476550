import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import { Modal } from 'react-bootstrap';
import './downloadapp.css'

const DownloadAppSection = () => {

    const [showModalAndroid, setShowModalAndroid] = useState(false);
    const [showModalIos, setShowModalIos] = useState(false);
    const handleCloseButtonAndroid = () => {
        setShowModalAndroid(false)
    }
    const handleCloseButtonIos = () => {
        setShowModalIos(false)
    }
    const handleShowModalAndroid = () => {
        setShowModalAndroid(true)
    }
    const handleShowModalIos = () => {
        setShowModalIos(true)
    }
    return (
        <section className="app-section">
            <div className="container">
                <div className="app-sec">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="col-md-12">
                                <div className="heading aos" data-aos="fade-up">
                                    <h2 className='home-heading-custom'>Download Our App</h2>
                                    <p className='home-para-custom  text-align-justify' >
                                        From avoiding queues to securing preferred time slots,
                                        Take A No ensures that convenience is not just a
                                        feature but a way of life.
                                    </p>
                                    <p className='home-para-custom  text-align-justify'>Skip the queues, book your preferred time slots, and take control of your day with Take A No Whether you’re managing appointments, securing your spot at popular venues, or simply staying organized, our app makes life easier.</p>
                                    <p className='home-para-custom  text-align-justify'>Don’t let waiting lines or scheduling conflicts disrupt your day. Download the app now and make convenience part of your everyday routine!</p>
                                </div>
                            </div>
                            <div className="downlaod-btn aos margin-t-30px download-btn_custom" data-aos="fade-up">
                                <Link to="#" onClick={handleShowModalAndroid}>
                                    <ImageWithBasePath
                                        src="assets/img/googleplay.svg"
                                        alt="img"
                                        className='googleplay-custom-btn'
                                    />
                                </Link>
                                <Link to="#" onClick={handleShowModalIos}>
                                    <ImageWithBasePath
                                        src="assets/img/appstore.svg"
                                        alt="img"
                                        className='apple-custom-btn'

                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="appimg-set aos custom-appimg" data-aos="fade-up">
                                <ImageWithBasePath
                                    src="assets/img/TKN_images/app-img.png"
                                    className="img-fluid "
                                    alt="img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModalAndroid} backdrop="static" keyboard={false} className='modal-centered' onHide={handleCloseButtonAndroid}>
                <Modal.Header closeButton className='border-none' >
                    <Modal.Title className='color-title'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-height'>
                    <div className="modal-body1">
                        <a href="https://play.google.com/store/apps/details?id=com.safa.takeano.consumer" target='_blank' rel='noreferrer' onClick={handleCloseButtonAndroid}>
                            <div className="choose-signup flex-fill transition-new-style" >
                                <h6>Customer</h6>
                                <div className="choose-img-custom">
                                    <ImageWithBasePath src="assets/img/user.png" alt="image" className='img-choose-custom' />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="modal-body2">
                        <a href="https://play.google.com/store/apps/details?id=com.safa.takeano.vendor" target='_blank' rel='noreferrer' onClick={handleCloseButtonAndroid}>
                            <div className="choose-signup flex-fill transition-new-style" >
                                <h6>Vendor</h6>
                                <div className="choose-img-custom">
                                    <ImageWithBasePath src="assets/img/vendor.png" alt="image" className='img-choose-custom' />
                                </div>
                            </div>
                        </a>
                    </div>
                </Modal.Body>
            </Modal >
            <Modal show={showModalIos} backdrop="static" keyboard={false} className='modal-centered' onHide={handleCloseButtonIos}>
                <Modal.Header closeButton className='border-none' >
                    <Modal.Title className='color-title'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body-height'>
                    <div className="modal-body1">
                        <a href="https://apps.apple.com/in/app/take-a-no/id6590624562" target='_blank' rel='noreferrer' onClick={handleCloseButtonIos}>
                            <div className="choose-signup flex-fill transition-new-style" >
                                <h6>Customer</h6>
                                <div className="choose-img-custom">
                                    <ImageWithBasePath src="assets/img/user.png" alt="image" className='img-choose-custom' />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="modal-body2">
                        <a href="https://apps.apple.com/in/app/take-a-no-vendor/id6596763844" target='_blank' rel='noreferrer' onClick={handleCloseButtonIos}>
                            <div className="choose-signup flex-fill transition-new-style" >
                                <h6>Vendor</h6>
                                <div className="choose-img-custom">
                                    <ImageWithBasePath src="assets/img/vendor.png" alt="image" className='img-choose-custom' />
                                </div>
                            </div>
                        </a>
                    </div>
                </Modal.Body>
            </Modal >
        </section >
    );
};

export default DownloadAppSection;