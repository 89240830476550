import React, { useEffect, useState } from 'react';
import AllRoutes from './router/router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  set_current_route,
  set_current_route_array,
  set_is_mobile_sidebar,
  setUserLocationDetails,
  setSearchRequestData,
  setSessionTokenDetails,
} from '../core/data/redux/action';
import Progress from './frontend/common/progress/progress';
import {
  AppState,
  CurrentRoute,
  Pageinfos,
  ProviderEarningsadmindatas,
} from '../core/models/interface';
import fetchCurrentLocation from '../helper/location';
import initialState from '../core/data/redux/initial.values';
import { SessionTokenDetails } from '../core/data/redux/initial.values';
import { RootState } from '../core/data/redux/store';
import { v4 as uuidv4 } from 'uuid';
import apiService from '../api/authentication-service';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const MAX_ATTEMPTS = 5;

const initialOptions = {
  clientId: "test",
  enableFunding: "venmo",
  currency: "USD",
  dataPageType: "product-details",
  components: "buttons",
  dataSdkIntegrationSource: "developer-studio",
  // intent: "capture",
};

const Feature = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const toggle_data = useSelector(
    (state: ProviderEarningsadmindatas) => state.ProviderEarningsAdmin,
  );
  const toggle_data_2 = useSelector((state: AppState) => state.toggleSidebar2);
  const mouse_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const current_route = useSelector((state: Pageinfos) => state.current_route);
  const mobileSidebar = useSelector((state: AppState) => state.mobileSidebar);
  const current_route_array = useSelector(
    (state: CurrentRoute) => state.current_route_array,
  );


  const [locationRequestCounter, setLocationRequestCounter] = useState(1);
  const [statuskeyData, setstatuskeyData] = useState<number | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  const getRoutes = () => {
    setCurrentRoute(location.pathname);
    const splitVal = location.pathname.split('/');
    const route_data = {
      base: splitVal[1],
      page: splitVal[2] || '',
      last: splitVal[3] || '',
    };

    dispatch(set_current_route_array(splitVal));
    dispatch(set_current_route(route_data));
    dispatch(set_is_mobile_sidebar(false));
  };

  useEffect(() => {
    getRoutes();
  }, [location.pathname, currentRoute]);

  const userLocationData = useSelector((state: RootState) => state.userLocationDetails);
  const searchRequestData = useSelector((state: RootState) => state.searchRequestData);
  const sessionTokenData = useSelector((state: RootState) => state.sessionTokenDetails);

  let hasRun = false;

  useEffect(() => {

    const checkSessionAndFetchData = async () => {

      // if (locationRequestCounter && !hasRun) {
      if (locationRequestCounter && !hasRun && retryCount < MAX_ATTEMPTS) {

        hasRun = true;

        if (userLocationData && sessionTokenData) {
          const $CheckToken = sessionTokenData.token;
          const sessionId = await sendsessionkeycheckRequest($CheckToken);

          if (sessionId) {

            console.log("Valid session ID:", sessionId);

          } else {

            console.log("Invalid session, fetching new user data...");
            await fetchAndUpdateUserCurrentUserDetails();
          }
        } else {

          await fetchAndUpdateUserCurrentUserDetails();
        }

        setLocationRequestCounter(0);

      } else if (retryCount >= MAX_ATTEMPTS) {
        console.log("Max attempts reached. Stopping further attempts.");
      } else {
        console.log('No more requests');
      }
    };

    checkSessionAndFetchData();
  }, []);


  const sendsessionkeycheckRequest = async ($CheckToken: any): Promise<number | null> => {


    const payload = { session_key: $CheckToken };

    try {
      const response = await apiService.checkkey(payload);

      return viewstatusdata(response);
    } catch (error) {
      console.log('Error checking session key:', "Unknown error");
      return null;
    }
  };

  const viewstatusdata = async (response: any) => {
    if (response) {
      const status = response?.status;
      const message = response?.message;
      const responseData = await response.data;


      if (Array.isArray(responseData.data) && responseData.data.length > 0) {

        const sessionId = responseData.data[0].id;
        setstatuskeyData(sessionId);
        return sessionId;

      } else {

        console.log('Data is empty or invalid');
        return null;
      }

    } else {
      console.log('Error:', response?.data || 'Unknown Error');
      return null;
    }
  };


  const addsessionkeyrequests = (userLocationDetails: any, token: any) => {

    const payload = {
      session_key: token,
      location_data: JSON.stringify({
        latitude: userLocationDetails?.latitude,
        longitude: userLocationDetails?.longitude,
        place_name: userLocationDetails?.place_name
      }),
    };


    apiService.addkey(payload)
      .then((response) => {
        addKeyResponse(response);
      })
      .catch((error) => {
        console.log('error', error.response);
      });
  };


  const addKeyResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      if (status && status === true) {

        const response_message = response?.message;


      } else {
        console.log('Error:', response?.data || 'Unknown Error');
      }
    } else {
      console.log('Error:', 'Unknown Error');
    }
  };


  const generateUniqueToken = () => {
    return uuidv4(); // Generates a unique token (UUID v4)
  };

  const fetchAndUpdateUserCurrentUserDetails = async () => {

    try {

      dispatch(setUserLocationDetails(initialState.userLocationDetails));
      dispatch(setSearchRequestData(initialState.searchRequestData));

      const userLocationDetails = await fetchCurrentLocation();

      if (!userLocationDetails || !userLocationDetails.latitude || !userLocationDetails.longitude) {
        throw new Error('Location data fetch failed');
      }
      // Reset retry count on success
      setRetryCount(0);

      dispatch(setUserLocationDetails(userLocationDetails));

      const token = generateUniqueToken();

      dispatch(setSessionTokenDetails({
        token: token,
      }));


      if (!(searchRequestData?.latitude) || !(searchRequestData?.longitude) || (searchRequestData?.place_name)) {

        dispatch(setSearchRequestData({
          ...searchRequestData,
          latitude: userLocationDetails?.latitude,
          longitude: userLocationDetails?.longitude,
          place_name: userLocationDetails?.place_name
        }));
      }
      addsessionkeyrequests(userLocationDetails, token);

    } catch (error) {

      console.log(`Attempt ${retryCount + 1} failed: 'An unknown error occurred'`);
      setRetryCount((prev) => prev + 1); // Increment retry count on failure

    }
  }

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div
        className={`${(current_route?.base === 'vendors' || current_route?.base === 'customers') ? 'provider-body' : ''}${current_route_array.includes('authentication') ? 'login-body' : ''
          }${current_route?.base == 'admin' ? 'admin' : ''} ${toggle_data_2 ? 'mini-sidebar' : ''
          } ${mobileSidebar ? 'menu-opened slide-nav' : ''} ${mouse_data ? 'expand-menu' : ''
          } ${current_route?.base == 'home-five' ? 'home-page-five' : ''}`}
      >
        <div className={`main-wrapper ${mobileSidebar ? 'menu-opened' : ''}`}>
          <AllRoutes />
        </div>
        <div className={`sidebar-overlay ${toggle_data ? 'opened' : ''} ${mobileSidebar ? 'opened' : ''}`} />
        {location.pathname.includes('home') ? <Progress /> : <></>}
      </div>
    </PayPalScriptProvider>
  );
};

export default Feature;
