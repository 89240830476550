import React from 'react';
import FooterOne from '../../../components/footer-one';
import HomeHeader from '../../../components/header/home-header';
import { ContactUsComponent } from '../../../components/contact_us/contact_us_component';


const Contact_Us = () => {
    return (
        <>
            <HomeHeader type={1} />
            <ContactUsComponent />
            <FooterOne />
        </>
    );
};

export default Contact_Us;
