import { format } from "date-fns";
import { convertDateStringToISOString, convertDateToISO, getConvertedBusinessDateTime } from "./Date";
import { getCurrentTime, getTimeDifference } from "./Time";

export function checkBookingTimeCondition(dateTimeString: string, timezone: string) {


    const [datePart, timePart] = dateTimeString.split(' | ');
    const date = datePart.trim();
    const time = timePart.trim();

    const currentDate = format(getConvertedBusinessDateTime(timezone), 'yyyy-MM-dd');
    const currentTime = format(getConvertedBusinessDateTime(timezone), 'HH:mm');
    const bookedTime = getCurrentTime(time);
    const convertedDate = convertDateToISO(date)
    const givenDate = convertDateStringToISOString(convertedDate);
    const durationInMinutes = 5;

    if (givenDate === currentDate) {

        if (bookedTime !== currentTime && currentTime > bookedTime) {

            if (getTimeDifference(bookedTime, currentTime) >= durationInMinutes) {

                return true;
            } else {

                return false;
            }
        } else {

            return false;
        }
    } else {

        if (givenDate < currentDate) {

            return true;
        } else {

            return false;
        }
    }
};