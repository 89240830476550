import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import * as Icon from 'react-feather';
import { all_routes } from '../../../core/data/routes/all_routes';
import { WEB_BASE_URL } from '../../../constants/constants';
import './favoriteCardStyle.css';
import en from '../../../en/en';
import { FavoriteCardProps } from '../../../core/models/interface';
import apiService from '../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { setvendorId } from '../../../core/data/redux/action';

const FavoriteCard = (Props: FavoriteCardProps) => {
    const dispatch = useDispatch();
    const routes = all_routes;
    const [selectedItems, setSelectedItems] = useState(Array(10).fill(true));
    const [serverError, setServerError] = useState('');
    const [messagevalue, setMessagevalue] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const handleremove = (vendorId: number) => {
        sendRemoveFavouriteRequest(vendorId);
    };

    const sendRemoveFavouriteRequest = (vendorId: number) => {

        apiService.deleteUserFavourites(addRemoveFavouriteRequest(vendorId)).then((response) => {

            addRemoveFavouriteResponse(response?.data);
            if (response?.data.data == 1) {

                const responsestatus = response?.data.data;
                sendgetFavouriteRequest();
            }
        }).catch((error) => {

            console.log('error', error);
        });
    };

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    const addRemoveFavouriteRequest = (vendorId: number) => ({
        user_id: userId,
        vendor_id: vendorId,
    });

    const addRemoveFavouriteResponse = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status && status === true) {

                window.location.reload();

            } else {

                setServerError(en.SERVERERROR);
            }
        }
    };

    const sendgetFavouriteRequest = () => {

        apiService.getUserFavourites(getFavouriteRequest()).then((response) => {
            getFavouriteResponse(response?.data);
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const getFavouriteRequest = () => ({
        user_id: userId,
    });

    const getFavouriteResponse = (response: any) => {

        if (response) {

            const status = response?.status;
            if (status && status === true) {
                const vendorList = response?.data;

                setMessagevalue(vendorList);
                setOriginalData(vendorList);
            } else {
                setServerError(en.SERVERERROR);
            }
        }
    };

    return (
        <React.Fragment>
            {
                Props.favoritesArray.map((favoriteItem, index) => (
                    <div key={index} className="col-xl-4 col-md-6 fav-card-custom">
                        <div className="service-widget servicecontent favouritecontent">
                            <div className="service-img">
                                <Link
                                    to={{ pathname: routes.businessDetails }}
                                    state={{ vendor_id: favoriteItem.id }}
                                >
                                    <ImageWithBasePathWithUrl
                                        className="img-fluid serv-img"
                                        alt="Service Image"
                                        src={WEB_BASE_URL + favoriteItem.temp_name}
                                    />
                                </Link>
                                <div
                                    className="fav-item"
                                    key={1}
                                    onClick={() => handleremove(favoriteItem.id)}
                                >
                                    <Link
                                        to="#"
                                        className={`fav-icon ${selectedItems[index] ? 'selected' : ''}`}
                                        title="Unfavourite"
                                    >
                                        <Icon.Heart size={15} />
                                    </Link>
                                </div>
                            </div>
                            <div className="service-content">
                                <h3 className="title">
                                    <Link to={{ pathname: routes.businessDetails }}
                                        state={{ vendor_id: favoriteItem.id }}>{favoriteItem.bussiness_name}</Link>
                                </h3>
                                <div className="d-flex align-items-center">
                                    <div className="details-info" style={{ width: '60%' }}>
                                        <p style={{ width: '96%' }} className='fav-text-custom'>
                                            <Icon.Mail className="react-feather-custom fav-icon-custom" />
                                            <a href={`mailto:${favoriteItem.vendor_email}`} style={{ color: '#74788d' }}>
                                                {favoriteItem.vendor_email}
                                            </a>
                                        </p>
                                        <p className='fav-text-custom'>
                                            <Icon.Phone className="react-feather-custom fav-icon-custom" />
                                            <a href={`tel:${favoriteItem.mobile}`} style={{ color: '#74788d' }}>
                                                {favoriteItem.mobile}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="align-self-start serv-info" >
                                        <Link
                                            to={{ pathname: routes.businessDetails }}
                                            onClick={() => {
                                                dispatch(setvendorId(favoriteItem.id));
                                            }}
                                            className="btn btn-book custom-book-btn d-flex align-justify-center"
                                        >
                                            {en.BOOK_NOW}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </React.Fragment>
    )
};

export default FavoriteCard;