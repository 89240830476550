import React, { useEffect } from "react"
import en from "../../../en/en"
import * as Icon from 'react-feather'
import { GOOGLE_MAPS_PLACES_API } from "../../../constants/constants"
import { useLoadGoogleMapAPI } from "../../../hooks/useLoadGoogleMapAPI";

interface SearchInputProps {
    label?: boolean,
    mainContainerClassName?: string,
    searchInputClassName?: string,
    closeIconClassName?: string,
    placeHolder?: string,
    onChange: (placeName: any) => void,
    placeName: string
    inputRef: React.RefObject<HTMLInputElement>
    handleCloseButton: () => void,
    onLocationSelect: (placeName: any, lat: number, lng: number) => void,
}

const SearchInput: React.FC<SearchInputProps> = ({
    mainContainerClassName,
    searchInputClassName,
    closeIconClassName,
    placeHolder,
    label,
    onChange,
    placeName,
    inputRef,
    handleCloseButton,
    onLocationSelect
}: SearchInputProps) => {

    const isLoaded = useLoadGoogleMapAPI(GOOGLE_MAPS_PLACES_API);

    useEffect(() => {
        if (isLoaded && inputRef?.current && (window as any).google && (window as any).google.maps && (window as any).google.maps) {
            const searchBox = new (window as any).google.maps.places.SearchBox(inputRef.current);

            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places != null && places.length > 0) {
                    const place = places[0];
                    const formattedAddress = place.formatted_address || '';
                    const placeName = place.name || '';
                    const lat = place.geometry?.location?.lat();
                    const lng = place.geometry?.location?.lng();

                    onLocationSelect(formattedAddress || placeName, lat || 0, lng || 0);
                }
            });
        }
    }, [isLoaded]);

    return (
        <React.Fragment>
            <div className={mainContainerClassName}>
                <div className="form-group mb-0" style={{ position: 'relative' }}>
                    {label && <label>{en.YOUR_LOCATION}</label>}
                    <input
                        ref={inputRef}
                        type="text"
                        className={searchInputClassName || "form-control"}
                        placeholder={placeHolder || "All Countries"}
                        onChange={(e) => onChange(e.target.value)}
                        value={placeName}
                        style={{ paddingRight: '35px' }}
                    />
                    {placeName && (
                        <Icon.XCircle
                            className={closeIconClassName || "close-icon-1"}
                            onClick={handleCloseButton}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(SearchInput);
