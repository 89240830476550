import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import {
  TKN_DropdownInputvalue,
  TKN_TextInputreqired,
  TKN_EmailInputwithcheck,
  TKN_PhoneInputComponent,
} from '../../../components/inputfields/input';
import TimeSlot from '../../../components/timeSlot/timeSlot';
import { Nullable } from 'primereact/ts-helpers';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { format } from 'date-fns';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
import { TKN_modal, TKN_successmodal } from '../../../components/modal/modal';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Whiteoverlay } from '../../../components/buttons/button';
import { Spinner } from 'react-bootstrap';
import en from '../../../../en/en';
import './style.css';
import { validateEmail, validateMobileNumber } from '../../../../helper/validate';

type ErrorMessages = {
  service?: string;
  date?: string;
  selecttimeSlot?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  general?: string;
};

type Option = {
  label: string;
  value: number;
};

const ProviderWalkin: React.FC = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const [selectedOptionvalue, setSelectedOptionvalue] = useState<number | null>(null);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [value, onChange] = useState(new Date());
  const [birthDate, setBirthDate] = useState<Nullable<Date>>(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const today = new Date();
  const formattedToday = format(today, 'yyyy-MM-dd');
  const [datetosend, setDatetosend] = useState<string>(formattedToday);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [genderoption, setGenderoption] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryDetails, setCountryDetails] = useState('');
  const [countryName, setCountryName] = useState('');
  const [successmsg, setSuccessmsg] = useState('');
  const [serverError, setServerError] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dropdownKey, setDropdownKey] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const vendor = useSelector((state: RootState) => state.vendor);

  const gender: Option[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Others', value: 0 }
  ];

  const disablePreviousDates = ({ date }: { date: Date }) => {
    const todaywithouttime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const datewithouttime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return datewithouttime < todaywithouttime;
  };

  useEffect(() => {
    sendServiceRequest(formattedToday);
  }, []);

  const sendServiceRequest = (formattedNewDate: any) => {
    const payload = serviceRequest(formattedNewDate);

    apiService.getAllactiveservices(payload)
      .then((response: any) => {
        getServiceResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setErrors(error);
      });
  };

  const serviceRequest = (formattedNewDate: any) => ({
    vendor_id: vendor.id,
    booking_for: formattedNewDate
  });

  const getServiceResponse = (response: any) => {
    if (response) {
      const status = response?.status;

      if (status === true) {
        const responseData = response.data;
        const serviceOptions = responseData.map((data: any) => ({
          label: data.service_name,
          value: data.service_vendor_map_id
        }));

        if (Array.isArray(serviceOptions) && serviceOptions.length > 0) {
          setOptions(serviceOptions);
          setErrors((prevErrors) => ({ ...prevErrors, general: undefined }));
        } else {
          setErrors({ general: 'Services are not available on this date.' });
        }

      } else {
        console.error('ERROR MSG:', response?.message);
        setErrors(response?.message);
      };
    } else {
      console.error('ERROR MSG:', response?.message);
      setErrors(response?.message);
    };
  };

  const handleSelect = (selectedItem: Option) => {

    setSelectedOption(selectedItem);
    setSelectedOptionvalue(selectedItem.value);
    sendAvailabeserviceRequest(selectedItem.value);
  };

  const handleSelectgender = (selectedGender: Option) => {
    setGenderoption(selectedGender.label);

    if (errors.gender) {
      setErrors((prevErrors) => ({ ...prevErrors, gender: undefined }));
    };
  };

  const handleDateChange = (newValue: Date) => {
    const formattedNewDate = format(newValue, 'yyyy-MM-dd');

    onChange(newValue);
    setSelectedOption(undefined);
    setSelectedOptionvalue(null);
    setDropdownKey(prevKey => prevKey + 1);
    setAvailableSlots([]);
    setDatetosend(formattedNewDate);
    sendServiceRequest(formattedNewDate);
  };

  useEffect(() => {

    if (datetosend && selectedOptionvalue !== null) {
      sendAvailabeserviceRequest(selectedOptionvalue);
    };
  }, [datetosend, selectedOptionvalue]);

  const sendAvailabeserviceRequest = (selectedServiceId: number | null) => {
    if (selectedServiceId !== null) {
      const payload = AvailabeserviceRequest(selectedServiceId);

      apiService.getAvailableservicetime(payload)
        .then((response: any) => {

          getAvailabeserviceResponse(response?.data);
        })
        .catch((error: any) => {
          console.error('Error fetching User details', error);
          setErrors(error);
        });
    }
  };

  const AvailabeserviceRequest = (serviceId: number) => ({
    vendor_map_id: serviceId,
    booking_for: datetosend,
  });

  const getAvailabeserviceResponse = (response: any) => {
    if (response) {

      const status = response?.status;
      if (status === true) {
        const responseData = response.data;

        setAvailableSlots(responseData);
      }
      else {
        console.error('ERROR MSG:', response?.message);
        setErrors(response?.message);
      }
    }
    else {
      console.error('ERROR MSG:', response?.message);
      setErrors(response?.message);
    }
  };


  const handleSubmit = () => {

    const newErrors: ErrorMessages = {};


    if (!selectedOption) newErrors.service = 'Please select a service';
    if (!datetosend) newErrors.date = 'Appointment date is required';
    if (!selectedTimeSlot) newErrors.selecttimeSlot = 'Please select Appointment time';
    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (email) {
      const validationResponse = validateEmail(email, 'Email');
      if (validationResponse.status === "false") {

        newErrors.email = validationResponse.error;
      };
    };
    if (!phone) newErrors.phone = 'Phone number is required';
    if (!genderoption) newErrors.gender = 'Gender is required';

    const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;
    const phoneValidation = validateMobileNumber(MobileNumber, countryCode, 'Country code and phone number mismatch. Please verify.');

    if (phoneValidation.status === 'false') {
      newErrors.phone = phoneValidation.error;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      sendaddwalking();
    };
  };

  const handleTimeSlotSelect = (slot: string) => {


    const [timePart, modifier] = slot.split(' ');

    const [hoursStr, minutes] = timePart.split(':').map(Number); // Destructure as const
    let hours = hoursStr; // Reassign only hours if necessary

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    const timeIn24HourFormat = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    setSelectedTimeSlot(timeIn24HourFormat);

  };

  /*  const convertTo24Hour = (time: string): string => {
   
     const [timePart, modifier] = time.split(' '); 
   
     let [hours, minutes] = timePart.split(':').map(Number); 
     const constMinutes = minutes;
   
     if (modifier === 'PM' && hours !== 12) {
       hours += 12; 
     } else if (modifier === 'AM' && hours === 12) {
       hours = 0; 
     }     
     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
   }; */

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    if (errors.firstName) {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: undefined }));
    };
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    if (errors.lastName) {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: undefined }));
    }
  };

  const handleEmailChange = (value: string) => {
    const LowerCValue = value.toLowerCase();
    setEmail(LowerCValue);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  };

  const handlePhonechange = (phoneNumber: string, formattedDialCode: any, countryname: any, countrydetails: any) => {
    setPhone(phoneNumber);
    setCountryCode(formattedDialCode);
    setCountryName(countryname);
    setCountryDetails(countrydetails);
  };

  const sendaddwalking = () => {
    const payload = sendwalkingRequest();

    apiService.addWalkingbooking(payload).then((response: any) => {
      const walkResponse = response.data;
      const status = walkResponse.status;
      const message = walkResponse.message;

      if (walkResponse) {
        setIsLoading(false);

        if (status && message && (status === true)) {
          setSuccessmsg(message);
          setShowModal(true);
        } else {
          setServerError(message);
        };
      };

      if (status && message) {
        setSuccessmsg(message);
      } else {
        setServerError(message);
      };
    }).catch((error) => {
      console.log('Error', error)
    })
  };

  const sendwalkingRequest = () => ({
    first_name: firstName,
    last_name: lastName,
    email: email.toLowerCase(),
    phone: phone,
    gender: genderoption,
    country: countryName,
    country_code: countryCode,
    country_details: JSON.stringify(countryDetails),
    vendor_id: vendor.id,
    vendor_map_id: selectedOptionvalue,
    dob: birthDate ? format(birthDate, 'yyyy-MM-dd') : null,
    booking_for: datetosend,
    booking_slot: selectedTimeSlot,
  });

  const handleCancel = () => {
    navigateTo(routes.vendorDashboard)
  };

  const handleServerErrorClose = () => {
    setServerError('');
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            {serverError && <TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />}
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>{en.WALKIN_HEADING}</h6>
              </div>
            </div>
            <div className="col-md-8">
              <div className="book-title">
                <TKN_successmodal
                  title={'Walking Booking'}
                  description={successmsg}
                  okbutton={'OK'}
                  handleshowModal={showModal}
                  handleSuccessButton={() => navigateTo(routes.vendorBooking)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 walkin-date-container">
            <div className="book-title" >
              <h5 >Appointment Date</h5>
            </div>
            <div id="datetimepickershow">
              <Calendar
                onChange={(value: any) => handleDateChange(value)}
                value={value}
                className={"react-calendar-style"}
                prev2Label={null}
                next2Label={null}
                tileDisabled={disablePreviousDates}
              />
            </div>
          </div>
          <div className="col-lg-8 walkin-details-container">
            <div className="row">
              <div className="col-md-12">
                <div className="book-title">
                  <h5>Services</h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <TKN_DropdownInputvalue
                key={dropdownKey}
                placeholderText="Select a service"
                options={options}
                onSelect={handleSelect}
                descriptionError={errors.service}
              />
            </div>
            {errors.service && <div className="text-danger">{errors.service}</div>}
            {errors.general && <div className="error">{errors.general}</div>}

            <div className="row">
              <div className="col-lg-12 token-slot mt-2 " >
                <div className="appointment-title">
                  <h5>Appointment Time</h5>
                </div>
                <div className="token-slot mt-2">
                  <TimeSlot availableSlots={availableSlots} onSelectSlot={handleTimeSlotSelect} />
                  {errors.selecttimeSlot && <div className="text-danger">{errors.selecttimeSlot}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ height: '12px' }}></div>
        <div className="row ">
          <div className="col-md-6">
            <TKN_TextInputreqired onChange={handleFirstNameChange} label="First Name" placeholder='Enter first name' />
            {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
          </div>
          <div className="col-md-6">
            <TKN_TextInputreqired onChange={handleLastNameChange} label="Last Name" placeholder='Enter last name' />
            {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
          </div>
          <div className="col-md-6">
            <TKN_EmailInputwithcheck onChange={handleEmailChange} required={true} />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </div>
          <div className="col-md-6">
            <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} />
            {errors.phone && <div className="text-danger mt-2">{errors.phone}</div>}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Gender<span className="text-danger">*</span></label>
              <TKN_DropdownInputvalue
                placeholderText="Select gender"
                options={gender}
                onSelect={handleSelectgender}
                descriptionError={errors.gender}
              />
              {errors.gender && <div className="text-danger">{errors.gender}</div>}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Date of birth</label>
              <div className="form-icon cus-profile">
                <PrimeCalendar
                  value={birthDate}
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date()}
                  onChange={(e) => {
                    if (e.value) {
                      const date = new Date(e.value);
                      setBirthDate(date);
                    }
                  }}
                  placeholder="MM/DD/YYYY"
                  showIcon
                  inputClassName="p-inputtext"
                  readOnlyInput={true}
                />
              </div>
            </div>
          </div>
          <div className="walking-booking-btn d-flex justify-content-end gap-30px">
            <ButtonTextOnly_Whiteoverlay
              label={"Cancel"}
              fullWidth={false}
              onClick={handleCancel} />
            <ButtonTextOnly_Blueoverlay label={'Save'} fullWidth={false}
              onClick={handleSubmit}
              disabled={isLoading}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderWalkin;


