import React from "react";
import { Link } from "react-router-dom";
import './style.css';

interface ImageCustomprops {
    src: string;
    classname?: string;
    imgclassname?: string;
    alt?: string;
}

const ImageComponentCustom: React.FC<ImageCustomprops> = (props) => {
    return (
        <React.Fragment>
            <div className={`image-custom ${props.classname}`}>
                <img src={props.src} className={`img-custom img-fluid ${props.imgclassname}`} alt={props.alt} />
            </div>
        </React.Fragment>
    )
}

export { ImageComponentCustom };
