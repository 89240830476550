import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import {
  set_is_mobile_sidebar,
  set_toggleSidebar_data_2,
} from '../../../../core/data/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';
import { RootState } from '../../../../core/data/redux/store';
import { AppDispatch } from '../../../../core/data/redux/store';
import { logout } from '../../../../core/data/redux/action';
import { WEB_BASE_URL } from '../../../../constants/constants';
import './headerStyle.css';

const ProviderHeader = () => {
  const routes = all_routes;
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar2);
  //const dispatch = useDispatch();
  const dispatch: AppDispatch = useDispatch();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const navigateTo = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const vendor = useSelector((state: RootState) => state.vendor);
  const defaultimg = { src: 'assets/img/profiles/avatar-01.avif' }


  const handleLogout = () => {
    dispatch(logout());
    // Optional: clear local storage or any other storage
    localStorage.removeItem('UserDetails');
    if (vendor) {
      localStorage.removeItem('VendorDetails');
    }
    navigateTo('../authentication/login');

  };


  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    // Toggle the state
    setIsFullscreen(!isFullscreen);
  };
  const toogle = () => {
    dispatch(set_toggleSidebar_data_2(toggle_data ? false : true));
  };
  const mobileSidebar = useSelector((state: AppState) => state.mobileSidebar);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };
  return (
    <div className="header">
      <div className="header-left">
        <div className="sidebar-logo img-height">
          <Link to={'/'}>
            <ImageWithBasePath
              src="assets/img/TKN_images/TestingLogo.png"
              className="img-fluid logo img-margin-left img-height"
              alt="Logo"
            />
          </Link>
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              src="assets/img/TKN_images/logo-small.png"
              className="img-fluid logo-small hide"
              alt="Logo"
            />
          </Link>
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              src="assets/img/TKN_images/logo-small.png"
              className="img-fluid logo-small custom-faviconlogo"
              alt="Logo"
            />
          </Link>
        </div>
        {/* <div className="siderbar-toggle">
          <Link to="#" onClick={toogle}>
            <label className="switch" id="toggle_btn">
              <input type="checkbox" />

            </label>
          </Link>
        </div> */}
      </div>
      <Link
        className={`mobile_btns ${mobileSidebar ? 'active-custom' : 'custom_mobile_btns'}`}
        id="mobile_btns"
        to="#"
        onClick={handleClick}
      >
        <i className="fas fa-align-left align-left-icon-custom" />
      </Link>
      <div className="header-split">
        <div className="page-headers">
        </div>
        <ul className="nav user-menu noti-pop-detail ">
          {/* Notifications */}
          <li className="nav-item">
            {vendor.website && (
              <a href={vendor.website} target="_blank" rel="noopener noreferrer" className="viewsite">
                <Icon.Globe className="me-2 color-new" size={18} />
                View Site
              </a>
            )}
          </li>
          {/* Notifications */}
          <li className="nav-item  has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper">
            <Link
              to="#"
              className="dropdown-toggled nav-link notify-link new-background-color"
              data-bs-toggle="dropdown"
            >
              {/* <span className="noti-message backgroundcolor-new ">1</span> */}
              <ImageWithBasePath
                src="assets/img/icons/bell-icon.svg"
                alt="Bell"
              />
            </Link>
            <div className="dropdown-menu notify-blk notifications" style={{ height: 200 }}>
              <div className="topnav-dropdown-header">
                <div>
                  <p className="notification-title">
                    Notifications <span> 0 </span>
                  </p>
                </div>
                <Link to="#" className="clear-noti">
                  <i className="fa-regular fa-circle-check" /> Mark all as read{' '}
                </Link>
              </div>
              <div className="noti-content">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#74788D', marginTop: 8 }}>There are no notifications available.</p>
                </div>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to={'#'}>
                  View All Notifications{' '}
                  <ImageWithBasePath
                    src="assets/img/icons/arrow-right-01.svg"
                    alt="Arrow"
                  />
                </Link>
              </div>
            </div>
          </li>

          {/* /Notifications */}
          <li className="nav-item  has-arrow dropdown-heads ">
            <Link onClick={toggleFullscreen} to="#" className="win-maximize new-background-color">
              <i className="feather-maximize color-new" >
                <Icon.Maximize className="react-feather-custom" />
              </i>
            </Link>
          </li>
          {/* User Menu */}
          <li className="nav-item dropdown has-arrow account-item">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-infos">
                {user.profilephoto ? (

                  <span className="user-img">
                    <ImageWithBasePathWithUrl
                      src={`${WEB_BASE_URL}${user.profilephoto}`}
                      className="rounded-circle"
                      alt="User Image"
                    />
                  </span>) : (

                  <span className="user-img">
                    <ImageWithBasePath
                      src={defaultimg.src}
                      className="rounded-circle"
                      alt="User Image"
                      style={{ objectFit: 'cover' }}
                    />
                  </span>)}
                <div className="user-info">
                  <h6>{vendor.bussiness_name}</h6>
                  <p>{user.first_name}</p>
                </div>
              </div>
            </Link>
            <div className="dropdown-menu dropdown-menu-end emp">
              <Link
                className="dropdown-item"
                to={routes.vendorAppointmentSettings}
              >
                <i className="feather-user me-2" /> Profile
              </Link>
              <Link className="dropdown-item" onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                <i className="feather-log-out me-2" /> Logout
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
      </div>
    </div>
  );
};

export default ProviderHeader;
