import React from "react";
import PaypalCheckOutButton from "../paypalCheckOutButton/paypalCheckOutButton";
import "./checkOutSectionStyle.css";

import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { ButtonTextOnly_Whiteoverlay } from "../../components/buttons/button";

interface CheckoutSectionProps {
  planName: string;
  planPrice: number;
  planValidity: string;
  planTotalServices: number;
}


export const CheckoutSection = ({
  planName,
  planPrice,
  planValidity,
  planTotalServices
}: CheckoutSectionProps) => {


  const routes = all_routes;
  const navigate = useNavigate();

  const handleCancelButton = () => {
    navigate(routes.vendorsubscription)
  }
  return (

    <div className="checkout-container mx-auto checkout-container-custom d-flex flex-direction-column">
      <h1 className="text-align-center custom-text-heading">
        Subscription Payment
      </h1>
      <div className="d-flex flex-direction-row main-checkoutcontainer-custom">
        <div className="image-section w-50 custom-image-section" >
          <ImageWithBasePath
            src="assets/img/TKN_images/payment_image.jpg"
            className="img-fluid w-100"
          />
        </div>
        <div className="content-section content-section-custom" >
          <h5 className="subandpay-custom pay-title"  >
            Subscription & Payment Details
          </h5>

          <div className="booking-summary">
            <ul className="booking-date booking-date-custom" >
              <li>
                <span>Subscription Plan Name</span>
                <span className="planname-custom" >
                  {planName}
                </span>
              </li>
              <li>
                <span>Validity</span>
                <span className="planvalidity-custom"  >
                {`${planValidity} Month Validity`}
                </span>
              </li>
              <li>
                <span>Number of Services</span>
                <span className="planvalidity-custom">
                  {planTotalServices}
                </span>
              </li>
            </ul>
            <div className="booking-total booking-total-custom" >
              <ul className="booking-total-list booking-date-custom" >
                <li className="d-flex justify-content-spacebetween">
                  <span>Total</span>
                  <span className="total-cost planname-custom" >
                    ${planPrice}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="payment-summary payment-summary-custom">
            <div className="form-group d-flex align-items-center flex-direction-column gap-15px" >
              <div className="w-100">
                <PaypalCheckOutButton />
              </div>
              <ButtonTextOnly_Whiteoverlay
                label={"Cancel"}
                route={""}
                fullWidth={false}
                onClick={handleCancelButton}
                className="w-100 cancelbtn-checkout-custom"
              />
              <ImageWithBasePath
                src="assets/img/card-icon-1.png"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
};