import React, { useEffect, useState } from "react";
import { TKN_DropdownInput, TKN_EmailInput, TKN_InputPlaceholder, TKN_PhoneInputComponent } from "../../../components/inputfields/input";
import apiService from "../../../../api/authentication-service";
import { useSelector } from "react-redux";
import { format, parse } from 'date-fns';
import { CHAR_LIMIT_EMAIL_WEBSITE, CHAR_LIMIT_FIRST_NAME, CHAR_LIMIT_LAST_NAME } from "../../../../constants/constants";
import { validateDropdown, validateDropdown_RelationShip, validateEmail, validateEmptyValue, validateMobileNumber, validateUsername } from "../../../../helper/validate";
import { CountryDropdown } from "react-country-region-selector";
import { Calendar } from 'primereact/calendar';
import en from "../../../../en/en";
import { useNavigate } from "react-router-dom";
import { dateFormatFunction } from "../../../../helper/Date";
import { TKN_Danger_message } from "../../../components/alert_messages/alert_messages";
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Whiteoverlay } from "../../../components/buttons/button";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { Spinner } from "react-bootstrap";
import { ChevronDown } from "react-feather";

type CountryDetailsType = {
    cca2: string;
    currency: string[];
    callingCode: string[];
    region: string;
    subregion: string;
    flag: string;
    name: string;
};

const EditFamilyMember = () => {
    const routes = all_routes
    const navigateTo = useNavigate();
    const [relation, setRelation] = useState([]);
    const [selected, setSelected] = useState("");
    const [selectedRelationship, setSelectedRelationship] = useState('');
    const [relationshipError, setRelationshipError] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [mobilenumber, setMobileNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [birthDateTo, setBirthDateTo] = useState('');
    const [firstnameError, setFirstnameError] = useState('');
    const [lastnameError, setLastnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [mobilenumberError, setMobileNumberError] = useState('');
    const [genderDropdownError, setGenderDropdownError] = useState('');
    const [countryCode, setCountryCode] = useState('+1');
    const [countryDetails, setCountryDetails] = useState<CountryDetailsType | null>(null);
    const [serverError, setServerError] = useState('');
    const [selectedbirthDate, setSelectedbirthDate] = useState<any>(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [familyMemberId, setFamilyMemberId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [countryName, setCountryName] = useState('');
    const genderoptions = ['Male', 'Female', 'Other']
    const combinedRelation = [...relation];
    const famMemberId = useSelector((state: any) => {
        return state.familyMemberDetails
    });

    useEffect(() => {
        sendFamilyMemberRequest()
    }, []);

    useEffect(() => {
        sendRelationshipDataRequest();
    }, []);

    const resetResponseMessage = () => {
        setResponseMessage('');
    };

    const famMemberID = () => ({
        member_id: famMemberId
    });

    const sendFamilyMemberRequest = () => {

        if (famMemberId !== null) {

            apiService.getAllFamilyMembersDetailsById(famMemberID()).then((response) => {

                if (response) {
                    const responseData = response?.data;
                    const familyMemberDob = responseData?.data.dob ?? null;
                    let countryDetail = responseData.data.country_details;

                    setFirstname(responseData.data.first_name);
                    setLastname(responseData.data.last_name);
                    setEmail(responseData.data.email);
                    setMobileNumber(responseData.data.phone);
                    setCountryCode(responseData.data.country_code);
                    setGender(responseData.data.gender);
                    setSelected(responseData.data.relation_name);
                    setSelectedRelationship(responseData.data.relation_name);
                    setFamilyMemberId(responseData.data.family_member_id);

                    if (typeof countryDetail === 'string') {
                        try {
                            countryDetail = JSON.parse(countryDetail);
                        } catch (e) {
                            console.error('Error parsing country details:', e);
                        }
                    }
                    setSelectedCountry(responseData.data.country);
                    setCountryDetails(countryDetail);
                    setCountryName(countryDetail.name);

                    if (familyMemberDob) {
                        const dobDate = parse(familyMemberDob, 'yyyy-MM-dd', new Date());
                        const formattedDob = dateFormatFunction(dobDate);

                        setSelectedbirthDate(dobDate);
                        setBirthDateTo(formattedDob);
                    }
                }
            }).catch((error) => {
                console.log('error', error);
            });
        };
    };

    const sendRelationshipDataRequest = () => {
        apiService.getAllRelationships().then((response) => {
            const responseData = response?.data;
            setRelation(responseData.data);
        }).catch((error) => {
            console.log('error', error);
        })
    };

    const handleSubmit = () => {
        let isValid = true;
        const isDropdownValid = validateDropdown_RelationShip(selected, 'Any relationship');
        const trimmedMobileNumber = mobilenumber.startsWith(countryCode) ? mobilenumber.trim().slice(countryCode.length) : mobilenumber.trim();

        const fieldsToValidate = [
            { value: firstname, errorSetter: setFirstnameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'First name' },
            { value: lastname, errorSetter: setLastnameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'Last name' },
            { value: gender, errorSetter: setGenderDropdownError, validationFunction: [validateDropdown], fieldName: 'gender' },
        ];

        if (email.trim() !== '') {
            fieldsToValidate.push({ value: email, errorSetter: setEmailError, validationFunction: [validateEmail], fieldName: 'email' });
        }

        fieldsToValidate.forEach((field => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error);
                    isValid = false;
                    break;
                }
            }
        }));

        if (mobilenumber.trim() === '' || mobilenumber.trim() === countryCode) {
            setMobileNumberError('Phone number is required.');
            isValid = false;
        } else {
            const phoneValidation = validateMobileNumber(trimmedMobileNumber, countryCode, 'Invalid phone number for the selected country.');

            if (phoneValidation.status === 'false') {
                setMobileNumberError(phoneValidation.error);
                isValid = false;
            } else {
                setMobileNumberError('');
            };
        };

        if (selectedCountry !== countryName) {
            setMobileNumberError("Invalid phone number for the selected country.");
            isValid = false;
        }

        if (!selectedCountry) {
            setCountryError('Please select a country.');
            isValid = false;
        } else {
            setCountryError('');
        }

        if (isDropdownValid["status"] === "false") {
            setRelationshipError(isDropdownValid["error"]);
            isValid = false;
        }

        if (isValid) {
            setIsLoading(true);
            sendUpdateFamilyMemberRequest();
        };
    };

    const sendUpdateFamilyMemberRequest = () => {

        apiService.familyMembersUpdate(updateFamilyMemberRequest()).then((response) => {
            const responseData = response?.data;
            setIsLoading(false);
            updateFamilyMemberResponse(responseData);
        }).catch((error) => {
            console.log('error', error);
        })
    };


    const updateFamilyMemberResponse = (response: any) => {
        if (response) {

            const status = response?.status;
            if (status && status === true) {

                setFamilyMemberId(null)
                navigateToAddfamilyAlert();

            } else {

                let errMessage = response?.message;
                if (!errMessage) {

                    errMessage = en.DEFAULT_ERROR
                }

                setServerError(errMessage);
                console.log('ERROR', errMessage);

            }
        } else {

            setServerError(en.SERVERERROR)
        }
    };

    const navigateToAddfamilyAlert = () => {

        navigateTo(routes.customerAddMember);

    };

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    const updateFamilyMemberRequest = () => ({
        user_id: userId,
        family_member_id: familyMemberId,
        first_name: firstname,
        last_name: lastname,
        relation_name: selectedRelationship,
        gender: gender,
        dob: selectedbirthDate ? format(selectedbirthDate, 'yyyy-MM-dd') : null,
        email: email.toLowerCase(),
        phone: mobilenumber,
        country: selectedCountry,
        country_code: countryCode,
        country_details: JSON.stringify(countryDetails),
    });

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value;

        if (text.length < CHAR_LIMIT_FIRST_NAME) {
            setFirstname(text);
            setFirstnameError('');
        } else {
            setFirstnameError(en.CHAR_LIMIT);
        }
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value;
        if (text.length < CHAR_LIMIT_LAST_NAME) {
            setLastname(text);
            setLastnameError('');

        } else {
            setLastnameError(en.CHAR_LIMIT);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value;
        if (text.length < CHAR_LIMIT_EMAIL_WEBSITE) {
            setEmail(text);
            setEmailError('');
        } else {
            setEmailError(en.CHAR_LIMIT);
        }
    };

    const handleCountryChange = (val: string) => {
        resetResponseMessage();
        if (!val) {
            setCountryError('Unable to select country. Please try again.');
            setSelectedCountry(null);
        } else {
            setCountryError('');
            setSelectedCountry(val);
        }
    };

    const handlePhonechange = (value: string, formattedDialCode: any, countryname: any, countrydetails: any) => {
        resetResponseMessage();

        if (value) {
            setMobileNumberError('');
        }

        setMobileNumber(value);
        setCountryCode(formattedDialCode);
        setCountryName(countryname);
        setCountryDetails(countrydetails);
    };

    const handleServerErrorClose = () => {
        setServerError('');
    }

    return (
        <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="widget-title">
                                {serverError && <TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />}
                                <h4>Edit Member</h4>
                            </div>
                            <h6 className="user-title">General Information</h6>
                            <div className="general-info">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <TKN_InputPlaceholder placeholderText={"Enter first name"}
                                                onChange={handleFirstNameChange}
                                                maxLength={CHAR_LIMIT_FIRST_NAME}
                                                value={firstname}
                                            >
                                            </TKN_InputPlaceholder>
                                            {firstnameError && (
                                                <div className='errorText'>{firstnameError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Last Name <span className="text-danger">*</span>
                                            </label>
                                            <TKN_InputPlaceholder placeholderText={"Enter last name"}
                                                onChange={handleLastNameChange}
                                                maxLength={CHAR_LIMIT_FIRST_NAME}
                                                value={lastname}
                                            >
                                            </TKN_InputPlaceholder>
                                            {lastnameError && (
                                                <div className='errorText'>{lastnameError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TKN_EmailInput
                                                onChange={handleEmailChange}
                                                maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                                                value={email}
                                                error={emailError}>
                                            </TKN_EmailInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Country <span className="text-danger">*</span>
                                            </label>
                                            <CountryDropdown
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                classes="form-control"
                                                defaultOptionLabel={selectedCountry ? selectedCountry : 'Selectedd Country'}
                                                priorityOptions={['United States']}
                                            />
                                            <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                                            {countryError !== '' && (
                                                <div className='errorText'>{countryError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} error={mobilenumberError} value={mobilenumber} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Gender <span className="text-danger">*</span>
                                            </label>
                                            <TKN_DropdownInput placeholderText={"Select your gender"} options={genderoptions} categoryDropdownError={genderDropdownError} value={gender}
                                                onSelect={(selectedItem) => {
                                                    setGender(selectedItem);
                                                    setGenderDropdownError('');
                                                }}
                                            >
                                            </TKN_DropdownInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Relationship <span className="text-danger">*</span>
                                            </label>
                                            <TKN_DropdownInput placeholderText={"Relationship"}
                                                options={combinedRelation}
                                                categoryDropdownError={relationshipError}
                                                value={selectedRelationship}
                                                onSelect={(selectedItem: React.SetStateAction<string>) => {
                                                    if (selectedItem === 'other') {
                                                        setSelectedRelationship('other');
                                                        setSelected('other');
                                                    } else {
                                                        setSelectedRelationship(selectedItem);
                                                        setSelected(selectedItem);
                                                    }
                                                    setRelationshipError('');
                                                }}
                                            >
                                            </TKN_DropdownInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Date of birth</label>
                                            <div className="form-icon cus-profile">
                                                <Calendar
                                                    id="dob"
                                                    minDate={new Date(1900, 0, 1)}
                                                    maxDate={new Date()}
                                                    value={selectedbirthDate} // Pass the selected birth date here
                                                    onChange={(e: any) => {
                                                        const selectedDate = e.value; // Get the selected date
                                                        setSelectedbirthDate(selectedDate); // Set it in the state
                                                        const formattedDob = format(selectedDate, 'dd/MM/yyyy'); // Format the date as needed
                                                        setBirthDateTo(formattedDob); // Store the formatted date if required
                                                    }}
                                                    placeholder="MM/DD/YYYY"
                                                    showIcon
                                                    readOnlyInput={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="acc-submit d-flex justify-content-end gap-30px">
                                <ButtonTextOnly_Whiteoverlay label={"Cancel"} fullWidth={false} onClick={() => navigateTo(routes.customerAddMember)} />
                                <ButtonTextOnly_Blueoverlay label={'Save'} isLoading={isLoading} fullWidth={false} onClick={handleSubmit} disabled={isLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditFamilyMember;


