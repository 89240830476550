import { useState, useEffect } from 'react';

interface GoogleWindow extends Window {
    google?: {
        maps?: {
            places?: any;
        }
    }
}

export const useLoadGoogleMapAPI = (anyGoogleApi: string) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const waitforscriptload = (attempts: number) => {
        const intervalId = setInterval(() => {
            if ((window as GoogleWindow).google?.maps?.places) {
                setIsLoaded(true);
                clearInterval(intervalId);
            } else {
                attempts++;
                console.log('Waiting for Google Maps API to be ready...');

                if (attempts >= 5) {
                    clearInterval(intervalId);
                    console.warn('Google Maps API was not loaded after 5 attempts.');
                }
            }
        }, 100);

        return () => clearInterval(intervalId);
    }

    useEffect(() => {

        const loadGoogleMapsAPI = async () => {
            try {
                if (document.getElementById('google-maps-script')) {

                    if ((window as GoogleWindow).google?.maps?.places) {
                        setIsLoaded(true);
                    }
                    else {
                        waitforscriptload(0);
                    }

                    return;
                }

                const script = document.createElement('script');
                script.src = `${anyGoogleApi}&loading=async`;
                script.async = true;
                script.defer = true;
                script.id = 'google-maps-script';

                script.onload = () => {
                    waitforscriptload(0);
                };

                script.onerror = (error) => console.error('Error loading Google Maps API:', error);
                document.body.appendChild(script);

            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }

            // const waitForScriptLoad = () => {
            //     let attempts = 0;

            //     const intervalId = setInterval(() => {
            //         if ((window as GoogleWindow).google?.maps?.places) {
            //             setIsLoaded(true);
            //             clearInterval(intervalId);
            //         } else {
            //             attempts++;
            //             console.log('Waiting for Google Maps API to be ready...');

            //             if (attempts >= 5) {
            //                 clearInterval(intervalId);
            //                 console.warn('Google Maps API was not loaded after 5 attempts.');
            //             }
            //         }
            //     }, 100);

            //     return () => clearInterval(intervalId);
            // };
        };

        loadGoogleMapsAPI();
    }, [anyGoogleApi]);

    return isLoaded;
};
