import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';
import './provider-sub.css';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Custombtn } from '../../../components/buttons/button';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { setServiceSubscriptionDetails, setServiceSubscriptionSelect } from '../../../../core/data/redux/action';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import Loader from '../../../components/loader_spinner/loader_spinner';
import { TKN_modal } from '../../../components/modal/modal';
import PaypalCheckOutButton from '../../../components/paypalCheckOutButton/paypalCheckOutButton';


const ProviderSubscription = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const currentPackage = useSelector((state: any) => state.serviceSubscriptionDetails?.current_package ?? []);
  const currentServicesSubscriptionStatus = currentPackage?.service_subscription_status ?? 0;
  const currentServicesCount = currentPackage?.services_count ?? 0;
  const isFirstSubscription = useSelector((state: any) => state.serviceSubscriptionDetails?.is_first_subscription ?? false);

  const [serverError, setServerError] = useState('');
  const [serviceSubscriptions, setServiceSubscriptions] = useState([]);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [popupMessage, setPopupMessage] = useState<string>('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(null);
  const routes = all_routes;
  const [showModal, setShowModal] = useState(false);
  const [showloader, setShowLoader] = useState(true);

  useEffect(() => {
    sendGetAllServiceSubscriptionPackageRequest();
  }, []);

  const sendGetAllServiceSubscriptionPackageRequest = () => {
    setServerError('');
    setResponseMessage('');
    apiService.getAllServiceSubscriptionPackages()
      .then((response) => getAllSubscriptionPackageResponse(response?.data))
      .catch((error) => console.log('error', error));
  };

  const getAllSubscriptionPackageResponse = (response: any) => {
    if (response && response.status === true) {
      const allPackages = response?.data;
      setServiceSubscriptions(allPackages || []);
      setServerError('');
      setShowLoader(false)
    } else {
      setServiceSubscriptions([]);
      setServerError("Failed to get packages");
    }
  };

  const sentPurchaseTrialServiceSubscriptionRequest = () => {

    apiService.addTrialServiceSubscription(serviceSubscriptionRequest()).then((response) => {

      purchaseServiceSubscriptionResponse(response?.data);
    }).catch((error) => {

      console.log('error', error);
    });
  }

  const purchaseServiceSubscriptionResponse = (response: any) => {
    if (response) {

      const status = response?.status;
      if (status && status === true) {
        const currentPackage = response?.data ?? '';
        dispatch(setServiceSubscriptionDetails(currentPackage));
        navigateTo(routes.vendorservice);
      }

    } else {
      setServerError(response?.message || "Failed to activate package... Try after some time.");
    }
  };

  const handleConfirmPurchase = () => {

    apiService
      .checkServiceSubscriptionPurchaseEligibility(serviceSubscriptionRequest())
      .then((response) => {
        const responseData = response?.data;
        const responseStatus = response?.data?.status;
        const subscriptionPurchaseCheckResponse = responseData?.data;

        if (subscriptionPurchaseCheckResponse) {

          const subscriptionPurchaseStatus = subscriptionPurchaseCheckResponse?.status;
          if (subscriptionPurchaseStatus) {

            if (isFirstSubscription === true) {

              sentPurchaseTrialServiceSubscriptionRequest();
              return;
            }
          }
          if (subscriptionPurchaseStatus === false) {

            setServerError(responseData?.data?.message);
            setShowModal(false);
          } else if (subscriptionPurchaseStatus === true) {

            if (selectedSubscriptionId !== null) {

              navigateTo(routes.vendorSubscriptionPaymentCheckout);
            } else {

              console.log("No subscription ID selected.");
            }
            setShowModal(false);
          }
        }
      })
      .catch((error) => {

        console.log('error', error);
      });
  };

  const handleOK = (subscriptionId: number, newservicesCount: number) => {

    setServerError('');
    setResponseMessage('');
    setPopupMessage('');
    setShowModal(true);
    setSelectedSubscriptionId(subscriptionId);

    const serviceDetails = serviceSubscriptions.find((service: any) => service.service_subscription_id === subscriptionId);
    dispatch(setServiceSubscriptionSelect(serviceDetails));

    if (currentServicesCount && currentServicesCount >= newservicesCount) {

      setShowModal(true);
      setPopupMessage('Heads Up! Selected package having less number of services than your active package. Are you sure want to continue?');

    }
    else {

      setShowModal(true);
      setPopupMessage('Are you sure want to purchase this package?');
    }
  };

  const vendorId = useSelector((state: any) => {
    return state.vendor.id
  });


  const serviceSubscriptionRequest = () => ({
    vendor_id: parseInt(vendorId),
    service_subscription_id: selectedSubscriptionId,
  });

  const handleSuccessmsgClose = () => {
    setResponseMessage('');
  }
  const handleServerErrorClose = () => {
    setServerError('');
  }
  const handlecancelchange = () => {
    setShowModal(false);
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="widget-title">
                <h4>Subscription</h4>
              </div>
            </div>
            {responseMessage && (
              <TKN_Success_message text={responseMessage} onClick={handleSuccessmsgClose} />
            )}
            {serverError && (
              <TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />
            )}
          </div>
          <div className="row provider-price">
            <div className="col-md-12">
              <div className="choose-title text-center">
                <h6>Choose Plan</h6>
              </div>
            </div>
            {showloader ? (

              <Loader showloader={showloader} style={{ marginTop: '50px' }} />
            ) : (

              <React.Fragment>
                {serviceSubscriptions.map((subPackage: any, index) => {
                  const isActiveSubscription = currentPackage?.service_subscription_id === subPackage?.service_subscription_id;
                  const hasActiveSubscription = currentServicesSubscriptionStatus === 1;

                  return (
                    <div className="col-md-3 d-flex h-100 width-div" key={index}>
                      <div
                        className={`price-card flex-fill card-new-background ${isActiveSubscription ? 'highlighted' : ''}`}
                        style={isActiveSubscription ? { border: '2px solid grey', backgroundColor: 'rgba(244, 231, 231, 0.711)' } : {}}
                      >
                        <div className="price-head">
                          <div className="price-level">

                            <h1 ><span>{subPackage?.service_subscription_name}</span></h1>
                            {isActiveSubscription && <h1><span style={{ color: 'green' }}>Current Plan</span></h1>}

                          </div>
                          <h1> <h6 className='inline-new'>${subPackage?.service_subscription_amt}</h6> <span>/ month</span></h1>
                        </div>
                        <div className="price-body">
                          <ul>
                            <li className="active">{subPackage.services_count} Services</li>
                            <li className="active">{subPackage.service_subscription_duration} Month{(subPackage.service_subscription_duration > 1) ? 's' : ''} Validity</li>
                            <li className="active">Email Support</li>
                            <li className="active">QR Code</li>
                            <li className="active">Unlimited Visitors</li>
                            <li className="active">Unlimited Logins</li>
                            <li className="active">Service Management</li>
                            <li className="active">Walk-in Booking</li>
                            <li className="active">Phone Support</li>
                            <li className="active">Unlimited Bookings</li>
                            <li className="active">Unlimited Customers</li>
                            <li className="active">No Hidden Charges</li>
                            <li className="active">Worldwide Booking</li>
                          </ul>
                          <div className="text-center">
                            <ButtonTextOnly_Blueoverlay
                              label={'Choose Plan'}
                              onClick={() => !isActiveSubscription && handleOK(subPackage?.service_subscription_id, subPackage?.services_count)}
                              route="#"
                              fullWidth={true}
                              className={`chooseplan-btn-custom h-44px`}
                              />
                          </div>
                          <div>

                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                }
              </React.Fragment>)}
          </div>
        </div>
      </div>
      {showModal && (
        <TKN_modal
          title={"Confirm Purchase"}
          description={popupMessage}
          okbutton={"OK"}
          cancelbutton={"Cancel"}
          handleCancelButton={handlecancelchange}
          handleSuccessButton={handleConfirmPurchase}
          handleshowModal={showModal}
        />
      )}
    </div>
  );
}

export default ProviderSubscription