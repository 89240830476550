import React, { useEffect, useState } from 'react';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { validateEmail, validateEmptyValue, validatePassword } from '../../../../helper/validate';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailAndPhone, setRole, setServiceSubscriptionDetails, setUserDetails, setUserEmail, setVendorDetails, setVendorEmail } from '../../../../core/data/redux/action';
import { CHAR_LIMIT_NAME_PASSWORD, WEB_USER_ROLE_CUSTOMER, WEB_USER_ROLE_VENDOR } from '../../../../constants/constants';
import en from '../../../../en/en';
import { TKN_EmailInputwithcheck, TKN_PasswordInput } from '../../../components/inputfields/input';
import { TKN_modal, TKN_successmodal } from '../../../components/modal/modal';
import { RootState } from '../../../../core/data/redux/store';
import { Spinner } from 'react-bootstrap';
import './style/login.css';
import { FormGroupComponent, LoginUserVendorCard, LoginHeaderTitle, AccountHaveComponent, FormComponent } from '../../../components/login/login_component';
import { ButtonTextOnly_Blueoverlay } from '../../../components/buttons/button';


interface FormErrors {
  email?: string;
  password?: string;
  general?: string;
};

const Login = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const location = useLocation();
  const maxLength = CHAR_LIMIT_NAME_PASSWORD;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [activeUser, setActiveUser] = useState<number | null>(null);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showProfileIncompleteModal, setShowProfileIncompleteModal] = useState<boolean>(false);
  const [userId, setUserId] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [confirmactiveModal, setConfirmactiveModal] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [IsVendorModalVisible, setIsVendorModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const customerUserId = useSelector((state: RootState) => state.useremail.userid);
  const vendorUserId = useSelector((state: RootState) => state.vendorEmail?.userid);

  useEffect(() => {

    if (location.state?.from === routes.bookinglogin) {
      const incomingEmail = location.state.email || '';
      setActiveButton('button1');
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
      setEmail(incomingEmail);
    }
  }, [location.state]);

  useEffect(() => {
    if (activeButton !== 'button1' && activeButton !== 'button2') {
      setEmail('');
      setPassword('');
      setEmailError('');
      setPasswordError('');
    }
  }, [activeButton])
  const handleClick = (buttonId: string) => {

    if (activeButton === buttonId) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonId);
    };

    if (buttonId === 'button1') {
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
    } else {
      setActiveUser(WEB_USER_ROLE_VENDOR);
    };
  };

  const handleLoginClick = async (e: any) => {
    e.preventDefault();
    setErrors({ general: '' });
    let isValid = true;
    const fieldsToValidate = [
      { value: email.toLowerCase(), errorSetter: setEmailError, validationFunction: [validateEmptyValue, validateEmail], fieldName: 'Email' },
      { value: password, errorSetter: setPasswordError, validationFunction: [validateEmptyValue, validatePassword], fieldName: 'Password' }
    ];

    fieldsToValidate.forEach((field => {
      for (const validationFunction of field.validationFunction) {
        const validationResult = validationFunction(field.value, field.fieldName);

        if (validationResult.status === 'false') {
          field.errorSetter(validationResult.error);
          isValid = false;
          break;
        }
      }
    }));

    if (isValid) {
      setIsLoading(true);
      await checkVendorDeactStatus();
    }
  };

  const sendLoginRequest = () => {
    const payload = loginRequest();

    apiService.login(payload)
      .then((response: any) => {
        getLoginResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setErrors({ general: 'Failed to fetch user details' });
      });
  };

  const loginRequest = () => ({
    email: email.toLowerCase(),
    password: password,
    userrole: activeUser
  });

  const switchAfterLoginNavigation = (vendor_length: any) => {
    switch (location.state?.from) {
      case routes.businessDetails: {
        const { eventNames } = location.state || {};
        navigateTo(routes.businessDetails, { state: { eventNames: eventNames }, replace: true });
        break;
      }
      default: {
        if (activeUser === WEB_USER_ROLE_CUSTOMER) {
          navigateTo(routes.customerDashboard);
        } else if (activeUser === WEB_USER_ROLE_VENDOR) {
          if (vendor_length != 0) {
            navigateTo(routes.vendorDashboard);
          }
        }
      }
    }
  };

  const getLoginResponse = (response: any) => {
    if (response) {
      const status = response?.status;

      if (status === true) {
        const responseData = response?.data;
        const responseUser = responseData.user_details;
        const otpverified = responseData.user_details.otp_verified;
        const ProfileVerified = responseData.user_details.profile_verified;

        if (responseData) {
          dispatch(setEmailAndPhone({
            email: responseData.user_details.email,
            phone: responseData.user_details.phone,
            countryCodeDetails: responseData.user_details.country
          }))

          if (activeUser === WEB_USER_ROLE_VENDOR) {

            dispatch(setRole(WEB_USER_ROLE_VENDOR));
            dispatch(setVendorEmail({
              email: responseData.user_details.email,
              userid: responseData.user_details.user_id
            }));
          } else if (activeUser === WEB_USER_ROLE_CUSTOMER) {

            dispatch(setRole(WEB_USER_ROLE_CUSTOMER));
            dispatch(setUserEmail({
              email: responseData.user_details.email,
              userid: responseData.user_details.user_id
            }));
          };

          if (otpverified == 1) {
            if (ProfileVerified == 1) {
              const VendordataDetails = responseData?.vendor_details;

              if (responseUser && VendordataDetails && (activeUser === 3)) {
                if (VendordataDetails.length == 0) {
                  setIsVendorModalVisible(true);
                } else {
                  const vendorStatus = VendordataDetails?.status;
                  const vendorApprovalStatus = VendordataDetails?.approval_status;
                  const serviceSubscriptionDetails = responseData.service_subscription_details;

                  if (vendorStatus == 1 && vendorApprovalStatus == 1) {
                    dispatch(setVendorDetails(responseData.vendor_details));
                    dispatch(setUserDetails(responseData.user_details));

                    if (serviceSubscriptionDetails) {
                      dispatch(setServiceSubscriptionDetails(responseData.service_subscription_details));
                    };
                  } else {
                    setErrors({ general: "Your vendor account is either deactivated or not approved yet." });
                  };
                }
              } else if (responseUser && (activeUser === 2)) {
                setUserId(responseData.user_details.id);
                dispatch(setUserDetails(responseData.user_details));
              } else {
                setErrors({ general: "Invalid User" });
              };
              const vendor_details_length = responseData?.vendor_details?.length
              switchAfterLoginNavigation(vendor_details_length);
            } else {
              setShowProfileIncompleteModal(true);
            };
          } else {
            otpAlert("Account inactive", "Your account is inactive as your registration process is not completed. Click 'CONTINUE' to complete the registration.");
          };
        } else {
          setErrors(responseData.error);
        };
      } else {
        console.error('ERROR MSG:', response?.message);
        setErrors({ general: response?.message });
      };
    } else {
      console.error('ERROR MSG:', response?.message);
      setErrors({ general: response?.message });
    };
  };

  const deactResponse = async (response: any) => {
    if (response) {
      const status = response?.status;
      setIsLoading(false);
      if (status === true) {
        const userDetials = await response.data;
        if (userDetials === "Deactivated User") {

          setConfirmactiveModal(true);
        } else if (userDetials === "Deleted User") {

          setIsDeleteModalVisible(true);
        } else {

          sendLoginRequest();
        }
      } else {
        let errMessage = response?.message;
        if (!errMessage) {
          errMessage = en.DEFAULT_ERROR;
        }
        setErrors({ general: errMessage });
      };
    } else {
      setErrors({ general: en.DEFAULT_ERROR });
    };
  };

  const checkVendorDeactStatus = () => {
    apiService.checkvendordeactstatus(loginRequest()).then((response: any) => {
      const vendorDeactResponse = response?.data;
      if (vendorDeactResponse) {
        deactResponse(vendorDeactResponse);
      } else {
        setErrors({ general: en.DEFAULT_ERROR });
      }
    }).catch((error: any) => {
      console.log('error', error);
    });
  };

  const UpdatedeactiveStatus = (email: any) => {
    const updatedEmail = email;

    apiService.reactivateUseraccount({
      email: updatedEmail,
    }).then((response: { data: any; }) => {
      viewactivestatusdata(response?.data);
    }).catch((error: any) => {
      console.log('Error:', error);
    });
  };

  const viewactivestatusdata = (response: any) => {
    if (response) {
      const responseData = response.data;

      if (activeUser === WEB_USER_ROLE_VENDOR) {

        dispatch(setRole(WEB_USER_ROLE_VENDOR));
        dispatch(setVendorEmail({
          email: responseData.user_details.email,
          userid: responseData.user_details.user_id
        }));
      } else if (activeUser === WEB_USER_ROLE_CUSTOMER) {

        dispatch(setRole(WEB_USER_ROLE_CUSTOMER));
        dispatch(setUserEmail({
          email: responseData.user_details.email,
          userid: responseData.user_details.user_id
        }));
      };

      navigateTo(routes.emailOtp);
    } else {
      console.log('Error:', response?.data?.error || 'Unknown Error');
    };
  };

  const handleOk = () => {
    setConfirmactiveModal(false);
    UpdatedeactiveStatus(email.toLowerCase());
  };

  const handlePasswordRecovery = () => {
    dispatch(setRole(activeUser));
  };

  const otpAlert = (modalTitle: string, modalMessage: string) => {
    setShowModal(true);
    setModalTitle(modalTitle);
    setModalDescription(modalMessage);
  };

  const resendOtpRequest = () => {
    apiService.ResendOtp(otpRequest()).then((response) => {
      setShowModal(false);
      const responseData = response?.data;

      if (responseData) {
        const status = responseData?.status;
        if (status === true) {
          navigateTo(routes.emailOtp);
        } else {
          otpAlert("Error!...", "Failed to send OTP. Click 'CONTINUE' to resend OTP");
        };
      } else {
        otpAlert("Error!...", "Failed to send OTP. Click 'CONTINUE' to resend OTP");
      };
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const otpRequest = () => ({
    userid: activeUser === 2 ? customerUserId : activeUser === 3 ? vendorUserId : 0,
    email: email.toLowerCase(),
  });

  const handleOtpNotVerified = () => {
    resendOtpRequest();
  };

  const handleProfileVerified = () => {
    setShowProfileIncompleteModal(false);

    if (activeUser === 2) {
      navigateTo(routes.userProfile);
    } else if (activeUser === 3) {
      navigateTo(routes.vendorProfile);
    };
  };

  const handleCancelModal = () => {
    setConfirmactiveModal(false);
  };

  const onAccountDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleLoginClick;
    };
  };

  const navigateToAddVendor = () => {
    setIsVendorModalVisible(false);
    navigateTo(routes.vendorBusinessProfile);
  };

  const HandleEmailonChange = (value: string) => {
    setEmail(value);
    setEmailError('');
  }

  const HandlePasswordonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setPassword(value);
      setPasswordError('');
    } else {
      setPasswordError(en.CHAR_LIMIT);
    }
  }
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <LoginHeaderTitle title='Login' />
                <div className="row mb-4">
                  <LoginUserVendorCard title='Consumer' imgsrc='assets/img/user.png' cardstyle={{ outline: activeButton === 'button1' ? '2px solid #0445CF' : 'none' }} onClickcard={() => { handleClick('button1') }} />
                  <LoginUserVendorCard title='Vendor' imgsrc='assets/img/vendor.png' cardstyle={{ outline: activeButton === 'button2' ? '2px solid #0445CF' : 'none' }} onClickcard={() => { handleClick('button2') }} />
                </div>
                {(activeButton === 'button1' || activeButton === 'button2') && (
                  <FormComponent>
                    {errors.general && <div className="error" style={{ color: 'red' }}>{errors.general}</div>}
                    <FormGroupComponent>
                      <TKN_EmailInputwithcheck
                        required={true}
                        value={email}
                        onChange={HandleEmailonChange}
                      />
                      {emailError !== '' && (
                        <div className="error" style={{ color: 'red' }}>{emailError}</div>
                      )}
                    </FormGroupComponent>
                    <FormGroupComponent>
                      <div className="pass-group">
                        <TKN_PasswordInput
                          label={'Password'}
                          value={password}
                          required={true}
                          onChange={HandlePasswordonChange}
                        />
                      </div>
                      {passwordError !== '' && (
                        <div className="error" style={{ color: 'red' }}>{passwordError}</div>
                      )}
                      <div className="row">
                        <div className="col">
                        </div>
                        <div className="col-auto">
                          <Link
                            className="forgot-link color-new"
                            to={routes.passwordRecovery}
                            onClick={handlePasswordRecovery}
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                    </FormGroupComponent>
                    <ButtonTextOnly_Blueoverlay className='login-btn takeano-login-btn signupheight-custom' isLoading={isLoading} type='submit' onKeydown={handleKeyDown} onClick={handleLoginClick} disabled={isLoading} label={'Login'} fullWidth={true} />
                  </FormComponent>
                )}
                <AccountHaveComponent title='Don’t have an account?' linktext='Signup' route={routes.chooseSignUp} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <TKN_modal
          title={modalTitle ? modalTitle : ""}
          description={modalDescription ? modalDescription : ""}
          okbutton={en.CONTINUE}
          handleshowModal={showModal}
          handleSuccessButton={handleOtpNotVerified}
          cancelbutton={en.CANCEL}
          handleCancelButton={() => setShowModal(false)}
        />
      )}
      {showProfileIncompleteModal && (
        <TKN_modal
          title={en.REGISTRATION_INCOMPLETE}
          description={en.REGISTRATION_INCOMPLETE_MESSAGE}
          okbutton={en.CONTINUE}
          handleshowModal={showProfileIncompleteModal}
          handleSuccessButton={handleProfileVerified}
          cancelbutton={en.CANCEL}
          handleCancelButton={() => setShowProfileIncompleteModal(false)}
        />
      )}
      {confirmactiveModal && (
        <TKN_modal
          title={en.ACTIVATION}
          okbutton={en.OK}
          handleshowModal={confirmactiveModal}
          handleSuccessButton={handleOk}
          cancelbutton={en.CANCEL}
          handleCancelButton={handleCancelModal}
        />
      )}
      {isDeleteModalVisible && (
        <TKN_successmodal
          title={en.ACCOUNT_DELETED}
          description={en.ACCOUNT_DELETED_MESSAGE}
          handleshowModal={isDeleteModalVisible}
          cancelbutton={en.CANCEL}
          handleCancelButton={onAccountDelete}
        />
      )}
      {IsVendorModalVisible && (
        <TKN_modal
          title={en.REGISTRATION_INCOMPLETE}
          description={en.REGISTRATION_INCOMPLETE_MESSAGE}
          okbutton={en.CONTINUE}
          handleshowModal={IsVendorModalVisible}
          handleSuccessButton={navigateToAddVendor}
          cancelbutton={en.CANCEL}
          handleCancelButton={() => setIsVendorModalVisible(false)}
        />
      )}
    </>
  );
};

export default Login;
