import React from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import './style.css'



interface ImagewithtitleCardProps {
    title: string;
    subtitle: string;
    number: any;
    imgsrc: string;
}
interface AccordingwithtextProps {
    title: string;
    subtitle: string;
    to: string;
    classname?: string;
    iconclassname?: string;
}
interface Aboutuspricinglistitemsprops {
    text: string;
    classname?: string;
}
interface AboutusUnorderedlistprops {
    text: string;
    classname?: string;
}
interface ParaTextJustifyprops {
    paratext: { subtitle: string }[];
    classname?: string;
}

interface HeadingTextprops {
    title: string;
    classname?: string;
}

interface aboutusTitlesubtitleprops {
    title: string;
    subtitle: { subtitle: string; }[];
    bulletpoints: boolean;
    bulletpointstext?: { subtitle: string; }[];
}

interface TitleSubtitleDescriptionprops {
    title?: string;
    subtitle?: string;
    description?: string[];
    alignment?: 'left' | 'center' | 'right';
    classname?: string;
    descriptionclassname?: string;
    titleclassname?: string;
    subtitleclassname?: string;
}
const ImageWithTitleCard: React.FC<ImagewithtitleCardProps> = (props) => {
    return (
        <div className="col-md-4">
            <div className="work-box">
                <div className="work-icon">
                    <span>
                        <ImageWithBasePath src={props.imgsrc} alt="img" />
                    </span>
                </div>
                <h5 className='text-subtitle'>{props.title}</h5>
                <p>{props.subtitle}</p>
                <h4>{props.number}</h4>
            </div>
        </div>
    )
}

const Accordingwithtext: React.FC<AccordingwithtextProps> = (props) => {
    return (
        <React.Fragment>
            <div className={`support-card ${props.classname}`}>
                <h4 className={`support-title ${props.iconclassname}`}>
                    <Link
                        className="collapsed"
                        data-bs-toggle="collapse"
                        to={'#' + props.to}
                        aria-expanded="false"
                    >
                        {props.title}
                    </Link>
                </h4>
                <div id={props.to} className={`card-collapse collapse`}>
                    <p style={{ textAlign: 'justify' }} className='aboutus-para accordian-para-custom'>
                        {props.subtitle}
                    </p>
                </div>
            </div>

        </React.Fragment>
    )
}

const Aboutuspricinglistitems: React.FC<Aboutuspricinglistitemsprops> = (props) => {
    return (
        <React.Fragment>
            <li className={props.classname}><i className="fas fa-check-square" style={{ color: 'green', marginRight: '10px' }}></i>{props.text}</li>
        </React.Fragment>
    )
}


const AboutusUnorderedlist: React.FC<AboutusUnorderedlistprops> = (props) => {
    return (
        <React.Fragment>
            <div className="col-md-6">
                <ul>
                    <li className={`li-fontsize-custom ${props.classname}`}>{props.text}</li>
                </ul>
            </div>
        </React.Fragment>
    )
}

const ParaTextJustify: React.FC<ParaTextJustifyprops> = (props) => {
    return (
        <React.Fragment>
            {Array.isArray(props.paratext) ? (
                props.paratext.map((item, index) => (
                    <p key={index} className={`text-align-justify ${props.classname}`} >{item.subtitle}</p>
                ))
            ) : (
                <p className={`text-align-justify ${props.classname}`} >{props.paratext}</p>
            )}
        </React.Fragment>
    )
}

const AboutusTitlesubtitle: React.FC<aboutusTitlesubtitleprops> = (props) => {
    return (
        <React.Fragment>
            <div className="about-content">
                <TitleSubtitleDescription title={props.title} alignment='left' classname='about-heading-custom-padding' />
                {props.subtitle && <ParaTextJustify paratext={props.subtitle} />}
                {props.bulletpoints && props.bulletpointstext &&
                    <div className="row">
                        {props.bulletpointstext.map((item, index) => {
                            return <AboutusUnorderedlist key={index} text={item.subtitle} />
                        })}
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

const TitleSubtitleDescription: React.FC<TitleSubtitleDescriptionprops> = (props) => {
    const alignmentstyle = {
        textAlign: props.alignment
    }
    return (
        <React.Fragment>
            <div className={`text-section ${props.classname}`} style={alignmentstyle}>
                {props.title && <h1 className={`text-title ${props.titleclassname}`}>{props.title}</h1>}
                {props.subtitle && <h2 className={`text-subtitle ${props.subtitleclassname}`}>{props.subtitle}</h2>}
                {props.description && <>
                    {Array.isArray(props.description) && props.description.map((desc, index) => (
                        <p className={`text-description ${props.descriptionclassname}`} key={index}>{desc}</p>
                    ))}
                </>}
            </div >
        </React.Fragment >
    )
}
export { ImageWithTitleCard, Accordingwithtext, Aboutuspricinglistitems, AboutusUnorderedlist, ParaTextJustify, AboutusTitlesubtitle, TitleSubtitleDescription }