import React from "react";
import { ButtonTextOnly_Whiteoverlay } from "../../buttons/button";
import "./dashBoardRecentSubHeadingStyle.css";
import { DashBoardRecentSubHeadingProps } from "../../../../core/models/interface";

const DashBoardRecentSubHeading = (props: DashBoardRecentSubHeadingProps) => {
    const TagName = props.tagName;

    return (
        <div className="dashBoard-recent-container" >
            <TagName className="dashBoard-title dashboard-title-custom ">{props.SubHeading}</TagName>
            {props.upcomingbtnText && (
                <div className="dashBoard-btn me-3">
                    <ButtonTextOnly_Whiteoverlay
                        label={props.upcomingbtnText}
                        className="btn-light-primary"
                        fullWidth={false}
                        onClick={props.upcomingBookingsClick} />
                </div>
            )}
            {props.btnText && (
                <div className="dashBoard-btn">
                    <ButtonTextOnly_Whiteoverlay
                        label={props.btnText}
                        className="btn-light-primary"
                        fullWidth={false}
                        onClick={props.allBookingsClick} />
                </div>
            )}
        </div>
    )
};

export default DashBoardRecentSubHeading;
