import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import apiService from '../../../../api/authentication-service';
import { all_routes } from "../../../../core/data/routes/all_routes";
import { TKN_DropdownInputvalue, TKN_TextareaPlaceholder, TKN_LocationMap, TKN_TextInputreqired, TKN_PhoneInputComponent, TKN_TextInput, TKN_InputPlaceholderEdit } from '../../../components/inputfields/input';
import { Nullable } from 'primereact/ts-helpers';
import Footer from '../../../components/footer/footer';
import PagesAuthHeader from './common/header';
import { CountryDropdown } from "react-country-region-selector";
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_BlueoverlayWithChildren, ButtonTextOnly_Whiteoverlay, } from '../../../components/buttons/button';
import { validateURL, validateEmail, validateZipCodeByCountry, validateBusinessName } from '../../../../helper/validate';
import { validateMobileNumber } from '../../../../helper/mobileNumberValidatorHelper';
import en from '../../../../en/en';
import { RootState } from '../../../../core/data/redux/store';
import { ChevronDown, MapPin } from 'react-feather';
import imageCompression from 'browser-image-compression';
import './style/addprofilestyle.css'
import { CHAR_LIMIT_ADDRESS, CHAR_LIMIT_EMAIL_WEBSITE, GOOGLE_MAPS_API_KEY } from '../../../../constants/constants';
import { userLocationDetails } from '../../../../core/data/redux/action';
import { defaultTimeZone } from '../../../../core/data/json/defaultTimeZone';
import { TKN_successmodal } from '../../../components/modal/modal';

type ErrorMessages = {
  service?: string;
  date?: string;
  timeSlot?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  businessName?: string;
  ownerName?: string;
  selectedOption?: string;
  selectedsubOption?: string;
  website?: string;
  location?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postalcode?: string;
  selectedCountry?: string;
  general?: string;
  description?: string;
  business?: string;
  otherCategory?: string;
  otherSubcategory?: string;
  websiteCharLimitError?: string;
};

type Option = {
  label: string;
  value: number;
};

const VendorBusinnessprofile = () => {
  const navigateTo = useNavigate();
  const routes = all_routes;
  const vendor = useSelector((state: RootState) => state.vendorEmail);
  const VendorUserId = vendor?.userid;
  const userLoginDetails = useSelector((state: RootState) => state.userEmailAndPhone);
  const userLocationDetails = useSelector((state: RootState) => state.userLocationDetails);
  const URL_REGEX = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=_-]*)?$/;
  const [tags] = useState(['English', 'French']);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [catoptions, setCatoptions] = useState<Option[]>([]);
  const [subcatoptions, setSubcatoptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const [selectedOptionvalue, setSelectedOptionvalue] = useState<number | null>(null);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [selectedCountry, setSelectedCountry] = useState<any>('United States');
  const [responseMessage, setResponseMessage] = useState('');
  const [countryError, setCountryError] = useState('');
  const [businessSelectedImage, setBusinessSelectedImage] = useState<string[] | null>(null);
  const [savedImage, setSavedImage] = useState<string | null>(null);
  const [businessName, setBusinessName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [selectedsubOption, setSelectedsubOption] = useState<Option | undefined>(undefined);
  const [selectedsubOptionvalue, setSelectedsubOptionvalue] = useState<number | null>(null);
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryDetails, setCountryDetails] = useState('');
  const [countryName, setCountryName] = useState('');
  const [region, setRegion] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [maplatitude, setMaplatitude] = useState<number | null>(userLocationDetails.latitude);
  const [maplongitude, setMaplongitude] = useState<number | null>(userLocationDetails.longitude);
  const [timezone, setTimezone] = useState<any>(defaultTimeZone);
  const [otherCategory, setOtherCategory] = useState('');
  const [otherSubCategory, setOtherSubCategory] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [websiteError, setWebsiteError] = useState('');


  useEffect(() => {
    sendGetAllCategoryRequest();
  }, []);

  const sendGetAllCategoryRequest = () => {
    apiService.listCategories()
      .then((response: any) => {
        getcategoryResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching categories', error);
        setErrors(error);
      });
  };

  const getcategoryResponse = (response: any) => {
    if (response) {
      const status = response?.status;

      if (status === true) {
        const responseData = response.data;
        const categoryOptions = Object.entries(responseData).map(([category_name, category_id]) => ({
          label: category_name,
          value: category_id as number,
        }));
        setCatoptions(categoryOptions);
      }
      else {
        console.error('ERROR MSG:', response?.message);
        setErrors(response?.message);
      };
    } else {
      console.error('ERROR MSG:', response?.message);
      setErrors(response?.message);
    };
  };

  const sendGetAllsubCategoryRequest = (categoryid: any) => {
    const payload = subcategoryRequest(categoryid);

    apiService.getAllSubcategories(payload)
      .then((response: any) => {
        getsubcategoryResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching categories', error);
        setErrors(error);
      });
  };

  const subcategoryRequest = (categoryid: any) => ({
    category_id: categoryid
  });

  const getsubcategoryResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      setSubcatoptions([]);

      if (status === true) {
        const responseData = response.data;
        const subcategoryOptions = Object.entries(responseData).map(([subcategory_name, subcategory_id]) => ({
          label: subcategory_name,
          value: subcategory_id as number,
        }));
        setSubcatoptions(subcategoryOptions);
      } else {
        setSubcatoptions([]);
        console.error('ERROR MSG:', response?.message);
        setErrors(response?.message);
      };
    } else {
      console.error('ERROR MSG:', response?.message);
      setErrors(response?.message);
    };
  };

  const combinedOptions = [
    ...catoptions,
    { label: 'Others', value: 0 },
  ];

  const combinedsubOptions = [
    ...subcatoptions,
    { label: 'Others', value: 0 },
  ];

  const handleBusinessNameChange = (value: string) => {
    setBusinessName(value);
    if (errors.businessName) {
      setErrors((prevErrors) => ({ ...prevErrors, businessName: undefined }));
    }
  };

  const handleOwnerNameChange = (value: string) => {
    setOwnerName(value);
    if (errors.ownerName) {
      setErrors((prevErrors) => ({ ...prevErrors, ownerName: undefined }));
    }
  };

  const handleCategoryotherChange = (value: string) => {
    setOtherCategory(value);
  };

  const handleSubcategoryotherChange = (value: string) => {
    setOtherSubCategory(value);
  };

  const handlePhonechange = (phoneNumber: string, formattedDialCode: any, countryname: any, countrydetails: any) => {
    setPhone(phoneNumber);
    setCountryCode(formattedDialCode);
    setCountryName(countryname);
    setCountryDetails(countrydetails);

    if (countrydetails) {
      const countryregion = countrydetails.region;
      setRegion(countryregion);
    };
  };

  const handleWebsiteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let text = event.target.value;

    if (text.length > CHAR_LIMIT_EMAIL_WEBSITE - 1) {
      setWebsiteError(en.CHAR_LIMIT);
      return
    }

    if (text && !/^https?:\/\//i.test(text)) {
      text = `https://${text}`;
    }

    if (text && !URL_REGEX.test(text)) {
      setWebsiteError('Invalid website URL format');
      return;
    }

    setWebsite(text);
    setWebsiteError('');
  };

  const maxLength = 200;
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (value.length >= maxLength - 1) {
      setDescriptionError(en.CHAR_LIMIT);
    } else {
      setDescriptionError('');
    }
    setDescription(value);
    if (errors.description) {
      setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
    }
  };

  const handleAddress1Change = (value: string) => {

    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress1(value)
      errors.address1 = '';
    } else {
      errors.address1 = en.CHAR_LIMIT;
    }
    if (errors.address1) {
      setErrors((prevErrors) => ({ ...prevErrors, address1: undefined }));
    }
  };

  const handleAddress2Change = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress2(value)
      errors.address2 = '';
    } else {
      errors.address2 = en.CHAR_LIMIT;
    }
    if (errors.address2) {
      setErrors((prevErrors) => ({ ...prevErrors, address2: undefined }));
    }
  };

  const handleAEmailChange = (value: string) => {
    const LowerCValue = value.toLowerCase();
    setEmail(LowerCValue);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  };

  const handleStateChange = (value: string) => {
    setState(value);
    if (errors.state) {
      setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));
    }
  };

  const handleCityChange = (value: string) => {
    setCity(value);
    if (errors.city) {
      setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));
    }
  };

  const handlePostcodeChange = (value: string) => {
    setPostalcode(value);
    if (errors.postalcode) {
      setErrors((prevErrors) => ({ ...prevErrors, postalcode: undefined }));
    }
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();

    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setSelectedCountry(null);
    } else {
      setCountryError('');
      setSelectedCountry(val);

      if (errors.selectedCountry) {
        setErrors((prevErrors) => ({ ...prevErrors, selectedCountry: undefined }));
      }
    }
  };

  const fetchTimezone = async (lat: number, lng: number) => {
    const timestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    const apiKey = GOOGLE_MAPS_API_KEY;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${apiKey}`
      );

      if (response.data && response.data.timeZoneId) {
        const timezoneData = {
          dstOffset: response.data.dstOffset.toString(),
          rawOffset: response.data.rawOffset.toString(),
          status: response.data.status,
          timeZoneId: response.data.timeZoneId,
          timeZoneName: response.data.timeZoneName,
        };
        setTimezone(timezoneData);
      } else {
        console.log('No timezone data found');
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const handleLocationSelect = (lat: number, lng: number) => {
    setMaplatitude(lat);
    setMaplongitude(lng);
    fetchTimezone(lat, lng);

    if (errors.location) {
      setErrors((prevErrors) => ({ ...prevErrors, location: undefined }));
    }

  };

  const resetResponseMessage = () => {
    setResponseMessage('');
  };

  const handleBusinessFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setBusinessSelectedImage([]);
      const files = Array.from(event.target.files).slice(0, 3);

      for (const file of files) {
        try {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageData = reader.result as string;
            setBusinessSelectedImage(prevImages => prevImages ? [...prevImages, imageData] : [imageData]);
          };
          reader.readAsDataURL(compressedFile);
          event.target.value = '';
        } catch (error) {
          console.error('Error during compression: ', error)
        }
      };
    } else {
      console.error("You can select a maximum of 3 files.");
    }
  };

  const handlebusinessimagecancel = () => {
    setBusinessSelectedImage(null)
    setSavedImage(null);
  };

  const handleSelect = (selectedItem: Option) => {
    setSelectedOption(selectedItem);
    setSelectedOptionvalue(selectedItem.value);

    if (selectedItem.value > 0) {
      setErrors({ otherCategory: "" });
    }
    sendGetAllsubCategoryRequest(selectedItem.value);
  };
  const handleSelectsubcategory = (selectedItem: Option) => {
    setSelectedsubOption(selectedItem);
    setSelectedsubOptionvalue(selectedItem.value);

    if (selectedItem.value > 0) {
      setErrors({ otherCategory: "" });
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    const newErrors: ErrorMessages = {};

    if (!businessName) newErrors.businessName = 'Business name is required';
    if (!ownerName) newErrors.ownerName = 'Owner name is required';
    if (!selectedOption) newErrors.selectedOption = 'Please select a category';

    if (selectedOptionvalue === 0) {
      if (!otherCategory) newErrors.otherCategory = 'Category required';
    };

    if (!selectedsubOption) newErrors.selectedsubOption = 'Please select a sub category';
    if (selectedsubOptionvalue === 0) {
      if (!otherSubCategory) newErrors.otherSubcategory = 'Sub Category required';
    };

    if (!phone) newErrors.phone = 'Phone number is required';
    if (!email) newErrors.email = 'Email is required';

    if (businessName) {
      const validationResponse = validateBusinessName(businessName, 'Business name');
      if (validationResponse.status === "false") {

        newErrors.businessName = validationResponse.error;
      };
    }

    if (email) {
      const validationResponse = validateEmail(email, 'Email');
      if (validationResponse.status === "false") {

        newErrors.email = validationResponse.error;
      };
    };

    if (!description) newErrors.description = 'Description is required';

    if (website) {
      const validationResponse = validateURL(website, 'website');

      if (validationResponse.status === "false") {
        setWebsiteError(validationResponse.error);
        isValid = false;
      } else if (websiteError !== "") {
        isValid = false;
      }
    }

    if (!maplatitude && !maplongitude) { newErrors.location = 'Select the location'; }
    if (!address1) newErrors.address1 = 'Address is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';
    if (!selectedCountry) newErrors.selectedCountry = 'Please select a country';

    if (!postalcode) {
      newErrors.postalcode = 'Zip code is required';
    } else {
      const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, postalcode);

      if (zipCodeValidationResult.status === 'false') {
        newErrors.postalcode = zipCodeValidationResult.error;
      };
    };

    if (phone && selectedCountry) {
      if (!validateMobileNumber(selectedCountry, phone)) {
        newErrors.phone = 'Invalid phone number for the selected country';
      }
    };

    if (countryName && selectedCountry) {
      if (countryName != selectedCountry) {
        newErrors.phone = "Country and phone number mismatch. Please verify.";
      };
    }

    if (!businessSelectedImage || businessSelectedImage.length === 0) {
      newErrors.business = 'Select Business photos.';
    };

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0 && isValid) {

      setIsLoading(true);
      sendBusinessProfileRequest();
    };
  };

  const sendBusinessProfileRequest = () => {
    const payload = signupRequest();

    apiService.saveVendordetails(payload)
      .then((response: any) => {
        getsignupResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setErrors({ general: 'Failed to fetch user details' });
      });
  };
  const signupRequest = () => ({
    bussiness_name: businessName,
    bussiness_address: address1,
    address: address2,
    country: selectedCountry,
    State: state,
    city: city,
    Zipcode: postalcode,
    website: website ?
      website.startsWith('http://') ?
        website.replace(/^http:\/\//i, 'https://') :
        website.startsWith('https://') ? website : `https://${website}` : website,
    category_id: selectedOptionvalue,
    subcategory_id: selectedsubOptionvalue,
    category_name: otherCategory,
    subcategory_name: otherSubCategory,
    owner_name: ownerName,
    mobile: phone,
    country_code: countryCode,
    country_details: JSON.stringify(countryDetails),
    vendor_email: email.toLowerCase(),
    description: description,
    photo: businessSelectedImage ? JSON.stringify(businessSelectedImage.map(image => image.split(',')[1])) : '',
    vendor_id: VendorUserId,
    latitude: maplatitude,
    longitude: maplongitude,
    timezone: timezone,
    region: region,
  });

  const getsignupResponse = (response: any) => {
    setIsLoading(false);

    if (response) {
      const status = response?.status;

      if (status && status === true) {
        const VendorId = response?.data?.id;

        if (VendorId) {
          localStorage.removeItem('vendorEmail');
          localStorage.removeItem('vendorRole');
          setModalTitle(en.ACCNT_CREATION_SUCCESS);
          setModalMessage(en.ACCNT_CREATION_SUCCESS_MESSAGE_VENDOR);
          setShowModal(true);
        };
      } else {
        console.error('Error fetching User details', response?.message);
        setErrors({ general: response?.message });
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigateTo(routes.login);
  };

  const handleCancelButton = () => {
    navigateTo(routes.vendorProfile, { replace: true })
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="widget-title">
                <h4>Add Business Profile</h4>
              </div>
              <h6 className="user-title">Business Information</h6>
              <div className="general-info">
                <div className="row">
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleBusinessNameChange} label="Business Name" placeholder='Enter business name' />
                    {errors.businessName && <div className="text-danger">{errors.businessName}</div>}
                  </div>
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleOwnerNameChange} label="Owner Name" placeholder='Enter owner name' />
                    {errors.ownerName && <div className="text-danger">{errors.ownerName}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Category <span className="text-danger">*</span>
                      </label>
                      <TKN_DropdownInputvalue
                        placeholderText="Select a category"
                        options={combinedOptions}
                        onSelect={handleSelect}
                        descriptionError={errors.service}
                      />
                      {errors.selectedOption && <div className="text-danger">{errors.selectedOption}</div>}
                      {errors.otherCategory && <div className="text-danger">{errors.otherCategory}</div>}
                      {selectedOptionvalue === 0 && (
                        <TKN_TextInput
                          onChange={handleCategoryotherChange}
                          label=""
                          placeholder="Category"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Sub Category <span className="text-danger">*</span>
                      </label>
                      <TKN_DropdownInputvalue
                        placeholderText="Select a subcategory"
                        options={combinedsubOptions}
                        onSelect={handleSelectsubcategory}
                        descriptionError={errors.service}
                      />
                      {errors.selectedsubOption && <div className="text-danger">{errors.selectedsubOption}</div>}
                      {errors.otherSubcategory && <div className="text-danger">{errors.otherSubcategory}</div>}
                      {selectedsubOptionvalue === 0 && (
                        <TKN_TextInput
                          onChange={handleSubcategoryotherChange}
                          label=""
                          placeholder="Sub Category"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} />
                    {errors.phone && <div className="text-danger mt-2">{errors.phone}</div>}
                  </div>
                  <div className="col-md-6">
                    <TKN_TextInput onChange={handleAEmailChange} label={<span>Email <span className="text-danger">*</span></span>} placeholder='Enter email' />
                    {errors.email && <div className="text-danger mt-2">{errors.email}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Website
                      </label>
                      <TKN_InputPlaceholderEdit
                        value={website}
                        onChange={handleWebsiteNameChange}
                        placeholderText={'www.example.com'}
                      />
                      {websiteError && <div className="text-danger">{websiteError}</div>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="col-form-label d-block">
                      Description <span className="text-danger">*</span>{' '}
                      <span className="brief-bio float-end">
                        Brief description for your company. URLs are
                        hyperlinked.
                      </span>
                    </label>
                    <TKN_TextareaPlaceholder
                      placeholderText={'Enter description'}
                      value={description}
                      descriptionInputError={descriptionError}
                      maxLength={maxLength}
                      onChange={handleDescriptionChange}>
                    </TKN_TextareaPlaceholder>
                    {errors.description && <div className="text-danger">{errors.description}</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TKN_TextInputreqired maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddress1Change} label="Address 1" placeholder='Enter address' />
                  {errors.address1 && <div className="text-danger">{errors.address1}</div>}
                </div>
                <div className="col-md-6">
                  <TKN_TextInputreqired maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddress2Change} label='Address 2' placeholder='Enter address' />
                </div>

                <div className="col-md-6">
                  <TKN_TextInputreqired onChange={handleStateChange} label="State" placeholder='Enter state' />
                  {errors.state && <div className="text-danger">{errors.state}</div>}
                </div>
                <div className="col-md-6">
                  <TKN_TextInputreqired onChange={handleCityChange} label="City" placeholder='Enter city' />
                  {errors.city && <div className="text-danger">{errors.city}</div>}
                </div>
                <div className="col-md-6">
                  <TKN_TextInputreqired onChange={handlePostcodeChange} label="Zip code" placeholder='Enter postal code' />
                  {errors.postalcode && <div className="text-danger">{errors.postalcode}</div>}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Country<span className="text-danger">*</span></label>
                    <CountryDropdown
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      classes="form-control"
                      defaultOptionLabel="Select country"
                      priorityOptions={['United States']}
                    />
                    <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                  </div>
                  {errors.selectedCountry && <div className="text-danger">{errors.selectedCountry}</div>}
                </div>
                <div className="col-md-6" style={{ paddingBottom: '20px' }}>
                  <label className="col-form-label d-block">
                    Location <span className="text-danger">*</span>{' '}
                  </label>
                  <TKN_LocationMap icon={<MapPin className="react-feather-custom" />} savedLat={maplatitude} savedLng={maplongitude} onLocationSelect={handleLocationSelect} />
                  {errors.location && <div className="text-danger">{errors.location}</div>}
                </div>
                <div className="col-md-6" style={{ paddingBottom: '20px' }}> </div>
                <div className="col-md-6" style={{ marginTop: '20px' }}>
                  <h6 className="user-title">Business Photo(s) <span className="text-danger">*</span> </h6>
                  <div className="pro-picture">
                    <div className="pro-img-1">
                      {businessSelectedImage && businessSelectedImage.length > 0 && businessSelectedImage.length < 4 ? (
                        businessSelectedImage.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`Selected ${index + 1}`}
                            className='pro-img-2'
                            style={{ width: '90px', height: '90px', marginRight: '15px', objectFit: 'cover' }}
                          />
                        ))
                      ) : (
                        <>
                          <img
                            src="../assets/img/TKN_images/cameraicon.png"
                            alt="Blank"
                            className='pro-img-2'
                            style={{ width: '90px', height: '90px', marginRight: '15px' }}
                          />
                          <img
                            src="../assets/img/TKN_images/cameraicon.png"
                            alt="Blank"
                            className='pro-img-2'
                            style={{ width: '90px', height: '90px', marginRight: '15px' }}
                          />
                          <img
                            src="../assets/img/TKN_images/cameraicon.png"
                            alt="Blank"
                            className='pro-img-2'
                            style={{ width: '90px', height: '90px', marginRight: '15px' }}
                          />
                        </>
                      )}
                    </div>
                    <div className="pro-info">
                      {businessSelectedImage ? (
                        <div className="d-flex">
                          <ButtonTextOnly_BlueoverlayWithChildren label={'Choose 3 files'} fullWidth={false} className='img-upload h-44px businessimg-custombtn-choose' >
                            <input type="file" onChange={handleBusinessFileUpload} multiple accept='image/*' />
                          </ButtonTextOnly_BlueoverlayWithChildren>
                          <ButtonTextOnly_Whiteoverlay label={'Remove'} fullWidth={false} onClick={handlebusinessimagecancel} className='h-44px businessimg-custombtn-choose' />
                        </div>
                      ) : (<div className="d-flex">
                        <ButtonTextOnly_BlueoverlayWithChildren label={'Choose 3 files'} fullWidth={false} className='img-upload h-44px businessimg-custombtn-choose' >
                          <input type="file" onChange={handleBusinessFileUpload} multiple accept='image/*' />
                        </ButtonTextOnly_BlueoverlayWithChildren>
                      </div>)}
                    </div>
                  </div>
                </div>
                {errors.business && <div className="text-danger">{errors.business}</div>}
                <div className="col-md-6">
                  <div className="form-group">
                  </div>
                </div>
              </div>
              <div className="acc-submit d-flex justify-content-end gap-30px">
                <ButtonTextOnly_Whiteoverlay label={"Cancel"}
                  fullWidth={false}
                  onClick={handleCancelButton} />
                <ButtonTextOnly_Blueoverlay label={'Save'} fullWidth={false} onClick={handleSubmit} disabled={isLoading} isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
        {showModal && (<TKN_successmodal title={modalTitle} description={modalMessage} okbutton={'OK'} handleshowModal={showModal} handleSuccessButton={handleCloseModal} />)}
      </div>
      <Footer />
    </>
  );
};

export default VendorBusinnessprofile;
