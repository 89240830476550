import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import Locationwithname from './location_with_map_component';
import Ratingwithtext from './rating_with_text_component';
import Booking_timings from './booking_timings_component';
import './available_services.css';
import { ButtonTextOnly_Blueoverlay } from '../buttons/button';
import { Available_ServiceProps } from '../../../core/models/interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/data/redux/store';
import { resetVendorMapDetails, setVendorMapDetails } from '../../../core/data/redux/action';

const Available_Services: React.FC<Available_ServiceProps> = (Props) => {
    const routes = all_routes;
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    let hoursOpen: any;
    try {
        hoursOpen = typeof Props.hoursOpen === 'string' ? JSON.parse(Props.hoursOpen) : Props.hoursOpen;
    } catch (error) {
        console.error('Failed to parse hours_open JSON:', error);
        hoursOpen = {};
    }

    useEffect(() => {
        dispatch(resetVendorMapDetails())
    }, []);

    const handleBookNow = () => {
        dispatch(setVendorMapDetails({
            mobile: Props.mobile,
            bussiness_address: Props.bussiness_address,
            vendor_email: Props.vendor_email,
            service_name: Props.service_name,
            serviceDescription: Props.descriptiontext,
            service_image: Props.service_image,
            bussiness_name: Props.bussiness_name,
            hoursOpen: hoursOpen,
            vendorMapId: Props.vendorMapId,
            vendorId: Props.vendor_id
        }));

        if (user.user_id) {
            navigate(routes.bookingAppointment);

        } else {
            navigate(routes.bookinglogin);
        }
    };

    return (
        <>
            <div className="col-md-6 padding-l-0px padding-r-0px">
                <div className="service-widget service-content booking-widget">

                    <div className='top-container new-bottom-style-20px'>
                        <div className='images-container'>
                            <ImageWithBasePathWithUrl
                                src={Props.imagesrc}
                                className={'new-img-style img-fluid serv-img '}
                                alt='Service Image'
                            />
                        </div>
                        <div className='description-container'>
                            <h5 className='availableservices-title-custom'>{Props.service_name}</h5>
                            <div className='descheightclass'>
                                <p className='new-white-nowrap availableservices-para-custom'>
                                    {Props.descriptiontext}
                                </p>
                            </div>
                            <div className="col-md-12 new_flex_between">
                                <div className="col-l-4">
                                    <Locationwithname
                                        city={`${Props.city}`}
                                        State={`${Props.State}`}
                                        icon='MapPin'
                                        iconClass='standard-feather' />
                                </div>
                                <div className="col-l-4">
                                    <Ratingwithtext
                                        rating="4.9"
                                        iconClass='standard-feather' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="booking-timings-style  new-margin-top new-flex-grow">
                        <div className="row">
                            <Booking_timings hoursOpen={hoursOpen} />
                        </div>
                    </div>
                    <div className='btn-container align-items-center'>
                        <ButtonTextOnly_Blueoverlay label={'Book Now'} fullWidth={true} className='button-container booking-booknow-custom' onClick={handleBookNow} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Available_Services;
