import React from 'react';
import * as Icon from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import './vendor_dashboard.css';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Custombtn, ButtonTextOnly_Whiteoverlay } from '../buttons/button';
import { VendorDashboardProps, ActiveServicesCardProps, VendorBookingCardProps, VendorCurrentPlanProps, VendorPricingCardProps } from '../../../core/models/interface';
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../core/data/routes/all_routes';
import './vendor_dashboard.css';

const routes = all_routes;

const VendorDashboard: React.FC<VendorDashboardProps> = (Props) => {
    const IconComponent = Props.icon ? Icon[Props.icon as keyof typeof Icon] : null;
    const navigate = useNavigate();

    const handleButtonChange = () => {
        navigate(Props.navigateTo);
    };

    return (
        <>
            <div className="col-md-4 new-dashboard-card-width flex-grow-1">
                <div className="dash-card flex-fill">
                    <div className="dash-header">
                        <div className="dash-widget-header">
                            <div className="d-flex align-items-center flex-style-between">
                                <span className="dash-widget-icon">
                                    {IconComponent && <IconComponent className={Props?.iconclassName} />}
                                    {Props.svgImage && Props.svgImage}
                                </span>
                                <div className="dash-widget-info margin-style-0">
                                    <h6>{Props.label}</h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="dash-booking-2 ">
                                <div className="dash-booking-3">
                                    <div>
                                        <p>
                                            {Props.subtitle1}: <span>{Props.subtitlevalue1}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            {Props.subtitle2}: <span>{Props.subtitlevalue2}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="dash-booking-4">
                                    <ButtonTextOnly_Custombtn label={Props.buttonlabel} route={''} fullWidth={false} className='dash-booking-button-2 new-button-style-2 wi-100' onClick={handleButtonChange} style={{ ...Props.buttonstyle }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ActiveServicesCard: React.FC<ActiveServicesCardProps> = (Props) => {
    return (
        <>
            <div className="active-services-card-2">
                <div className="active-services-card-3">
                    <h1 className="active-services-card-4 para-elipses-text">{Props.ServiceName}</h1>
                </div>
                <div className="cardio">
                    <div className="active-services-card-5">
                        <h1 className="active-services-card-6">Check In</h1>
                        <div className="active-services-card-7">
                            <h1 className="active-services-card-14">{Props.CheckinValue}</h1>
                        </div>
                    </div>
                    <div className="active-services-card-8">
                        <h1 className="active-services-card-9">Check Out</h1>
                        <div className="active-services-card-10">
                            <h1 className="active-services-card-14">{Props.CheckoutValue}</h1>
                        </div>
                    </div>
                    <div className="active-services-card-11">
                        <h1 className="active-services-card-12">Cancelled</h1>
                        <div className="active-services-card-13">
                            <h1 className="active-services-card-14">{Props.CancelledValue}</h1>
                        </div>
                    </div>
                    <div className="active-services-card-15">
                        <h1 className="active-services-card-16">Bookings</h1>
                        <div className="active-services-card-17">
                            <h1 className="active-services-card-18">{Props.BookingsValue}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const VendorBookingCard: React.FC<VendorBookingCardProps> = (Props) => {
    return (
        <>
            <div className="provider-card">
                <div className="provider-card2">
                    {Props.Imgsrc !== null ? (
                        <Link to={'#'}>
                            <ImageWithBasePathWithUrl
                                src={Props.Imgsrc}
                                alt="Service Booking Image"
                                className="provider-card-img"
                            />
                        </Link>
                    ) : (
                        <Link to="#" className="vendor-img avatar-m me-2 d-flex align-items-center justify-content-center">
                            <Icon.User color={'white'} size={70} className='mb-1' fill="white" />
                        </Link>
                    )}
                </div>
                <div className="provider-card3">
                    <div className="provider-card4">
                        <h1 className="provider-head">{Props.PersonName}</h1>
                        <div className="provider-card5">
                            <p className={`provider-card6 ${Props.className}`}>{Props.text}</p>
                        </div>
                    </div>
                    <div className="provider-card7">
                        <p >
                            <i className="fa-solid fa-phone" style={{ color: '#0445CF' }}></i> : {Props.PhoneNumber}
                        </p>
                        <p >
                            <i className="fa-solid fa-envelope" style={{ color: '#0445CF' }}></i> : {Props.Emailid}
                        </p>
                        <p >
                            <i className="fa-solid fa-mobile" style={{ color: '#0445CF', marginLeft: '2px', }}></i> : {Props.ServiceName}
                        </p>
                        <p >
                            <i className="fa-solid fa-calendar-days" style={{ color: '#0445CF' }}></i> : {Props.Date} | {Props.Time}
                        </p>
                    </div>
                    <div className="provider-card8">
                        <ButtonTextOnly_Whiteoverlay label={'Cancel Booking'} route={'#'} fullWidth={false} className='new-padding-style-button new-button-style-2' />
                        <ButtonTextOnly_Blueoverlay label={'Check-In/out'} route={'#'} fullWidth={false} className='new-padding-style-button' />
                    </div>
                </div>
                <div className="booking-icon">
                    <i className={Props.iconclassname} />
                    {Props.svgImage && Props.svgImage}
                </div>
            </div >
        </>
    );
};

const VendorCurrentPlan: React.FC<VendorCurrentPlanProps> = (Props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="card flex-fill">
                <div className="plan-info">
                    <div className="plan-term">
                        <h5>{Props.PlanName}</h5>
                        <p className='para-text'>Our most popular plan</p>
                        <h6 className='para-text'>
                            <span className='para-text'>Expiry Date:</span> {Props.ExpiryDate}
                        </h6>
                    </div>
                </div>
                <div className="plan-btns justify-flex-end upgrade-payment-top" >
                    <ButtonTextOnly_Blueoverlay label={'Upgrade'} route={'#'} fullWidth={false} onClick={() => navigate(routes.vendorsubscription)} />
                </div>
            </div>
        </>
    );
};

const VendoChoosePlan: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(routes.vendorsubscription);
    };

    return (
        <>
            <div className="card flex-fill">
                <div className="plan-info">
                    <div className="plan-term w-100">
                        <h5>Get Premium</h5>
                        <p></p>
                        <h6>
                            <span>Please select a package and service(s)</span>
                        </h6>
                    </div>
                </div>
                <div className="plan-btns justify-flex-end upgrade-payment-top-new" >
                    <ButtonTextOnly_Blueoverlay label={'Upgrade'} route={"#"} fullWidth={false} onClick={handleClick} />

                </div>
            </div>
        </>
    );
};


const VendorPricingCard: React.FC<VendorPricingCardProps> = (Props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="card flex-fill">
                <div className="pay-next">
                    <div className="plan-info plan-no-flex-wrap">
                        <div className="plan-term">
                            <h5>Last Payment</h5>
                            {Props.LastPaymentDate !== '-' ? (<h6>{Props.LastPaymentDate}</h6>) : (<h6 className='text-align-centre'>{Props.LastPaymentDate}</h6>)}
                        </div>
                        <div className="plan-price">
                            {Props.subscription_active ? (<div className="btn btn-light-success active">
                                Active
                            </div>) : (<div className="btn btn-light-danger inactive">
                                Not Active
                            </div>)}
                        </div>
                    </div>
                    <div className="plan-info">
                        <div className="plan-term w-100">
                            <h5>Next Payment</h5>
                            {Props.RenewPaymentDate !== '-' ? (<h6>{Props.RenewPaymentDate}</h6>) : (<h6 className='margin-left-new'>{Props.RenewPaymentDate}</h6>)}
                        </div>
                        <div className="plan-price w-100 justify-flex-end ">
                            <ButtonTextOnly_Blueoverlay label={'Manage Payment'} route={'#'} fullWidth={false} onClick={() => navigate(routes.vendorsubscription)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export {
    VendorDashboard,
    ActiveServicesCard,
    VendorBookingCard,
    VendorCurrentPlan,
    VendorPricingCard,
    VendoChoosePlan
};