import React, { useEffect, useRef, useState } from "react";
import * as Icon from 'react-feather';
import en from "../../../en/en";
import { searchBoxProps } from "../../../core/models/interface";
import colors from "../../../colors/colors";
import { all_routes } from "../../../core/data/routes/all_routes";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../core/data/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSearchRequestData } from "../../../core/data/redux/action";
import SearchInput from "../searchInput/searchInput";
import './searchstyle.css';
import { ButtonTextandIconReversed_BlueOverlay } from "../buttons/button";

const SearchBox = (props: searchBoxProps) => {

    const routes = all_routes;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchRequestData = useSelector((state: RootState) => state.searchRequestData);
    const inputRef = useRef<HTMLInputElement>(null);

    const [placeName, setPlaceName] = useState<any>(searchRequestData?.place_name ?? '');
    const [lattitude, setLattitude] = useState<number>(searchRequestData?.latitude ?? 0);
    const [longitude, setLongitude] = useState<number>(searchRequestData?.longitude ?? 0);
    const [queryValue, setQueryValue] = useState<string>(searchRequestData?.query ?? '');

    useEffect(() => {
        setLattitude(searchRequestData?.latitude ?? '');
        setLongitude(searchRequestData?.longitude ?? '');
        setPlaceName(searchRequestData?.place_name ?? '');
    }, [searchRequestData]);

    const handleCloseButton = () => {
        setLattitude(0);
        setLongitude(0);
        setPlaceName('');

        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, 0);
        }
    }

    const handleKeywordCloseButton = () => {
        setQueryValue('');
    }

    const handleSubmit = () => {
        dispatch(setSearchRequestData({
            ...searchRequestData,
            latitude: lattitude,
            longitude: longitude,
            place_name: placeName,
            query: queryValue
        }));

        navigate(routes.search, { state: { lat: lattitude, lng: longitude, query: queryValue, locationValue: placeName } });
    }

    const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {
            handleSubmit;
        }
    }

    return (
        <React.Fragment>
            <div className="search-box">
                <form>
                    <div className="search-input line yourloc-custom">
                        <div className="search-group-icon">
                            <Icon.MapPin className="standard-feather" style={{ color: colors.web_primary_color }} />
                        </div>
                        <SearchInput
                            mainContainerClassName="w-100"
                            label={true}
                            inputRef={inputRef}
                            placeName={placeName}
                            handleCloseButton={handleCloseButton}
                            onLocationSelect={(placeName: any, lat: number, lng: number) => {
                                setPlaceName(placeName);
                                setLattitude(lat);
                                setLongitude(lng);
                            }}
                            onChange={(value) => setPlaceName(value)}
                        />
                    </div>
                    <div className="search-input whattolook-custom">
                        <div className="search-group-icon search-icon">
                            <Icon.Search className="react-feather-custom" style={{ color: colors.web_primary_color }} />
                        </div>
                        <div className="form-group mb-0">
                            <label>{en.WHAT_TO_LOOK}</label>
                            <input
                                type="text"
                                value={queryValue}
                                className="form-control"
                                placeholder="Car Repair Services"
                                onChange={e => setQueryValue(e.target.value)}
                            />
                            {queryValue && (
                                <Icon.XCircle
                                    className="close-icon-1"
                                    onClick={handleKeywordCloseButton}
                                />
                            )}
                        </div>
                    </div>
                    <div className="search-btn">
                        <ButtonTextandIconReversed_BlueOverlay label={en.SEARCH} icon={"Search"} fullWidth={false} type="submit" onClick={handleSubmit} onKeydown={handleKeyDown} className="searchhome-custom" iconclassName="react-feather-custom standard-feather me-2 searchhome-custom-icon" />
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
};

export default SearchBox;