import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Whiteoverlay } from '../buttons/button';
import { VendorBookingProps } from '../../../core/models/interface';
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import { IconWithText, IconWithNum, IconWithDate } from './iconwithtext_component';
import { toggleSidebar } from '../../../core/data/redux/action';
import { message } from 'antd';
import apiService from '../../../api/authentication-service';
import { useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { set } from 'date-fns';
import { TKN_ModalWithInput } from '../modal/modal';
import './style.css';
import { checkBookingDayCondition } from '../../../helper/checkDayFunction';
import { checkBookingTimeCondition } from '../../../helper/noShowBookingCheckFunction';
import { CHAR_LIMIT_DESC, WEB_BOOKING_STATUS_CANCELLED, WEB_BOOKING_STATUS_COMPLETED, WEB_BOOKING_STATUS_IN_PROGRESS, WEB_BOOKING_STATUS_NO_SHOW } from '../../../constants/constants';
import en from '../../../en/en';


const VendorBooking: React.FC<VendorBookingProps> = (Props) => {

    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [isStatusSelected, setIsStatusSelected] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [services, setServices] = useState([]);
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const vendorId = useSelector((state: any) => state.vendor.id);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [showModal1, setShowModal1] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [cancelbookData, setcancelbookData] = useState<{ data?: number, message?: string }>({});
    const [textarea, setTextArea] = useState('');
    const [limitError, setLimitError] = useState('');

    const handlebookingvendor1 = () => {
        setShowModal(true)
        // handleApply()
    }

    const handlebookingvendor = () => {
        setShowModal(true)
        // handleApply()
    }

    const handlecancelbookingvendor = () => {
        setShowModal1(true)
    }

    const UpdatecancelStatus = () => {
        apiService.updatebookStatus({
            status: 0,
            booking_id: Props.Bookingid,
        })
            .then((response) => {
                viewstatusdata(response?.data);
            })
            .catch((error) => {
                console.log('Error:', error);
            })
    };


    const UpdatebookingStatus = (statusValue: any) => {
        apiService.updatebookStatus({
            status: statusValue,
            booking_id: Props.Bookingid,
        })
            .then((response) => {
                viewstatusdata(response?.data);
            })
            .catch((error) => {
                console.log('Error:', error);
            })
    };

    const viewstatusdata = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status && status === true) {
                setModalVisible(false);
                Props.statusCallback?.();
            } else {
                console.log('Error :', response?.data?.error || 'Unknown Error');
            }
        }
    };

    const dateFormat = (date: Date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleRadioChangeforcheckin = () => {

        UpdatebookingStatus(2)
        setShowModal(false);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const handleRadioChangeforcheckout = () => {
        UpdatebookingStatus(3)
        setShowModal(false);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value);
        setIsChecked(e.target.checked);
    };

    const getbookid = () => ({

        vendor_map_id: Props?.vendorMapId,
        booking_id: Props?.Bookingid
    });
    const handlecancelSaveModal = () => {
        cancelBookingRequest()
        UpdatecancelStatus()
        setShowModal1(false)
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    const cancelBookingRequest = () => {
        const payload = getbookid();
        apiService.cancelTokenRequest(payload)
            .then((response) => {
                viewcancelbookdata(response?.data);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const viewcancelbookdata = (response: any) => {

        if (response) {
            const status = response?.data?.status;

            if (status && status === 1) {
                const responseData = response;
                setcancelbookData(responseData);
            } else {
                console.log('Error:', response?.data?.error || 'Unknown Error');
            }
        }
    };

    const handlecancelCloseModal = () => {
        setTextArea('');
        setLimitError('');
        setShowModal1(false)
    }

    const handleCheckInClick = () => {
        if (!isChecked) {
            setErrorMessage('Please select the checkbox before proceeding.');
        } else {
            setErrorMessage('');
            handleRadioChangeforcheckin();
        }
    };

    const handleCheckOutClick = () => {
        if (!isChecked) {
            setErrorMessage('Please select the checkbox before proceeding.');
        } else {
            setErrorMessage('');
            handleRadioChangeforcheckout();
        }
    };

    const handleTextareacompChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setTextArea(text);
        if (text.length <= CHAR_LIMIT_DESC - 1) {
            setLimitError('')
        }
        else {
            setLimitError(en.CHAR_LIMIT)
        }
    }
    return (
        <>
            <div className="provider-card">
                <div className="provider-card2">
                    {Props.Imgsrc !== null ? (
                        <div>
                            <ImageWithBasePathWithUrl
                                src={Props.Imgsrc}
                                alt="Service Booking Image"
                                className="provider-card-img"
                            />
                        </div>
                    ) : (
                        <div className="vendor-img avatar-m me-2 d-flex align-items-center justify-content-center">
                            <Icon.User color={'white'} size={70} className='mb-1' fill="white" />
                        </div>
                    )}
                </div>
                <div className="provider-card3">
                    <div className="provider-card4">
                        <h1 className="provider-head">{Props.PersonName}</h1>
                        <div className="provider-card5">
                            <p className={`provider-card6 ${Props.className}`}>{Props.text}</p>
                        </div>
                    </div>
                    <div className="provider-card7" >
                        {Props.PhoneNumber &&
                            <a
                                href={`tel:${Props.PhoneNumber}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                onMouseEnter={(e) => e.currentTarget.style.color = '#0445CF'}
                                onMouseLeave={(e) => e.currentTarget.style.color = 'inherit'}
                            >
                                <IconWithNum iconclassName={'fa-solid fa-phone'} label1={Props.PhoneNumber} />
                            </a>
                        }
                        {Props.Emailid &&
                            <a href={`mailto:${Props.Emailid}`} style={{ textDecoration: 'none', color: 'inherit' }}
                                onMouseEnter={(e) => e.currentTarget.style.color = '#0445CF'}
                                onMouseLeave={(e) => e.currentTarget.style.color = 'inherit'}
                            >
                                <IconWithText iconclassName={'fa-solid fa-envelope'} label1={Props.Emailid} />
                            </a>
                        }
                        <IconWithText iconclassName={'fa-solid fa-mobile'} label1={Props.ServiceName} />
                        <IconWithDate iconclassName={'fa-solid fa-calendar-days'} label1={Props.Date} label2={Props.Time} />
                    </div>
                    {Props.text === 'No Show' || Props.text === 'Cancelled' || Props.text === 'Checked Out' || checkBookingTimeCondition(Props?.Date + ' | ' + Props?.Time, Props.business_timezone) ? ('') : (<div className="provider-card8">
                        <ButtonTextOnly_Whiteoverlay label={'Cancel Booking'} route={'#'} fullWidth={false} className='new-padding-style-button new-button-font cancel-booking-custom' onClick={handlecancelbookingvendor} />
                        {(checkBookingDayCondition(Props?.Date + ' | ' + Props?.Time, Props.business_timezone) !== false ? (
                            <ButtonTextOnly_Blueoverlay label={'Check-In/Out'} route={'#'} fullWidth={false} className='new-padding-style-button new-button-font' onClick={handlebookingvendor1} />
                        ) : (null))}

                    </div>)
                    }
                </div>
                <div className='person-booking-icon'>
                    <i className={Props.iconclassname} ></i>
                    {Props.svgImage && Props.svgImage}
                </div>
            </div>
            <Modal show={showModal} backdrop="static" keyboard={false} contentClassName='modal-info-custom' dialogClassName='dialog-info-custom'>
                <div className="w-100">
                    <div className="icon-modal-info-custom">
                        <i className="fa-solid fa-info info-icon-custom"></i>
                    </div>
                </div>
                <Modal.Header className='border-none'>
                    {Props.text === 'Checked In' ? (
                        <Modal.Title className='color-title'>Check Out</Modal.Title>
                    ) : (
                        <Modal.Title className='color-title'>Check In</Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body className='pt-1 pb-1'>
                    <Form className='check-in-out-contriner'>
                        <Form.Check
                            type="radio"
                            label="Check In"
                            name="checkOptions"
                            value="Check In"
                            checked={selectedOption === 'Check In'}
                            onChange={handleRadioChange}
                            disabled={Props.text === 'Checked In'}
                        />
                        <Form.Check
                            type="radio"
                            label="Check Out"
                            name="checkOptions"
                            value="Check Out"
                            checked={selectedOption === 'Check Out'}
                            onChange={handleRadioChange}
                            disabled={Props.text !== 'Checked In'}
                        />
                    </Form>
                    {errorMessage && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            {errorMessage}
                        </div>)}
                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    <ButtonTextOnly_Whiteoverlay label={'Cancel'} fullWidth={false} onClick={handleCloseModal} className='cancel-btnmodal-info-custom check-okcancel-btn-custom' />
                    {selectedOption === 'Check In' ? (
                        <ButtonTextOnly_Blueoverlay label={'OK'} fullWidth={false} onClick={handleCheckInClick} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />) :
                        (<ButtonTextOnly_Blueoverlay label={'OK'} fullWidth={false} onClick={handleCheckOutClick} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />)}
                </Modal.Footer>
            </Modal>
            {/*<TKN_modal description={en.CANCEL_BOOKING_DESCRIPTION} handleshowModal={showModal1} handleCancelButton={handlecancelCloseModal} closeButton={false} title={'Cancel booking?'} okbutton={'OK'} cancelbutton={'Cancel'} handleSuccessButton={handlecancelSaveModal} />*/}
            <TKN_ModalWithInput handleshowModal={showModal1} handleCancelButton={handlecancelCloseModal} closeButton={false} title={'Cancel booking?'} okbutton={'OK'} cancelbutton={'Cancel'} handleSuccessButton={handlecancelSaveModal} textareaValue={textarea} handleTextareaChange={handleTextareacompChange} limitErrormodal={limitError} />
        </>
    )
}

export { VendorBooking };