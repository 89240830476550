import React from 'react';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import en from '../../../en/en';

interface LoginUserVendorCardProps {
    title?: string;
    imgsrc?: string;
    imgclassname?: string;
    cardstyle?: React.CSSProperties;
    onClickcard?: (event: React.MouseEvent<HTMLDivElement>) => void;
    cardclassname?: string;
}

interface LoginFormComponentprops {
    children?: React.ReactNode;
    error?: string;
}

interface LoginFormGroupComponentprops {
    children?: React.ReactNode;
}

interface LoginHeaderTitleprops {
    title?: string;
    classname?: string;
}

interface AccountHaveComponentprops {
    title?: string;
    linktext?: string;
    route?: string;
    classname?: string;
}

interface TextBoxWithTextComponentprops {
    title?: string;
    isChecked?: boolean;
    handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
}

interface PasswordStrengthComponentProps {
    PasswordResponse: any;
}

interface FormComponentprops {
    children?: React.ReactNode;
}



const LoginUserVendorCard: React.FC<LoginUserVendorCardProps> = (props) => {
    return (
        <React.Fragment>
            <div className="col-md-6 d-flex customer-login-icon-custom">
                <div className={`choose-signup flex-fill transition-new-style customer-login-height-custom ${props.cardclassname}`} style={props.cardstyle} onClick={props.onClickcard} >
                    <h6 className='login-title-custom'>{props.title}</h6>
                    <div className="choose-img">
                        <ImageWithBasePath src={props.imgsrc} alt="image" className={`img-choose-login-custom ${props.imgclassname}`} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const LoginFormComponent: React.FC<LoginFormComponentprops> = (props) => {
    return (
        <React.Fragment>
            <form>
                <div className="log-form paddingbt-10-new">
                    {props.children}
                </div>
            </form>
        </React.Fragment>
    )
}

const FormGroupComponent: React.FC<LoginFormGroupComponentprops> = (props) => {
    return (
        <React.Fragment>
            <div className="form-group">
                {props.children}
            </div>
        </React.Fragment>
    )
}

const FormComponent: React.FC<FormComponentprops> = (props) => {
    return (
        <React.Fragment>
            <form>
                <div className="log-form padding-custom-0px">
                    {props.children}
                </div>
            </form>
        </React.Fragment>
    )
}

const LoginHeaderTitle: React.FC<LoginHeaderTitleprops> = (props) => {
    return (
        <React.Fragment>
            <div className={`login-header ${props.classname}`}>
                <h3>{props.title}</h3>
            </div>
        </React.Fragment>
    )
}

const AccountHaveComponent: React.FC<AccountHaveComponentprops> = (props) => {
    const routes = all_routes
    const route = props.route || '#';
    return (
        <React.Fragment>
            <p className={`no-acc margint-0-new login-para-custom ${props.classname}`}>
                {props.title}
                <Link to={route} className='color-new login-para-custom '> {props.linktext}</Link>
            </p>
        </React.Fragment >
    )
}

const TextBoxWithTextComponent: React.FC<TextBoxWithTextComponentprops> = (props) => {
    return (
        <React.Fragment>
            <div className="text-container">
                <div className="checkbox-container" style={{ position: 'relative', zIndex: '2' }}>
                    <input
                        type="checkbox"
                        id="isChecked"
                        name="isChecked"
                        checked={props.isChecked}
                        onChange={props.handleOnChange}
                        style={{ accentColor: "#0445CF", width: "auto", height: "auto", padding: "0px", margin: "0px" }}
                    />
                </div>
                <div className="signup-text-container">
                    <p className="signup-text">
                        {props.title ? (props.title) : (<>{props.children}</>)}
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

const PasswordStrengthComponent: React.FC<PasswordStrengthComponentProps> = (props) => {
    return (
        <React.Fragment>
            <div
                className={`password-strength ${props?.PasswordResponse.passwordResponseKey === '0'
                    ? 'poor-active'
                    : props?.PasswordResponse.passwordResponseKey === '1'
                        ? 'avg-active'
                        : props?.PasswordResponse.passwordResponseKey === '2'
                            ? 'strong-active'
                            : props?.PasswordResponse.passwordResponseKey === '3'
                                ? 'heavy-active'
                                : ''
                    }`}
                id="passwordStrength"
            >
                <span id="poor" className="active"></span>
                <span id="weak" className="active"></span>
                <span id="strong" className="active"></span>
                <span id="heavy" className="active"></span>
            </div>
        </React.Fragment>
    )
}
export { LoginUserVendorCard, LoginFormComponent, FormGroupComponent, LoginHeaderTitle, AccountHaveComponent, TextBoxWithTextComponent, PasswordStrengthComponent, FormComponent }