import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { TKN_EmailInputwithcheck } from '../../../components/inputfields/input';
import { validateEmail, validateEmptyValue } from '../../../../helper/validate';
import apiService from '../../../../api/authentication-service';
import { WEB_USER_ROLE_CUSTOMER, WEB_USER_ROLE_VENDOR } from '../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { log } from 'console';
import { RootState } from '../../../../core/data/redux/store';
import { resetRole, setUserEmail, setVendorRole } from '../../../../core/data/redux/action';
import {  ButtonTextOnly_BlueoverlayLinkTag } from '../../../components/buttons/button';
import { AccountHaveComponent } from '../../../components/login/login_component';

const PasswordRecovery = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [userId, setUserId] = useState('');
  const [emailError, setEmailError] = useState('');
  const [serverError, setServerError] = useState('');
  const buttonId = useSelector((state: RootState) => state.role);

  const navigateTo = useNavigate();

  const handleSubmit = async () => {
    let isValid = true;

    const fieldsToValidate = [
      { value: email, errorSetter: setEmailError, validationFunction: [validateEmptyValue, validateEmail], fieldName: 'Email' },
    ];

    fieldsToValidate.forEach((field => {
      for (const validationFunction of field.validationFunction) {
        const validationResult = validationFunction(field.value, field.fieldName);

        if (validationResult.status === 'false') {
          field.errorSetter(validationResult.error);
          isValid = false;
          break;
        }
      }
    }));
    if (isValid) {

      sendForgotPasswordRequest();

    }
  };

  const sendForgotPasswordRequest = () => {

    apiService.forgotPassword(forgotPasswordRequest()).then((response) => {
      forgotPasswordResponse(response?.data);
    }).catch((error) => {

      console.log('error', error);
    });
  }

  const forgotPasswordRequest = () => ({
    email: email.toLowerCase(),
    user_role: buttonId,
  });

  const forgotPasswordResponse = (response: any) => {

    if (response) {

      const status = response?.status;
      if (status && status === true) {

        const userId = response?.data?.user_id;
        const emailId = response?.data?.user_email;

        dispatch(setUserEmail({
          userid: response.data.user_id,
          email: response.data.user_email,
        }));

        dispatch(resetRole());

        if (userId) {

          navigateTo(routes.forgotPasswordOtp);

        }
      } else {

        let message = response?.message;
        if (!message) {

          message = "This email address is not registered.";
        }
        setServerError(message)
      }
    }
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Password Recovery */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Forgot Password</h3>
                  {serverError && (
                    <div className="response-message" style={{ color: 'red', fontSize: 18, marginBottom: '10px' }}>
                      {serverError}
                    </div>
                  )}
                </div>
                <form action={routes.resetPassword}>
                  <div className="log-form">
                    <TKN_EmailInputwithcheck
                      onChange={(value: string) => {
                        setEmail(value);
                        setEmailError('');
                        setServerError('');
                      }}
                      required={true}
                    />
                    {emailError && <div className="error" style={{ color: 'red' }}>{emailError}</div>}
                  </div>
                  <ButtonTextOnly_BlueoverlayLinkTag label={'Submit'} fullWidth={true} className='btn login-btn btn-custom-center signupheight-custom' type='submit' onClick={handleSubmit} />
                  <AccountHaveComponent title='Remember Password ?' linktext='Login' route={routes.login} />
                </form>
              </div>
            </div>
            {/* /Password Recovery */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
