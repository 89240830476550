import React, { useEffect, useState } from "react";
import "./style.css";
import { ImageWithBasePath } from "../../../../core/img/ImageWithBasePath";
import PaypalCheckOutButton from "../../../components/paypalCheckOutButton/paypalCheckOutButton";
import { ButtonTextOnly_Whiteoverlay } from "../../../components/buttons/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Flag } from "react-feather";
import apiService from "../../../../api/authentication-service";
import { useDispatch, useSelector } from "react-redux";
import { setServiceSubscriptionSelect, setVendorDetails } from "../../../../core/data/redux/action";
import { RootState } from "../../../../core/data/redux/store";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { Spinner } from "react-bootstrap";

const AppSubscriptionPayment = () => {


    const dispatch = useDispatch();
    const location = useLocation();
    const [subscriptionId, setSubscriptionId] = useState(0);
    const [vendorId, setVendorId] = useState(0);
    const [serverError, setServerError] = useState('');
    const [requestingPlatform, setRequestingPlatform] = useState('web');
    const [showLoader, setShowLoader] = useState(true);

    const routes = all_routes
    const navigate = useNavigate();

    const handleonclickCancel = () => {

        if (requestingPlatform == "app") {

            dispatch(setServiceSubscriptionSelect(''));
            dispatch(setVendorDetails(''));
            navigate(routes.SubscriptionPurchaseStatusFailed);
        } else {

            navigate(routes.vendorsubscription)
        }
    }

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const sId = params.get('sid');
        const vId = params.get('vid');
        const platform = params.get('pform');

        if (sId && vId && platform) {

            setSubscriptionId(parseInt(sId));
            setVendorId(parseInt(vId));
            setRequestingPlatform(platform);

            const vendor_details = {
                id: parseInt(vId)
            }
            dispatch(setVendorDetails(vendor_details));
            sendGetAllServiceSubscriptionPackageRequest(parseInt(sId));
        }

    }, [location]);

    const sendGetAllServiceSubscriptionPackageRequest = (subscribeId: number) => {

        setServerError('');
        if (subscribeId) {

            dispatch(setServiceSubscriptionSelect(''));

            apiService.getServiceSubscriptionPackageById(serviceSubscriptionRequest(subscribeId))
                .then((response) => getServiceSubscriptionPackageResponse(response?.data))
                .catch((error) => console.log('error', error));
        } else {

            console.log("Subscription id is missing");
        }
    };

    const serviceSubscriptionRequest = (subscribeId: number) => ({
        subscription_id: subscribeId,
    });

    const getServiceSubscriptionPackageResponse = (response: any) => {

        if (response && response.status === true) {

            const packageData = response?.data;
            setServerError('');
            setShowLoader(false);
            if (packageData) {

                dispatch(setServiceSubscriptionSelect(packageData));
            }
        } else {

            setServerError("Failed to get package details");
        }
    };

    const SubscriptionSelected = useSelector((state: RootState) => state.serviceSubscriptionSelect);

    return (
        <div className="subscription-payment-checkout-container">
            <div className="checkout-container mx-auto checkout-container-custom d-flex flex-direction-column">
                <h1 className="text-align-center custom-text-heading">
                    Subscription Payment
                </h1>
                <div className="d-flex flex-direction-row main-checkoutcontainer-custom">
                    <div className="image-section w-50 custom-image-section" >
                        <ImageWithBasePath
                            src="assets/img/TKN_images/payment_image.jpg"
                            className="img-fluid w-100"
                        />
                    </div>
                    <div className="content-section content-section-custom" >
                        <div className="title-block">
                            <h5 className="subandpay-custom pay-title"  >
                                Subscription & Payment Details
                            </h5>
                            {(showLoader) && (

                                <Spinner className="title-spinner" />
                            )}
                        </div>

                        <div className="booking-summary">
                            <ul className="booking-date booking-date-custom" >
                                <li>
                                    <span>Subscription Plan Name</span>
                                    <span className="planname-custom" >
                                        {SubscriptionSelected?.service_subscription_name}
                                    </span>
                                </li>
                                <li>
                                    <span>Validity</span>
                                    <span className="planvalidity-custom"  >
                                        {(SubscriptionSelected?.service_subscription_duration) ? `${SubscriptionSelected?.service_subscription_duration} Month Validity` : ''}
                                    </span>
                                </li>
                                <li>
                                    <span>Number of Services</span>
                                    <span className="planvalidity-custom">
                                        {SubscriptionSelected?.services_count}
                                    </span>
                                </li>
                            </ul>
                            <div className="booking-total booking-total-custom" >
                                <ul className="booking-total-list booking-date-custom" >
                                    <li className="d-flex justify-content-spacebetween">
                                        <span>Total</span>
                                        <span className="total-cost planname-custom" >
                                            {(SubscriptionSelected?.service_subscription_amt) ? `$ ${SubscriptionSelected?.service_subscription_amt}` : ''}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div
                            className="payment-summary payment-summary-custom">
                            <div className="form-group d-flex align-items-center flex-direction-column gap-15px" >
                                <div className="w-100">
                                    {(showLoader) ? (

                                        <Spinner className="button-spinner" />
                                    ) : (

                                        <PaypalCheckOutButton />
                                    )}
                                </div>
                                <ButtonTextOnly_Whiteoverlay
                                    label={"Cancel"}
                                    route={""}
                                    fullWidth={false}
                                    className="w-100 cancelbtn-checkout-custom"
                                    onClick={handleonclickCancel}
                                />
                                <ImageWithBasePath
                                    src="assets/img/card-icon-1.png"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppSubscriptionPayment;