import React from 'react';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import './dashboard.css';
import {
  ActiveServicesCard,
  VendorCurrentPlan,
  VendoChoosePlan,
  VendorDashboard,
  VendorPricingCard

} from '../../../components/vendor_dashboard/vendor_dashboard';
import { ButtonTextOnly_Whiteoverlay } from '../../../components/buttons/button';
import useGetVendorTotalCountDetails from '../../../../hooks/getVendorTotalCountDetails';
import { useSelector } from 'react-redux';
import useGetVendorActiveServicesDetails from '../../../../hooks/getVendorActiveServicesDetails';
import useGetVendorAllBookings from '../../../../hooks/getVendorAllBookings';
import { WEB_BASE_URL } from '../../../../constants/constants';
import en from '../../../../en/en';
import useBookingStatusText from '../../../../helper/bookingStatusCheckFunction';
import { RootState } from '../../../../core/data/redux/store';
import { useNavigate } from 'react-router-dom';
import { VendorBooking } from '../../../components/provider_booking/provider_booking_component';
import { all_routes } from '../../../../core/data/routes/all_routes';
import Loader from '../../../components/loader_spinner/loader_spinner';

const ProviderDashboard = () => {

  const routes = all_routes;
  const vendorId = useSelector((state: RootState) => state.vendor.id);
  const currentPlanDetails = useSelector((state: any) => state.serviceSubscriptionDetails?.current_package ?? []);
  const isCurrentPlanNotEmpty = currentPlanDetails && Object.keys(currentPlanDetails).length > 0;
  const currentPlanStatus = useSelector((state: any) => state.serviceSubscriptionDetails?.active_subscription || false);
  const navigate = useNavigate();

  const {
    expiry_date,
    service_subscription_name,
    service_subscribed_date,
    subscription_end_date,
  } = currentPlanDetails;

  const getformatDate = (datestring: any) => {
    const date = new Date(datestring);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    };
    const formattedDate = date.toLocaleDateString('en-US', options).replace(',', '');
    const [month, day, year] = formattedDate.split(' ');

    return `${month}-${day}-${year}`
  };

  const getNextValidDate = (year: number, month: number, day: number): Date => {
    const date = new Date(year, month, day);
    if (date.getDate() !== day) {
      return new Date(year, month + 1, 1);
    }
    return date;
  };

  const getNextPaymentDate = (expiryDate: string): string => {
    const expiry = new Date(expiryDate);
    const nextDate = new Date(expiry);
    const new_next_date = nextDate.setDate(expiry.getDate() + 1);
    const validNextDate = getNextValidDate(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate());
    if (validNextDate.getTime() === expiry.getTime()) {
      validNextDate.setDate(validNextDate.getDate() + 1);
    }
    return getformatDate(validNextDate);
  };

  const Expiry_Date = expiry_date
  const Next_Payment_Date = getNextPaymentDate(Expiry_Date)
  const Subscription_End_Date = getformatDate(subscription_end_date)
  const Last_Payment = getformatDate(service_subscribed_date)


  const {
    totalActiveServices,
    totalBookings,
    totalServices,
    totalWalkinBookings,
    totalTodaysBookings,
    totalTodaysWalkinBookings,
  } = useGetVendorTotalCountDetails(vendorId);

  const {
    serviceData,
    totalBookingsCount,
    totalCheckInBookingsCount,
    totalCheckOutBookingsCount,
    totalCancelledBookingsCount,
    showloaderservice,
  } = useGetVendorActiveServicesDetails(vendorId);

  const { bookingData, showloader } = useGetVendorAllBookings({
    vendor_id: vendorId,
    upcoming_bookings: 0,
    all_bookings: 1,
    page: 1,
    limit: 4
  });
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-md-12">
                <div className="provider-subtitle">
                  <h6>{en.VENDOR_DASHBOARD}</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <VendorDashboard
              label={en.BOOKING}
              icon={"Smartphone"}
              subtitle1={en.TOTAL_BOOKING}
              subtitle2={en.TODAY_BOOKING}
              subtitlevalue1={totalBookings}
              subtitlevalue2={totalTodaysBookings}
              buttonlabel={"All Booking"}
              navigateTo={routes.vendorBooking}
            />
            <VendorDashboard
              label={en.SERVICES}
              icon={"Briefcase"}
              subtitle1={en.TOTAL_SERVICES}
              subtitle2={en.ACTIVE_SERVICES}
              subtitlevalue1={totalServices}
              subtitlevalue2={totalActiveServices}
              buttonlabel={"All Services"}
              navigateTo={routes.vendorservice}
            />
            <VendorDashboard
              label={en.WALKIN_BOOKING}
              subtitle1={en.TOTAL_BOOKING}
              subtitle2={en.TODAY_BOOKING}
              subtitlevalue1={totalWalkinBookings}
              subtitlevalue2={totalTodaysWalkinBookings}
              svgImage={
                <ImageWithBasePath
                  src="assets/img/icons/person-walking.svg"
                  alt="image"
                  width={36}
                  height={36}
                  className="new-person-walking"
                />
              }
              buttonlabel={"Add Booking"}
              navigateTo={routes.vendorWalkin}
            />
          </div>
          <div className="row">
            <div className="col-md-6 d-flex flex-column new-row-width-100">
              <h6 className="sub-head-title">{en.CURRENT_PLAN}</h6>
              {isCurrentPlanNotEmpty ? (
                <VendorCurrentPlan
                  ExpiryDate={Subscription_End_Date}
                  PlanName={service_subscription_name}
                />
              ) : (
                <VendoChoosePlan />
              )}
            </div>
            {currentPlanStatus ? (
              <div className="col-md-6 d-flex flex-column new-row-width-100">
                <h6 className="sub-head-title">Payment</h6>
                <VendorPricingCard LastPaymentDate={Last_Payment} RenewPaymentDate={Next_Payment_Date} subscription_active={currentPlanStatus} />
              </div>
            ) : (<div className="col-md-6 d-flex flex-column">
              <h6 className="sub-head-title">Payment</h6>
              <VendorPricingCard LastPaymentDate={'-'} RenewPaymentDate={'-'} subscription_active={currentPlanStatus} />
            </div>)}
            <div className="service-main-header">
              <div className="services-header">
                <h1>{en.ACTIVE_SERVICES}</h1>
              </div>
              <div>
                <ButtonTextOnly_Whiteoverlay label={en.VIEW_ALL_SERVICES} route={'#'} fullWidth={false} className='new-button-style btn-light-primary btn-dashboard-custom' onClick={() => navigate(routes.vendorservice)} />
              </div>
            </div>
            {showloaderservice ? (

              <Loader showloader={showloaderservice} style={{ marginTop: '50px' }} />
            ) : (

              <div className="active-services-card-1">
                {serviceData ? <React.Fragment>
                  {serviceData?.slice(0, 2).map((item: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <ActiveServicesCard
                          ServiceName={item.service_name}
                          CheckinValue={totalCheckInBookingsCount && totalCheckInBookingsCount[item.service_id] || '0'}
                          CheckoutValue={totalCheckOutBookingsCount && totalCheckOutBookingsCount[item.service_id] || '0'}
                          CancelledValue={totalCancelledBookingsCount && totalCancelledBookingsCount[item.service_id] || '0'}
                          BookingsValue={totalBookingsCount && totalBookingsCount[item.service_id] || '0'}
                        />
                      </React.Fragment>
                    )
                  })}</React.Fragment> : (

                  <React.Fragment>
                    <div className='row w-100'>
                      <div className='no-favorites-container justify-content-center d-flex align-items-center'>
                        <h1 style={{ color: '#adb5bd', marginTop: '30px', marginBottom: '30px' }}>No active services</h1>
                      </div>
                    </div>
                  </React.Fragment>)}
              </div>
            )}

            <div className="service-main-header">
              <div className="services-header">
                <h1>{en.RECENT_BOOKING}</h1>
              </div>
              <div>
                <ButtonTextOnly_Whiteoverlay label={en.VIEW_ALL_BOOKINGS} route={'#'} fullWidth={false} className='new-button-style btn-light-primary btn-dashboard-custom' onClick={() => navigate(routes.vendorBooking)} />
              </div>
            </div>
          </div>
          {showloader ? (

            <Loader showloader={showloader} style={{ marginTop: '50px' }} />
          ) : (

            <div className="provider-cards">
              {bookingData.length > 0 ? bookingData.map((item: any, index: number) => {
                const { text, className } = useBookingStatusText(item);
                return (
                  <React.Fragment key={index}>
                    <VendorBooking
                      Imgsrc={item.profilephoto !== null ? WEB_BASE_URL + item.profilephoto : null}
                      PersonName={item.first_name + ' ' + item.last_name}
                      PhoneNumber={item.phone}
                      Emailid={item.email}
                      ServiceName={item.service_name}
                      Date={item.booking_for}
                      Time={item.booking_slot}
                      text={text}
                      className={className}
                      Bookingid={item.booking_id}
                      Service_id={item.service_id}
                      Status={item.status}
                      business_timezone={item.business_timezone}
                      svgImage={item.walkin !== 0 ? (
                        <ImageWithBasePath src="assets/img/icons/person-walking-white.svg" alt="image" className='person-walking-class' />)
                        : (
                          <ImageWithBasePath src="assets/img/icons/hand -white.svg" alt="image" className='hand-class' />
                        )} />
                  </React.Fragment>
                )
              }) : (

                <div className="d-flex no-bookings justify-content-center align-items-center flex-fill">
                  <h1 className="no-bookings-h4">{en.NO_BOOKING}</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default ProviderDashboard;
