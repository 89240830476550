export const faqData = [
    {
        title: 'What is the "Take A No" Vendor app?',
        subtitle: '“Take A No” Vendor app is meant for Service providers, where they can list services, add dates and time slots, and attract a large number of consumers to book services online.',
        id: 'faqOne'
    },
    {
        title: 'How do I use it?',
        subtitle: 'Download and install it from the Play Store for Android and the App Store for iPhone.',
        id: 'faqTwo'
    },
    {
        title: 'How to register into vendor app?',
        subtitle: 'After installing the Vendor app, Sign up with the required fields given, you will receive an OTP enter it. Now you are successfully registered into the Vendor app. Once registered update your profile and list down your services, add date and time slot where consumers can book.',
        id: 'faqThree'
    },
    {
        title: 'How to add services?',
        subtitle: 'At the bottom of your Home page, you will find the Services option, and it will be redirected to the Add Service option. Here you can add multiple services, its details, date and time slot etc.',
        id: 'faqFour'
    },
    {
        title: 'What about Walk-Ins?',
        subtitle: 'At the bottom of your Home page Walk-In option is available, and it will be redirected to the Walk-In Slot option, where you can add client details, services, and time slots they have opted for. Finally, click the Book Now option and the service is booked.',
        id: 'faqFive'
    },
    {
        title: 'Will I receive any booking confirmation mail when a consumer books a service?',
        subtitle: 'Yes, you will receive a booking confirmation mail.',
        id: 'faqSix'
    },
    {
        title: 'Is it free?',
        subtitle: 'It is free for 3 months, then a subscription is required.',
        id: 'faqSeven'
    },
    {
        title: 'What are the payment methods?',
        subtitle: 'We accept all Credit cards, Wallets and Paypal.',
        id: 'faqEight'
    },

]