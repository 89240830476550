import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style/bookinglogin.css';
import { WEB_USER_ROLE_CUSTOMER, WEB_USER_ROLE_VENDOR } from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../../core/data/redux/action';
import { AccountHaveComponent, FormComponent, LoginHeaderTitle, LoginUserVendorCard } from '../../../components/login/login_component';
import { ButtonTextOnly_Blueoverlay } from '../../../components/buttons/button';

const ChooseSignup = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [activeUser, setActiveUser] = useState<number | null>(null);
  const dispatch = useDispatch();
  const routes = all_routes;
  const navigateTo = useNavigate();

  const handleClick = (buttonId: string) => {

    if (activeButton === buttonId) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonId);
    }
    if (buttonId === 'button2') {
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
    } else {
      setActiveUser(WEB_USER_ROLE_VENDOR);
    }
  };

  const handleLoginClick = async (e: any) => {
    e.preventDefault();
    if (activeUser == 2) {
      dispatch(setRole(WEB_USER_ROLE_CUSTOMER));
      navigateTo(routes.userSignup);
    } else {
      dispatch(setRole(WEB_USER_ROLE_VENDOR));
      navigateTo(routes.vendorSignup);
    }

  };

  useEffect(() => {
    if (activeButton === 'button2') {
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
    } else if (activeButton) {
      setActiveUser(WEB_USER_ROLE_VENDOR);
    }
  }, [activeButton]);

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <LoginHeaderTitle title='Sign Up' />
                <div className="row mb-4">
                  <LoginUserVendorCard title='Consumer' imgsrc='assets/img/user.png' cardstyle={{ outline: activeButton === 'button2' ? '2px solid #0445CF' : 'none' }} onClickcard={() => { handleClick('button2'); }} />
                  <LoginUserVendorCard title='Vendor' imgsrc='assets/img/vendor.png' cardstyle={{ outline: activeButton === 'button1' ? '2px solid #0445CF' : 'none' }} onClickcard={() => { handleClick('button1') }} />
                </div>
                {(activeButton === 'button1' || activeButton === 'button2') && (
                  <FormComponent>
                    <div className="vendor-signup flex-fill" >
                      <ButtonTextOnly_Blueoverlay label={'Continue'} onClick={handleLoginClick} type='submit' route='' className='w-100 login-btn backgroundcolor-new signupheight-custom' fullWidth={false} />
                    </div>
                  </FormComponent>)}
                <AccountHaveComponent title='Already have an account?' linktext='Sign In' route={routes.login} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseSignup;
