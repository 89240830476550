import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import { all_routes } from '../../../../core/data/routes/all_routes';
import useGetAllBookings from '../../../../hooks/getAllBookings';
import { useSelector } from 'react-redux';
import { start } from 'repl';
import { time } from 'console';
import './style.css'
import { ButtonIconOnly_Colored } from '../../../components/buttons/button';

const CustomerBookingCalendar = () => {
  const routes = all_routes;
  const limit = 0;
  const [page] = useState(1);
  const [selectedSub, setselectedSub] = useState(null);
  const sub = [{ name: 'Most Helpful' }, { name: 'A -&gt; Z' }];
  const userId = useSelector((state: any) => state.user.user_id);
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const events = [
    { title: 'Meeting 1', start: '2024-07-01T10:00:00' },  // ISO8601 string for specific time
    { title: 'Meeting 2', start: '2024-07-02T14:00:00' },
    { title: 'Meeting 3', start: '2024-07-03T09:00:00' },
    { title: 'Meeting 4', start: '2024-07-01T12:00:00' },
    { title: 'Meeting 5', start: '2024-07-04T16:00:00' },
    { title: 'Meeting 6', start: '2024-07-01T11:00:00' },
    { title: 'Meeting 7', start: '2024-07-01T13:00:00' },
    { title: 'Meeting 8', start: '2024-07-01T15:00:00' },
    { title: 'Meeting 9', start: '2024-07-01T17:00:00' },
    { title: 'Meeting 10', start: '2024-07-01T18:00:00' },
    { title: 'Meeting 11', start: '2024-07-01T19:00:00' },
    { title: 'Meeting 12', start: '2024-07-01T20:00:00' },
  ];

  const dateFormat = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleViewChange = (view: any) => {
    setCurrentView(view.view.type);
  };

  const { bookings } = useGetAllBookings(userId, page, limit);

  const monthbookingEvents = bookings.map(({ booking_for, service_name, booking_slot }: any) => ({
    title: `${booking_slot.toUpperCase()}  ${service_name}`,
    start: dateFormat(new Date(booking_for)),
  }));

  const combineDateAndTime = (dateStr: string, timeStr: string): Date => {
    const date = new Date(dateStr);

    const [time, modifier] = timeStr.split(' ');
    const [hoursString, minutes] = time.split(':').map(Number);

    let hours = hoursString;

    if (modifier.toLowerCase() === 'pm' && hours < 12) hours += 12;
    if (modifier.toLowerCase() === 'am' && hours === 12) hours = 0;

    date.setHours(hours, minutes, 0, 0);
    return date;
  };

  const weekbookingEvents = bookings.map(
    ({ booking_for, service_name, booking_slot }: any) => {
      const startDate = combineDateAndTime(booking_for, booking_slot);
      const endDate = new Date(startDate.getTime() + 1 * 60 * 60 * 1000);

      return {
        title: `${service_name}`,
        start: startDate.toISOString(),
      };
    }
  );

  const daybookingEvents = bookings.map(
    ({ booking_for, service_name, booking_slot }: any) => {
      const startDate = combineDateAndTime(booking_for, booking_slot);
      const endDate = new Date(startDate.getTime() + 1 * 60 * 60 * 1000);

      return {
        title: `${service_name}`,
        start: startDate.toISOString(),
      };
    }
  );

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between height-calender-custom">
                <div className="provider-subtitle">
                  <h6>Bookings</h6>
                </div>
                <div className="grid-listview">
                  <ul>
                    <li>
                      <ButtonIconOnly_Colored icon={'Calendar'} route={routes.customerBookingCalendar} className='active active-new icon-custbooking' />
                    </li>
                    <li>
                      <ButtonIconOnly_Colored icon={'Grid'} route={routes.customerBooking} className='icon-custbooking' />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row padding-r-0px-custom">
                <div className="col-md-12 padding-r-0px-custom">
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    dayMaxEventRows={3}
                    initialView="dayGridMonth"
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}

                    events={currentView === 'dayGridMonth' ? monthbookingEvents
                      : currentView === 'timeGridWeek' ? weekbookingEvents
                        : currentView === 'timeGridDay' ? daybookingEvents
                          : []}
                    datesSet={handleViewChange}
                    eventOrder={'booking_slot'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerBookingCalendar;
