import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css'
import { SearchBar, TKN_DropdownInput, TKN_TextareaPlaceholder } from '../../../components/inputfields/input';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { validateDropdown, validateEmptyValue } from '../../../../helper/validate';
import en from '../../../../en/en';
import { CUSTOMER_APP } from '../../../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { setFamilyMemberDetails } from '../../../../core/data/redux/action';
import { TKN_modal } from '../../../components/modal/modal';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import Loader from '../../../components/loader_spinner/loader_spinner';
import { TitleSubtitleDescription } from '../../../components/about_us/about_us_extra';
import { ButtonTextOnly_WhiteoverlayLinkTag } from '../../../components/buttons/button';


const CustomerAddMemberlisting = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [memberId, setMemberId] = useState('');
    const [searchQuery, setSearchQuery] = React.useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [status, setStatus] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [serverError, setServerError] = useState('');
    const [showloader, setShowLoader] = useState<boolean>(true);



    const routes = all_routes;
    const navigateTo = useNavigate();

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    useEffect(() => {
        sendFamilyMembersListRequest();
    }, []);

    const sendFamilyMembersListRequest = () => {

        apiService.getFamilyMembersList(familyMembersListRequest())
            .then((response) => {

                const responseData = response?.data;
                const userMembers = responseData.data;
                setData(userMembers);
                setOriginalData(userMembers);
                setShowLoader(false)
            })
            .catch((error) => {
                console.error('Error fetching family members list:', error);
                setServerError('An error occurred while fetching family members.');
            });
    };

    const familyMembersListRequest = () => ({

        user_id: userId
    });

    const handleDelete = async (familyMemberId: string) => {

        if (familyMemberId) {
            try {

                await checkFamilyMemberRequest(familyMemberId);
                setMemberId(familyMemberId);
            } catch (error) {

                console.error("Error checking family member:", error);
                setServerError('An error occurred while checking the family member. Please try again later.');
            }
        }
    };

    const checkFamilyMemberRequest = async (familyMemberId: any) => {
        try {
            const response = await apiService.checkfamilymember(familyMembersDeleteRequest(familyMemberId))
            const responseData = response?.data;
            if (responseData) {

                const status = responseData?.status;
                if (status === true) {

                    setShowModal(true);

                } else {

                    setShowModal1(true);

                }
            }
        }
        catch (error) {

            console.error('Error checking family member:', error);
            setServerError('An error occurred while checking the family member.');
        }
    };

    const sendDeleteFamilyMemberRequest = (familyMemberId: any) => {

        apiService.familyMembersDelete(familyMembersDeleteRequest(familyMemberId))
            .then((response) => {

                const responseData = response?.data;
                // setLoading(false);
                deleteFamilyMemberResponse(responseData)
                window.location.reload();
            })
            .catch((error) => {

                console.error('Error deleting family member:', error);
                setServerError('An error occurred while deleting the family member.');
            });
    };

    const deleteFamilyMemberResponse = (response: any) => {

        if (response) {

            const Status = response?.status;

            if (Status === true) {

                setResponseMessage('Deleted member succesfully')

                sendFamilyMembersListRequest();
            }
        }
    };

    const familyMembersDeleteRequest = (familyMemberId: any) => ({

        family_member_id: familyMemberId
    });

    const handleSearchQuery = (text: string) => {
        try {
            setSearchQuery(text);
            const trimmedText = text.trim();
            if (trimmedText === '') {

                setData(originalData);
            } else {
                const filtered = originalData.filter((item: any) =>
                    item.family_member_name.toLowerCase().includes(trimmedText.toLowerCase())
                );
                setData(filtered);
            }
        } catch (error) {
            console.error("Error Spotted", error)
            setData([]);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCloseshowModal1 = () => {
        setShowModal1(false);
    };

    const handleSuccessButton = (familyMemberId: any) => {
        setShowModal(false);
        sendDeleteFamilyMemberRequest(familyMemberId);
    };

    const navigateToEditAddFamily = async (familyMemberId: any) => {

        const selectedFamilyMember = await fetchFamilyMemberDetails(familyMemberId);

        if (selectedFamilyMember) {

            dispatch(setFamilyMemberDetails(familyMemberId));
            navigateTo(routes.customerEditFamily);
        } else {

            console.error(`Family member with ID ${familyMemberId} not found.`);
        }
    };

    const fetchFamilyMemberDetails = (familyMemberId: any): any => {

        const selectedFamilyMember = data.find((member: any) => member.family_member_id === familyMemberId);
        return selectedFamilyMember;
    };

    const handleSuccessButtomClose = () => {
        setResponseMessage('')
    }

    const handleServerErrorClose = () => {
        setServerError('')
    }
    return (
        <div className="provider-body">
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="col-lg-6" style={{ width: '95%' }}>
                            <div className="widget-title">
                                {serverError && (<TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />)}
                                {responseMessage && (<TKN_Success_message text={responseMessage} onClick={handleSuccessButtomClose} />
                                )}
                                <TitleSubtitleDescription title='Family Members' titleclassname='vendor-title-custom' classname='padding-0px-custom padding-btm-15px' />
                            </div>
                            <div className='d-flex justify-content-between gap-15px flex-wrap-custom'>
                                <SearchBar placeholderText={'Search by name'} icon={'Search'} style={{ color: '#0445CF' }} className='form-control-new search-bar-width-custom' value={searchQuery} onChangeText={handleSearchQuery} relativeclassName={'memberrelativeclass-custom'} />
                                <div className="col-md-6 d-flex justify-content-end">
                                    <ButtonTextOnly_WhiteoverlayLinkTag label={'Add Family Member'} fullWidth={false} route={routes.customerAddFamily} className='custom-addfamily-btn' />
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-between flex-wrap-custom row-gap-custom" >
                                {showloader ? (
                                    <Loader showloader={showloader} style={{ marginTop: '100px', marginLeft: '50%' }} />
                                ) : (
                                    <React.Fragment>
                                        {data && data.length > 0 ? (
                                            data.map((member: any, index: any) => (

                                                <div key={index} className='customer-familycard-custom'>
                                                    <div className='customer-familyimage-custom'>
                                                        <div className='customer-familyimage-iconbox-custom'>
                                                            <i
                                                                className="fa-solid fa-user customer-familyimage-icon-custom"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div className='customer-familydetails-custom '>
                                                        <div>
                                                            <p className='customer-familytitle-custom para-text-elises w-90'>
                                                                {member.family_member_name.length > 20
                                                                    ? `${member.family_member_name.slice(0, 20)}...`
                                                                    : member.family_member_name}
                                                            </p>
                                                            <div className='d-flex font-size-15 '>
                                                                <i className="fa-solid fa-phone customer-familypara-custom"></i>
                                                                <a
                                                                    href={`tel:${member.phone}`}
                                                                    className="phone-link para-text-elises w-90"
                                                                    style={{
                                                                        marginLeft: '6px',
                                                                        marginTop: '-5px',
                                                                        textDecoration: 'none',
                                                                        color: 'inherit'
                                                                    }}
                                                                >
                                                                    <p className='para-text-elises w-90 customer-familypara-custom'> {member.phone} </p>
                                                                </a>
                                                            </div>
                                                            <div className='d-flex font-size-15'>
                                                                <i className="fa-solid fa-envelope customer-familypara-custom"></i>
                                                                <a
                                                                    href={`mailto:${member.email}`}
                                                                    className="phone-link para-text-elises w-90"
                                                                    style={{
                                                                        marginLeft: '6px',
                                                                        marginTop: member.email ? '-5px' : '15px',
                                                                        textDecoration: 'none',
                                                                    }}
                                                                >
                                                                    <p className='para-text-elises w-90 customer-familypara-custom'> {member.email.length > 30 ? `${member.email.slice(0, 30)}...` : member.email} </p>
                                                                </a>
                                                            </div>
                                                            <div className='font-size-16 d-flex justify-content-between w-90'>
                                                                <div className='d-flex'>
                                                                    <i className="fa-solid fa-trash customer-familypara-trashicon-custom customer-familypara-custom" ></i>
                                                                    <p className='customer-familypara-deletepara-custom customer-familypara-custom' onClick={() => handleDelete(member.family_member_id)}>Delete</p>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <i className="fa-solid fa-pen-to-square customer-familypara-trashicon-custom customer-familypara-custom" ></i>
                                                                    <p className='customer-familypara-deletepara-custom customer-familypara-custom' onClick={() => navigateToEditAddFamily(member.family_member_id)}>Edit</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className='w-100 d-flex justify-content-space-evenly'>
                                                <div
                                                    className="no-favorites-container"
                                                    style={{
                                                        height: '514px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <h1 style={{ color: '#adb5bd' }}>No members found</h1>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}

                            </div>






                        </div>
                    </div>
                </div>
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
            {/* Delete Service */}
            <TKN_modal title={'Delete member'} description={'Are you sure want to delete?'} okbutton={'OK'} handleshowModal={showModal} handleSuccessButton={() => handleSuccessButton(memberId)} cancelbutton={'Cancel'} handleCancelButton={handleCloseModal} />
            <TKN_modal title={'Delete member'} description={'The member has an active booking. Please cancel the booking and then delete the member'} okbutton={'OK'} handleshowModal={showModal1} handleSuccessButton={handleCloseshowModal1} />
        </div>
    );
};


export default CustomerAddMemberlisting;