import React, { useEffect, useRef, useState } from 'react';
import * as Icon from 'react-feather';
import QRCode from 'react-qr-code';
import { RootState } from '../../../../core/data/redux/store';
import { useSelector } from 'react-redux';
import apiService from '../../../../api/authentication-service';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';
import { TKN_failedmodal, TKN_modal, TKN_successmodal } from '../../../components/modal/modal';
import Loader from '../../../components/loader_spinner/loader_spinner';
import './qr-code.css';
import { ButtonTextandIconReversed_BlueOverlay } from '../../../components/buttons/button';

const QRCodeScreen = () => {
    const qrRef = useRef<HTMLDivElement>(null);
    const vendor_id = useSelector((state: RootState) => state.vendor.id);
    const [vendorQR, setVendorQR] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState('');
    const [showloader, setShowLoader] = useState<boolean>(true);
    useEffect(() => {
        sendQRCodeRequest();
    }, [vendor_id]);

    const sendQRCodeRequest = async () => {
        try {
            const payload = { vendor_id };
            const response = await apiService.qrCodeRequest(payload);
            qrCodeResponse(response?.data);
        } catch (error) {
            console.log('Error fetching QR code:', error);
        }
    };

    const qrCodeResponse = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status === true) {
                const qrData = response?.data;
                if (qrData) {
                    const shortenedQRData = qrData.slice(0, 1000);
                    setVendorQR(shortenedQRData);
                    setShowLoader(false);
                } else {
                    setShowLoader(false);
                }
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOkButton = () => {
        setShowModal(false);
    };

    const handleDownload = async () => {
        if (qrRef.current) {
            try {
                const dataUrl = await toPng(qrRef.current);
                saveAs(dataUrl, 'Takeano-QRcode.png');

                setModalTitle('Download Successful!');
                setModalDescription('Your QR code has been downloaded successfully.');
                setShowModal(true);
            } catch (error) {
                setModalTitle('Download Failed');
                setModalDescription('Failed to download QR code. Please try again.');
                setShowModal(true);
                console.error('Error capturing and downloading QR code:', error);
            }
        }
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="provider-subtitle">
                                <h6 style={{ marginBottom: '30px' }}>Check-In & Check-Out QR Code</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='ptag1'>Use this QR code to let the customers check-in & check-out</p>

                {showloader ? (

                    <div>
                        <div ref={qrRef} style={{ background: 'white', marginBottom: '30px', width: '220px' }}>
                            <Loader showloader={true} />
                        </div>
                    </div>
                ) : (

                    <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '-10px' }}>
                        <div ref={qrRef} style={{ background: 'white', padding: '16px 12px 16px 12px' }}>
                            {vendorQR ? (
                                <QRCode value={vendorQR} size={300} className='qrcode' />
                            ) : (
                                <p>Loading QR code...</p>
                            )}
                        </div>
                    </div>
                )
                }
                <div style={{ display: 'flex', justifyContent: 'left', marginTop: '20px' }}>
                    <ButtonTextandIconReversed_BlueOverlay fullWidth={false} label={'Download QR'} icon='Download' className='btn qrbutton d-flex align-justify-center gap-10px h-44px' iconclassName={'icon-size'} onClick={handleDownload} />
                </div>

                <p className='ptag2'>
                    Please check your download folder for the QR Code to print.
                </p>

                {modalTitle === 'Download Successful!' ? (
                    <TKN_successmodal
                        title={modalTitle}
                        description={modalDescription}
                        okbutton={'OK'}
                        handleshowModal={showModal}
                        closeButton={true}
                        handleCloseModal={handleCloseModal}
                        handleSuccessButton={handleOkButton}
                    />) : (<TKN_failedmodal
                        title={modalTitle}
                        description={modalDescription}
                        okbutton={'OK'}
                        handleshowModal={showModal}
                        closeButton={true}
                        handleCloseModal={handleCloseModal}
                        handleSuccessButton={handleOkButton}
                    />)}

            </div>
        </div>

    );
};

export default QRCodeScreen;