import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link, useNavigate } from 'react-router-dom';
import en from '../../../../en/en';
import { validateEmptyValue, validatePassword_ConfirmPassword } from '../../../../helper/validate';
import apiService from '../../../../api/authentication-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';
import { ButtonTextOnly_BlueoverlayLinkTag } from '../../../components/buttons/button';
import { TKN_PasswordInput } from '../../../components/inputfields/input';
import { error } from 'console';
import { FormErrors } from '../../../../core/models/interface';
import { PasswordStrengthComponent } from '../../../components/login/login_component';

const ResetPassword = () => {

  interface FormErrors {
    email?: string;
    password?: string;
    general?: string;
    phone?: string;
    confirmPassword?: string;
  }

  const routes = all_routes;
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmpasswordError, setConfirmPasswordError] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const [errors, setErrors] = useState<FormErrors>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const userdetails = useSelector((state: RootState) => state.useremail);
  const navigateTo = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [confirmPasswordResponse, setConfirmPasswordResponse] = useState({
    confirmPasswordResponseText: '',
    confirmPasswordResponseKey: '',
  });


  const maxLength = 15;
  const togglePasswordVisibility = (index: number) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  const [passwordResponse, setPasswordResponse] = useState({
    passwordResponseText: '',
    passwordResponseKey: '',
  });

  const onChangePassword = (password: string) => {
    setPassword(password);

    if (password.length <= 0) {
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '2',
      });
    } else {
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '3',
      });
    }
  };

  const onChangeConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    if (confirmPassword.match(/^$|\s+/)) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: 'Whitespaces are not allowed',
        confirmPasswordResponseKey: '',
      });
    } else if (confirmPassword.length === 0) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: '',
        confirmPasswordResponseKey: '',
      });
    } else if (confirmPassword.length < 8) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: 'Weak. Must contain at least 8 characters',
        confirmPasswordResponseKey: '0',
      });
    } else if (
      confirmPassword.search(/[a-z]/) < 0 ||
      confirmPassword.search(/[A-Z]/) < 0 ||
      confirmPassword.search(/[0-9]/) < 0
    ) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText:
          'Average. Must contain at least 1 upper case and number',
        confirmPasswordResponseKey: '1',
      });
    } else if (confirmPassword.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: 'Almost. Must contain a special symbol',
        confirmPasswordResponseKey: '2',
      });
    } else if (password !== confirmPassword) {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: 'Passwords do not match',
        confirmPasswordResponseKey: '3',
      });
    } else {
      setConfirmPasswordResponse({
        confirmPasswordResponseText: 'Confirm password matches the new password.',
        confirmPasswordResponseKey: '4',
      });
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    const ispasswordValid = validatePassword_ConfirmPassword(password, confirmPassword, 'password');

    if (ispasswordValid["status"] === "false") {
      setPasswordError(ispasswordValid["newPasswordError"]);
      setConfirmPasswordError(ispasswordValid["confirmPasswordError"]);
      isValid = false;
    }

    if (isValid) {
      setIsLoading(true);
      sendResetPasswordRequest();
    }
  };

  const sendResetPasswordRequest = () => {
    apiService.resetPassword(resetPasswordRequest()).then((response) => {
      resetPasswordResponse(response?.data);
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const resetPasswordRequest = () => ({
    new_password: password,
    confirm_password: confirmPassword,
    user_id: userdetails.userid
  });
  const resetPasswordResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      if (status && status === true) {
        setErrorMessage(null);
        navigateTo(routes.login);
      } else {
        setIsLoading(false);
        setErrorMessage('Unable to reset your password. Please try again.');
      };
    } else {
      setIsLoading(false);
      setErrorMessage('Unable to reset your password. Please try again.');
    };
  };

  const checkPasswordRequirements = (password: any) => {
    const hasMinimumLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);
    return hasMinimumLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  }

  const HandlePasswordonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);

    if (errors.password && value.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, password: undefined }));
    }

    if (value.length < maxLength + 1) {
      setPasswordError('');
    } else {
      setPasswordError(en.CHAR_LIMIT);
    }
    onChangePassword(value);
  }

  const HandleConfirmPasswordonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (errors.confirmPassword && value.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: undefined }));
    }

    if (value.length < maxLength + 1) {
      setConfirmPasswordError('');
    } else {
      setConfirmPasswordError(en.CHAR_LIMIT);
    }
  }

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <p>
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>
                {errorMessage && (
                  <div className="response-message" style={{ color: 'green', fontSize: 18, marginBottom: '10px' }}>
                    {errorMessage}
                  </div>
                )}
                <form>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">
                      </label>
                      <div className="pass-group">
                        <TKN_PasswordInput
                          label={'New Password'}
                          value={password}
                          onChange={HandlePasswordonChange}
                          required={true}
                        />
                        {passwordError && <div className="error" style={{ color: 'red' }}>{passwordError}</div>}
                        {password !== '' && !passwordError && !checkPasswordRequirements(password) && (
                          <div style={{ color: 'red' }}>
                            {en.PASSWORD_REQUIREMENTS}
                          </div>
                        )}
                      </div>
                      <PasswordStrengthComponent PasswordResponse={passwordResponse} />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">
                      </label>
                      <TKN_PasswordInput
                        label={'Confirm Password'}
                        value={confirmPassword}
                        onChange={HandleConfirmPasswordonChange}
                        required={true}
                      />
                      {confirmpasswordError !== '' && (
                        <div className="response-message" style={{ color: 'red' }}>{confirmpasswordError}</div>
                      )}
                    </div>
                  </div>
                  <ButtonTextOnly_BlueoverlayLinkTag label={'Save Change'} fullWidth={true} className='btn login-btn signupheight-custom btn-custom-center' type='submit' onClick={handleSubmit} isLoading={isLoading} disabled={isLoading} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
