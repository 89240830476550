import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { SearchBar } from '../../../components/inputfields/input';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Whiteoverlay } from '../../../components/buttons/button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';
import apiService from '../../../../api/authentication-service';
import { WEB_BASE_URL } from '../../../../constants/constants';
import { useSearch } from '../../../../hooks/useSearch';
import { Vendor_myservicescard } from '../../../components/provider_services/provider_service_component';
import Loader from '../../../components/loader_spinner/loader_spinner';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';
const routes = all_routes;


interface Service {
  service_id: string;
  service_name: string;
  service_description: string;
  service_image: string;
  service_vendor_map_id: string;
  service_status: number;
  start_date: string;
  end_date: string;
  hours_open: string;
}

const vendorservices = () => {
  const [services, setServices] = useState<Service[]>([]);
  const navigate = useNavigate();
  const useSearchForVendorServices = useSearch(services);
  const { searchQuery, filteredData, handleSearchQuery } = useSearchForVendorServices('service_name');
  const prevServicesRef = useRef(false);
  const vendor = useSelector((state: RootState) => state.vendor);
  const vendor_id = vendor.id;
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const isActivesubscription = useSelector((state: any) => state.serviceSubscriptionDetails?.active_subscription ?? false);
  const SubscriptionDetails = useSelector((state: any) => state.serviceSubscriptionDetails);
  const [showloader, setShowLoader] = useState<boolean>(true);
  const [activeservicecount, setActiveServiceCount] = useState(0);
  const [servererror, setServerError] = useState('');

  useEffect(() => {
    if (vendor_id) {

      sendGetAllServiceDetails(vendor_id);
    }
  }, []);

  useEffect(() => {
    if (!isActivesubscription) {

      setShowSubscriptionModal(true);
    }
  }, [isActivesubscription]);

  useEffect(() => {
    if (services.length > 0 && !prevServicesRef.current) {

      handleSearchQuery('');
      prevServicesRef.current = true;
    }
  }, [services]);

  const handleSubscriptionRedirect = () => {
    setShowSubscriptionModal(false);
    navigate(routes.vendorsubscription);
  };

  const handleAddService = () => {

    if (isActivesubscription === true) {

      navigate(routes.vendorAddService)
    } else {

      setShowSubscriptionModal(true);
    }
  };

  const sendGetAllServiceDetails = (vendor_id: number) => {
    const payload = { vendor_id };

    apiService.getServicesListByVendorId(payload)
      .then((response: any) => {

        getAllServiceDetailsResponse(response?.data);
      })
      .catch((error) => {

        console.error('Error fetching businesses:', error);
      });
  };

  const getAllServiceDetailsResponse = (response: any) => {
    if (response) {

      const status = response?.status;
      if (status === true) {

        const responseData = response?.data;

        const activeServiceCount = responseData?.filter((service: any) => service.service_status === 1).length;
        setActiveServiceCount(activeServiceCount)
        setServices(responseData);
        setShowLoader(false);
        if (responseData?.length == 0) {

          setShowLoader(false);
        }
      }

    }
  };

  const CurrentSubscriptionDetailsCount = SubscriptionDetails?.current_package?.services_count

  const handleAddServiceNot = () => {
    navigate(routes.vendorsubscription)
  }

  const handleServerErrorclose = () => {
    setServerError('')
  }
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-12">
              <div className="provider-subtitle">
                <h6>My Services</h6>
              </div>
            </div>

          </div>
        </div>
        {/* /Page Header */}
        {isActivesubscription ? (
          <div>
            {showloader ? ('') : (
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-list">
                    {activeservicecount === CurrentSubscriptionDetailsCount && (
                      <TKN_Danger_message text={'You have reached your service limit. Please select a package to add service(s).'} onClick={handleServerErrorclose} />
                    )}
                    <ul className="nav" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <li className='add-new-service-button'>
                        {activeservicecount === CurrentSubscriptionDetailsCount ? (
                          <ButtonTextOnly_Blueoverlay label={'Select package'} route={''} fullWidth={false} className='new-button-style btn-light-primary btn-dashboard-custom' onClick={handleAddServiceNot} />
                        ) : (
                          <ButtonTextOnly_Whiteoverlay label={'Add New Service'} route={''} fullWidth={false} className='new-button-style btn-light-primary btn-dashboard-custom' onClick={handleAddService} />
                        )}
                      </li>
                      <li>
                        <SearchBar
                          placeholderText={'Search by service'}
                          icon={'Search'}
                          style={{ color: '#0445CF', fontSize: '15px' }}
                          className='form-control-new'
                          value={searchQuery}
                          onChangeText={(text) => handleSearchQuery(text)}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
            }

            <div className="tab-content pt-0">
              <div className="tab-pane active" id="active-service">
                {showloader ? (

                  <Loader showloader={showloader} style={{ marginTop: '50px' }} />
                ) : (

                  <div className="row">
                    {filteredData.length > 0 ? (
                      filteredData.map((service: any) => {
                        const hoursOpen = JSON.parse(service.hours_open);
                        return (
                          <Vendor_myservicescard
                            key={service.service_id}
                            ServiceName={service.service_name}
                            Imgsrc={`${WEB_BASE_URL}${service.service_image}`}
                            ServiceDescription={service.service_description}
                            ServiceStatus={service.service_status}
                            hoursOpen={hoursOpen}
                            ServiceEndDate={service.end_date}
                            ServiceStartDate={service.start_date}
                            ServiceId={service.service_id}
                          />
                        );
                      })
                    ) : (
                      <div className="row" style={{ height: '514px' }}>
                        <div className='no-favorites-container justify-content-center d-flex align-items-center'>
                          <h1 style={{ color: '#adb5bd' }}>No available services found</h1>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* Pagination */}
            <div className="row" style={{}}>
              <div className="col-sm-6" style={{ visibility: 'hidden' }}>
                <div className="review-entries">
                  <span>Show</span>
                  <select>
                    <option>07</option>
                    <option>08</option>
                  </select>
                  <span>entries</span>
                </div>
              </div>
              <div className="col-sm-6">

                {/*  <div className="review-pagination">
              
              <ul className="pagination">
                <li className="page-item ">
                  <ButtonNumberOnly_Colored label={1} route={'#'} className='active-new' />
                </li>
                <li className="page-item">
                  <ButtonNumberOnly_Colored label={2} route={'#'} />
                </li>
                <li className="page-item">
                  <ButtonNumberOnly_Colored label={3} route={'#'} />
                </li>
              </ul>
            </div> */}

              </div>
            </div>
            {/* /Pagination */}

            {/* Inactive Service */}
            <div className="modal fade custom-modal" id="in-active">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Inactive Service</h5>
                    <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
                  </div>
                  <div className="modal-body pt-0">
                    <div className="write-review">
                      <form action={routes.vendorservice}>
                        <p>Are you sure want to inactive this service?</p>
                        <div className="modal-submit text-end">
                          <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                          <button type="submit" className="btn btn-primary">Yes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Inactive Service */}
            {/* Active Service */}
            <div className="modal fade custom-modal" id="active">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Active Service</h5>
                    <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
                  </div>
                  <div className="modal-body pt-0">
                    <div className="write-review">
                      <form action={routes.vendorservice}>
                        <p>Are you sure want to active this service?</p>
                        <div className="modal-submit text-end">
                          <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                          <button type="submit" className="btn btn-primary">Yes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Inactive Service */}
            {/* Delete Service */}
            <div className="modal fade custom-modal" id="del-service">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-bottom-0 justify-content-between">
                    <h5 className="modal-title">Delete Service</h5>
                    <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
                  </div>
                  <div className="modal-body pt-0">
                    <div className="write-review">
                      <form action={routes.vendorservice}>
                        <p>Are you sure want to Delete this service?</p>
                        <div className="modal-submit text-end">
                          <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                          <button type="submit" className="btn btn-primary">Yes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        ) : (
          <div className="no-subscription-message">
            <div className='selectpackage-para-custom'>
              No active subscription available. Please select a package to add service(s).
            </div>
            <ButtonTextOnly_Blueoverlay label={'Select Package'} fullWidth={false} onClick={handleSubscriptionRedirect} />
          </div>

        )}
      </div >
    </div >
  );
};

export default vendorservices
