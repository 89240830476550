import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import Footer from '../../../components/footer/footer';
import { AboutUsSec } from '../../../components/about_us/about_us_components';

const AboutUs = () => {
  const routes = all_routes;

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      <div>
        <HomeHeader type={1} />
        <AboutUsSec />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
