import React from 'react';
import { Accordingwithtext } from '../about_us/about_us_extra';
import { faqData } from '../../../core/data/json/faq';
import { GeneralHeaderSection } from '../about_us/about_us_components';
import './style.css'


const Faqmain: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className="content faq-content-custom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="faq-section">
                                {faqData.map((item, index) => (
                                    <Accordingwithtext key={index} title={item.title} subtitle={item.subtitle} to={item.id} iconclassname='faq-title' />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const FaqComponent: React.FC = (props) => {
    return (
        <React.Fragment>
            <GeneralHeaderSection Pagename={'Frequently Asked Questions'} />
            <Faqmain />
        </React.Fragment>
    )
}

export { Faqmain, FaqComponent }