import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../common/useAuth';
import Verification from './settings/verification';
import ProviderAvailability from './provider-availability/provider-availability';
import ProviderBookDetails from './provider-book-details/provider-book-details';
import ProviderBooking from './provider-booking/provider-booking';
import ProviderCoupons from './provider-coupons/provider-coupons';
import ProviderAppointmentSettings from './settings/provider-appointment-settings';
import ProviderSubscription from './provider-subscription/provider-subscription';
import ProviderWalkin from './provider-walkin/provider-walkin';
import ProviderServices from './provider-service/provider-service';
import ProviderAddService from './add-service/add-service';
import ProviderEditService from './edit-service/edit-service';
import ProviderServiceList from './provider-service-list/provider-service-list';
import ProviderBusinnessprofile from './settings/provider-business-profile';
import ProviderPlan from './settings/provider-plan';
import ProviderProfileSettings from './settings/provider-profile-settings';
import ProviderProfile from './settings/provider-profile';
import ProviderDashboard from './dashboard/dashboard';
import ProviderSignupSubscription from './authentication/provider-signup-subscription';
import ReportAnIssue from './provider-report-an-issue/provider-report-an-issue';
import ProviderDeactivateAccount from './provider-deactivate/provider-deactivate';
import ProviderResetPassword from './provider-reset-password/provider-reset-password';
import CustomerDashboard from '../customers/customer-dashboard/customer-dashboard';
import ProviderBusinessProfile from './settings/provider-business-profile';
import ProviderComingSoon from './provider-coming-soon/provider-coming-soon';
import { PaginationProvider } from '../../../context/paginationContext';
import ProviderDeleteAccount from './settings/provider-delete-account';
import QRCodeScreen from './qr-code/qr-code';
import SubscriptionPaymentCheckout from './subscription-payment-checkout/subscription-payment-checkout';
import PaymentStatus from './payment-status/payment-status';

const ProvidersRoutes = () => {

  useAuth();

  const all_providers_routes = [
    {
      path: '/report-an-issue',
      name: 'provider-report-an-issue',
      element: <ReportAnIssue />,
      route: Route,
    },
    {
      path: '/deactivate',
      name: 'provider-deactivate',
      element: <ProviderDeactivateAccount />,
      route: Route,
    },
    {
      path: '/reset-password',
      name: 'provider-reset-password',
      element: <ProviderResetPassword />,
      route: Route,
    },
    {
      path: '/coming-soon',
      name: 'provider-coming-soon',
      element: <ProviderComingSoon />,
      route: Route,
    },
    {
      path: '/qr-code',
      name: 'qr-code',
      element: <QRCodeScreen />,
      route: Route,
    },
    {
      path: '*',
      name: 'NotFound',
      element: <Navigate to="/" />,
      route: Route,
    },
    {
      path: '/customer-dashboard',
      name: 'customer-dashboard',
      element: <CustomerDashboard />,
      route: Route,
    },
    {
      path: '/edit-buisiness-profile',
      name: 'provider-security-settings',
      element: <ProviderBusinessProfile />,
      route: Route,
    },
    {
      path: '/edit-personal-profile',
      name: 'provider-profile-settings',
      element: <ProviderProfileSettings />,
      route: Route,
    }, 
    {
      path: '/service',
      name: 'provider-service',
      element: <ProviderServices />,
      route: Route,
    },
    {
      path: '/service-list',
      name: 'provider-service-list',
      element: <ProviderServiceList />,
      route: Route,
    },
    {
      path: '/settings/verification',
      name: 'verfication',
      element: <Verification />,
      route: Route,
    },
    {
      path: '/edit-personal-profile',
      name: 'provider-profile-settings',
      element: <ProviderProfileSettings />,
    },
    {
      path: '/profile',
      name: 'provider-profile',
      element: <ProviderProfile />,
    },
    {
      path: '/edit-business-profile',
      name: 'provider-business-profile',
      element: <ProviderBusinnessprofile />,
    },
    {
      path: '/settings/plan',
      name: 'provider-plan',
      element: <ProviderPlan />,
    },
    {
      path: '/edit-profile',
      name: 'provider-appointment-settings',
      element: <ProviderAppointmentSettings />,
      route: Route,
    },
    {
      path: '/availability',
      name: 'provider-availability',
      element: <ProviderAvailability />,
      route: Route,
    },
    {
      path: '/book-details',
      name: 'provider-book-details',
      element: <ProviderBookDetails />,
      route: Route,
    },
    {
      path: '/booking',
      name: 'provider-booking',
      element:
        <PaginationProvider>
          <ProviderBooking />
        </PaginationProvider>,
      route: Route,
    },
    {
      path: '/coupons',
      name: 'provider-coupons',
      element: <ProviderCoupons />,
      route: Route,
    },
    {
      path: '/vendor-dashboard',
      name: 'provider-dashboard',
      element: <ProviderDashboard />,
      route: Route,
    },
    {
      path: '/add-service',
      name: 'add-service',
      element: <ProviderAddService />,
      route: Route,
    },
    {
      path: '/edit-service',
      name: 'edit-service',
      element: <ProviderEditService />,
      route: Route,
    },
    {
      path: '/delete-account',
      name: 'provider-delete-account',
      element: <ProviderDeleteAccount />,
      route: Route,
    },
    {
      path: '/walkin',
      name: 'provider-walkin',
      element: <ProviderWalkin />,
      route: Route,
    },
    {
      path: '/subscription',
      name: 'provider-social-profile',
      element: <ProviderSubscription />
    },
    {
      path: '/payment-status',
      name: 'payment-status',
      element: <PaymentStatus />,
      route: Route
    },
    {
      path: '/authentication/signup-subscription',
      name: 'provider-signup-subscription',
      element: <ProviderSignupSubscription />,
      route: Route,
    },
    {
      path: '/subscription-payment-checkout',
      name: 'subscription-payment-checkout',
      element: <SubscriptionPaymentCheckout />,
      route: Route
    },
    // {
    //   path: '/addon',
    //   name: 'providerAddon',
    //   element: <ProviderAddon />,
    //   route: Route,
    // },
    // {
    //   path: '/holiday',
    //   name: 'provider-holiday',
    //   element: <ProviderHoliday />,
    //   route: Route,
    // },
    // {
    //   path: '/notification',
    //   name: 'provider-notification',
    //   element: <ProviderNotification />,
    //   route: Route,
    // },
    // {
    //   path: '/offer',
    //   name: 'provider-offer',
    //   element: <ProviderOffer />,
    //   route: Route,
    // },
    // {
    //   path: '/earnings',
    //   name: 'provider-earnings',
    //   element: <ProviderEarnings />,
    //   route: Route,
    // },
    // {
    //   path: '/login-activity',
    //   name: 'ProviderLoginActivity',
    //   element: <ProviderLoginActivity />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/connected-app',
    //   name: 'connected-app',
    //   element: <ConnectedApp />,
    //   route: Route,
    // },
    // {
    //   path: 'settings/device-management',
    //   name: 'ProviderDeviceManagement',
    //   element: <ProviderDeviceManagement />,
    // },
    // {
    //   path: '/chat',
    //   name: 'provider-chat',
    //   element: <ProviderChat />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/connected-apps',
    //   name: 'provider-connected-apps',
    //   element: <ProviderConnectedApps />,
    //   route: Route,
    // },
    // {
    //   path: '/authentication/signup-payment',
    //   name: 'provider-signup-payment',
    //   element: <ProviderSignupPayment />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/social-profile',
    //   name: 'provider-social-profile',
    //   element: <ProviderSocialProfile />,
    //   route: Route,
    // },
    // {
    //   path: '/payout',
    //   name: 'provider-payout',
    //   element: <ProviderPayout />,
    //   route: Route,
    // },
    // {
    //   path: '/review',
    //   name: 'provider-review',
    //   element: <ProviderReview />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/payment-settings',
    //   name: 'provider-security-settings',
    //   element: <ProviderSecuritySettings />,
    //   route: Route,
    // },
    // {
    //   path: '/settings/payment-settings',
    //   name: 'provider-security-settings',
    //   element: <ProviderSecuritySettings />,
    //   route: Route,
    // },
    // {
    //   path: '/add-subscription',
    //   name: 'add-subscription',
    //   element: <AddSubscription />,
    //   route: Route,
    // },
  ];

  return (
    <>
      <Routes>
        <Route>
          {all_providers_routes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </>
  )
};

export default ProvidersRoutes;
