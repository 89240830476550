import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { all_routes } from '../../../core/data/routes/all_routes';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import en from '../../../en/en';
import { SliderWithImageCardProps } from '../../../core/models/interface';
import colors from '../../../colors/colors';
import './slider.css'
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import { useNavigate } from 'react-router-dom';
import { WEB_BASE_URL } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { TKN_modal } from '../modal/modal';
import { setvendorId } from '../../../core/data/redux/action';
import { ButtonTextOnly_Custombtn } from '../buttons/button';

const SliderWithImageCard = (props: SliderWithImageCardProps) => {

  const routes = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendorOrNot = useSelector((state: any) => state.vendor.vendor_id);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleBookNow = (vendor_id: string) => {
    dispatch(setvendorId(vendor_id));
    navigate(routes.businessDetails);
  };

  return (
    <React.Fragment>
      <Slider {...props.settings}>
        {props.services.map((service, index) => (
          <div className="service-widget aos" data-aos="fade-up" key={index}>
            <div className="service-img">
              {/* <LinkTags to={routes.businessDetails}> */}
              <ImageWithBasePathWithUrl
                className="img-fluid serv-img slider-img"
                alt="Service Image"
                src={`${WEB_BASE_URL}${service.temp_name}`}
              />
              {/* </LinkTags> */}
            </div>
            <div className="service-content">
              <h3 className="title">
                {/* <LinkTags to={routes.businessDetails}> */}
                {service.bussiness_name}
                {/* </LinkTags> */}
              </h3>
              <p>
                {' '}
                <Icon.MapPin className="standard-feather" />
                {en.LOCATION}
                <span className="rate">
                  <i className="fas fa-star filled" />
                  {en.RATE}
                </span>
              </p>
              <ButtonTextOnly_Custombtn label={en.BOOK_NOW} fullWidth={true} onClick={() => {
                {
                  vendorOrNot === 0 ? handleBookNow(service.id) : setShowModal(true);
                }
              }} className='btn-homebooknowbtn-custom btn-booknowbtn-custom' />
            </div>
          </div>
        ))}
        {showModal && (
          <TKN_modal
            description={en.OPTION_UNAVAILABLE_VENDOR}
            okbutton={en.OK}
            handleshowModal={showModal}
            handleSuccessButton={() => setShowModal(false)}
          />
        )}
      </Slider>
    </React.Fragment>
  )
}
export default SliderWithImageCard;