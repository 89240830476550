import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import ProviderBookingModal from '../../common/modals/provider-booking-modal';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { SearchBar } from '../../../components/inputfields/input';
import { ButtonIconOnly_Colored } from '../../../components/buttons/button';
import { VendorBooking } from '../../../components/provider_booking/provider_booking_component';
import PaginationComponent from '../../../components/paginationComponent/pagination';
import useGetVendorAllBookings from '../../../../hooks/getVendorAllBookings';
import { useSelector } from 'react-redux';
import { usePagination } from '../../../../context/paginationContext';
import useBookingStatusText from '../../../../helper/bookingStatusCheckFunction';
import { WEB_BASE_URL } from '../../../../constants/constants';
import en from '../../../../en/en';
import Loader from '../../../components/loader_spinner/loader_spinner';
import './provider-booking-style.css';

const ProviderBooking = () => {

  const routes = all_routes;
  const { count, setCount } = usePagination();
  const vendorId = useSelector((state: any) => state.vendor.id);
  const [searchQuery, setSearchQuery] = React.useState<any>();
  const [filteredBookings, setFilteredBookings] = useState<any[]>([]);

  const { pagesCount, bookingData, showloader } = useGetVendorAllBookings({
    vendor_id: vendorId,
    upcoming_bookings: 0,
    all_bookings: 1,
    page: count,
    limit: 6
  });

  useEffect(() => {
    setFilteredBookings(bookingData);
  }, [bookingData]);

  const handlePrevious = useCallback(() => {
    setCount((prevCount) => {
      if (prevCount > 1) {
        return prevCount - 1;
      }
      return prevCount;
    });
  }, []);

  const handleNext = useCallback(() => {
    setCount((prevCount) => {
      if (prevCount < pagesCount) {
        return prevCount + 1;
      }
      return prevCount;
    });
  }, [pagesCount]);

  const handleSearchQuery = (text: string) => {

    setSearchQuery(text);
    const trimmedText = text.trim();
    if (trimmedText === '') {

      setFilteredBookings(bookingData);
    } else {
      const filtered = bookingData.filter((item: any) =>
        item.service_name.toLowerCase().includes(trimmedText.toLowerCase()) ||
        (item.first_name + ' ' + item.last_name).toLowerCase().includes(trimmedText) ||
        item.phone.includes(trimmedText) ||
        item.email.includes(trimmedText)
      );

      setFilteredBookings(filtered);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header new-page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Booking List</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="grid-listview" >
                  <ul>
                    <li>
                      <SearchBar
                        placeholderText={'Search by service, name or phone'}
                        icon={'Search'}
                        className='form-control-new'
                        value={searchQuery}
                        onChangeText={handleSearchQuery}
                      />
                    </li>
                    <li>
                      <ButtonIconOnly_Colored icon={'Calendar'} route={routes.vendorBookDetails} className='icon-custbooking' />
                    </li>
                    <li>
                      <ButtonIconOnly_Colored icon={'Grid'} route={''} className='active active-new icon-custbooking' />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="provider-cards" >
            {showloader ? (

              <div className='w-100' style={{ paddingTop: '50px' }}>
                <Loader showloader={true} />
              </div>
            ) : (

              <React.Fragment>
                {filteredBookings.length > 0 ? filteredBookings.map((item: any, index: number) => {
                  const { text, className } = useBookingStatusText(item);

                  return (
                    <React.Fragment key={index}>
                      <VendorBooking
                        Imgsrc={item.profilephoto !== null ? WEB_BASE_URL + item.profilephoto : null}
                        PersonName={item.first_name + ' ' + item.last_name}
                        PhoneNumber={item.phone}
                        Emailid={item.email}
                        ServiceName={item.service_name}
                        Date={item.booking_for}
                        Time={item.booking_slot}
                        text={text}
                        className={className}
                        Bookingid={item.booking_id}
                        vendorMapId={item.vendor_map_id}
                        Service_id={item.service_id}
                        Status={item.status}
                        business_timezone={item.business_timezone}
                        svgImage={item.walkin !== 0 ? (
                          <ImageWithBasePath src="assets/img/icons/person-walking-white.svg" alt="image" className='person-walking-class' />)
                          : (
                            <ImageWithBasePath src="assets/img/icons/hand -white.svg" alt="image" className='hand-class' />
                          )}
                      />
                    </React.Fragment>
                  )
                }) : (
                  <div className="d-flex no-bookings justify-content-center align-items-center flex-fill">
                    <h1 className="no-bookings-h1">{en.NO_BOOKING}</h1>
                  </div>
                )}
              </React.Fragment>)}

          </div>
          <div className="row col-md-12 col-sm-12 align-items-center justify-content-center">
            <div className="d-flex justify-content-center">
              {pagesCount > 1 &&
                <div>
                  <PaginationComponent
                    totalPages={pagesCount}
                    currentPage={count}
                    handlePrevious={() => handlePrevious()}
                    handleNext={() => handleNext()}
                    handleBeginning={() => setCount(1)}
                    handleEnd={() => setCount(pagesCount)}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div><ProviderBookingModal /></>
  );
};

export default ProviderBooking;
