import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ImageWithBasePath } from "../../../../core/img/ImageWithBasePath";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { ButtonTextandIconReversed_BlueOverlay, ButtonTextandIconReversed_BlueOverlayLinkTag, ButtonTextOnly_Whiteoverlay, ButtonTextOnly_WhiteoverlayLinkTag } from "../../../components/buttons/button";
import './style.css'

const PaymentStatus: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { status = "Failed", message = "Payment Failed!!" } = location.state || {};
  const routes = all_routes;

  const getImage = () => {
    return status === "success"
      ? "assets/img/booking-done-success.png"
      : "assets/img/payment-failed.png";
  };

  const handleSuccesbtn = () => {
    navigate(routes.vendorsubscription, { replace: true })
    navigate(routes.vendorservice,)
  }

  const handleFailedbtn = () => {
    navigate(routes.vendorsubscription, { replace: true })
    navigate(routes.vendorsubscription)
  }

  const handleDashboardbtn = () => {
    navigate(routes.vendorsubscription, { replace: true })
    navigate(routes.vendorDashboard,)
  }

  return (

    <div className="container d-flex justify-content-center align-items-center bookingdone-maincontainercustom" style={{ minHeight: "80vh" }}>
      <div className="row w-100">
        <div className="bookingdone-imagedivcustom">
          <div className="booking-done bookingdone-imagedivchild-custom">
            <ImageWithBasePath src={getImage()} alt={status} className="w-100" />
          </div>
        </div>
        <div className="bookingdone-bookingdetailscustom d-flex justify-content-center align-items-center text-center">
          <div className="booking-done">
            <h6 className="bookingdonecustom-text-heading">{message}</h6>
            {status === "success" ? (
              <p className="custom-text-para">Package subscription has been successfully completed and activated</p>
            ) : (
              <p className="custom-text-para">Package subscription has been failed. Please try again.</p>
            )}
            <div className="book-submit d-flex gap-30px justify-content-center">
              {status === "success" ? (
                <ButtonTextandIconReversed_BlueOverlay icon='ArrowLeftCircle' label={"Go to services"} fullWidth={false} onClick={handleSuccesbtn} className="gap-10px h-44px" iconclassName="bookingone-icon-custom" />
              ) : (
                <ButtonTextandIconReversed_BlueOverlay icon='ArrowLeftCircle' label={"Go to subscription"} fullWidth={false} onClick={handleFailedbtn} className="gap-10px h-44px" iconclassName="bookingone-icon-custom" />
              )}
              <ButtonTextOnly_Whiteoverlay label={"Dashboard"} fullWidth={false} onClick={handleDashboardbtn} className="h-44px" />
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default PaymentStatus;
