import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import FooterOne from '../../../components/footer-one';
import { FaqComponent } from '../../../components/faq/faq';

const Faq = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      <HomeHeader type={1} />
      <FaqComponent />
      <FooterOne />
    </>
  );
};

export default Faq;
