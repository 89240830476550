import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { CheckoutSection } from "../../../components/checkoutSection/checkoutSection";
import "./subscription-payment-checkout-style.css";
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';



const SubscriptionPaymentCheckout = () => {

    const [serverError, setServerError] = useState('');
    const [serviceSubscriptions, setServiceSubscriptions] = useState([]);
    const [responseMessage, setResponseMessage] = useState<string>('');
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(null);
    const routes = all_routes;
    const [showModal, setShowModal] = useState(false);
    const [showloader, setShowLoader] = useState(true);
    const [selectedServiceDetails, setSelectedServiceDetails] = useState<any | null>(null);   

    //const SubscriptionSelected= useSelector((state: RootState) => state.serviceSubscriptionDetails);

    const SubscriptionSelected= useSelector((state: RootState) => state.serviceSubscriptionSelect);    
    console.log('SUBSCRIPTION details', SubscriptionSelected); 

    return (
         <div className="subscription-payment-checkout-container">       
        
            <CheckoutSection
                planName={SubscriptionSelected.service_subscription_name}
                planPrice={SubscriptionSelected.service_subscription_amt}
                planValidity={SubscriptionSelected.service_subscription_duration}
                planTotalServices={SubscriptionSelected.services_count}
            />
        </div>
    )
};

export default SubscriptionPaymentCheckout;