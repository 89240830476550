import React from "react";
import * as Icon from 'react-feather';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LinkTags from "../linkTags/linkTags";
import SliderWithImageCard from "../sliders/sliders";
import en from "../../../en/en";
import { popularBusinessSectionProps } from "../../../core/models/interface";
import colors from "../../../colors/colors";
import { all_routes } from "../../../core/data/routes/all_routes";
import Loader from "../loader_spinner/loader_spinner";
import { ButtonTextandIcon_BlueOverlayLinkTag } from "../buttons/button";

const PopularBusinessSection = (props: popularBusinessSectionProps) => {

    const routes = all_routes;

    return (
        <section className="service-section">
            <div className="container">
                <div className="section-heading">
                    <div className="row align-items-center">
                        <div className="col-md-6 aos" data-aos="fade-up">
                            <h2 className="home-heading-custom ">{en.MOST_POPULAR_BUSINESS}</h2>
                        </div>
                        <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                            <div className="owl-nav mynav1" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Loader showloader={props.showloader} style={{ marginBottom: '20px' }} />
                    {!props.showloader && (<div className="col-md-12">
                        <div className="popular-slider">
                            <SliderWithImageCard services={props.serviceDetailsArray} settings={props.settings} />
                        </div>
                    </div>)}
                    {props.showloadererror && (
                        <div className="col-md-12">
                            <div className="popular-slider" style={{ textAlign: 'center' }}>
                                <h1 style={{ color: '#adb5bd', fontSize: '22px', marginTop: '30px', marginBottom: '30px' }}>{props.showloadererror}</h1>
                            </div>
                        </div>
                    )}
                    <div className="btn-sec aos" data-aos="fade-up">
                        <ButtonTextandIcon_BlueOverlayLinkTag label={en.VIEW_ALL} icon={"ArrowRightCircle"} fullWidth={false} route={routes.search} className="btn btn-view h-44px viewall-btn-custom" iconclassName="standard-feather ms-2" />
                    </div>
                </div>
            </div>
        </section >
    )
};

export default PopularBusinessSection;