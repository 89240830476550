import React, { useState } from "react";
import { BookingRowSection } from "../bookingRowSection/bookingRowSection";
import DashBoardRecentSubHeading from "../dashBoardRecentSubHeading/dashBoardRecentSubHeading";
import TableTag from "../../tableTag/tableTag";
import { useSelector } from "react-redux";
import en from "../../../../en/en";
import useGetAllBookings from "../../../../hooks/getAllBookings";
import "./customerDashBoardRecentBookingStyle.css";
import PaginationComponent from "../../paginationComponent/pagination";
import { usePagination } from "../../../../context/paginationContext";
import getUserUpcomingBookings from "../../../../hooks/getUserUpcomingBookings";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../../core/data/routes/all_routes";
import Loader from "../../loader_spinner/loader_spinner";

const CustomerDashBoardRecentBooking = () => {

    const limit = 5;
    const routes = all_routes;
    const [page] = useState(1);
    const navigateToAllBookings = useNavigate();
    const { count, setCount } = usePagination();
    const userId = useSelector((state: any) => state.user.user_id);
    const { bookings, showloader } = useGetAllBookings(userId, page, limit);
    const [upcomingState, setupcomingState] = useState<boolean | undefined>();
    const { pagesCount, upcomingBookings } = getUserUpcomingBookings(userId, limit, count, 1);

    const handlePrevious = (count: number) => {
        if (count > 1) {
            setCount((prevCount) => prevCount - 1);
        }
    };

    const handleNext = (count: number) => {
        if (count < pagesCount) {
            setCount((prevCount) => prevCount + 1);
        }
    };

    const handleUpcomingBookingsClick = () => {
        setupcomingState(!upcomingState);
    };

    const handleAllBookingsClick = () => {
        navigateToAllBookings(routes.customerBooking);
    };

    return (
        <React.Fragment>
            <div className="col-lg-8 d-flex flex-column customer-dashboard-custom-width">
                <div className="widget-dashBoard-title">
                    <DashBoardRecentSubHeading
                        SubHeading={upcomingState === true ? en.UPCOMING_BOOKING : en.RECENT_BOOKING}
                        btnText={en.VIEW_ALL_BOOKINGS}
                        upcomingbtnText={upcomingState !== true ? en.VIEW_UPCOMING_BOOKINGS : en.VIEW_RECENT_BOOKING}
                        tagName={"h6"}
                        upcomingBookingsClick={handleUpcomingBookingsClick}
                        allBookingsClick={handleAllBookingsClick}
                    />
                </div>
                <Loader showloader={showloader} className="loader-custom" />
                <div className="table-responsive recent-booking d-flex flex-fill height-dashboard-booking-custom recent-booking-dashboard-custom" >
                    {!showloader && (
                        (upcomingState && upcomingBookings.length > 0) || (!upcomingState && bookings.length > 0) ? (
                            <React.Fragment>
                                <TableTag className="table booking-section mb-0 h-fit-content">
                                    <BookingRowSection dataArray={upcomingState ? upcomingBookings : bookings} />
                                </TableTag>
                            </React.Fragment>
                        ) : (
                            <div className="d-flex no-bookings justify-content-center align-items-center flex-fill">
                                <h4 className="no-bookings-h4">{en.NO_BOOKING}</h4>
                            </div>
                        )
                    )}
                </div>
                {upcomingState === true && pagesCount > 1 &&
                    <div>
                        <PaginationComponent
                            totalPages={pagesCount}
                            currentPage={count}
                            handlePrevious={() => handlePrevious(count)}
                            handleNext={() => handleNext(count)}
                            handleBeginning={() => setCount(1)}
                            handleEnd={() => setCount(pagesCount)}
                        />
                    </div>
                }
            </div>
        </React.Fragment>
    )
};

export default CustomerDashBoardRecentBooking;