import React from 'react';
import { Link } from 'react-router-dom';
import './button.css';
import * as Icon from 'react-feather';
import {
  ButtonTextandIcon_ColoredProps,
  ButtonIcononly_ColoredProps,
  ButtonTextOnly_ColoredProps,
  ButtonNumberOnly_ColoredProps,
} from '../../../core/models/interface';
import { Spinner } from 'react-bootstrap';
import { all_routes } from '../../../core/data/routes/all_routes';
interface Props {
  route: string;
  buttonText: string;
  hover: boolean;
}

const ButtonIconOnly_Colored: React.FC<ButtonIcononly_ColoredProps> = (
  Props,
) => {
  const IconComponent = Icon[Props.icon];
  return (
    <Link
      to={Props.route}
      className={`icon-style ${Props?.className}`}
      style={Props?.style}
      onClick={Props.onClick}
    >
      {IconComponent && (
        <IconComponent
          className={`react-feather-custom-new ${Props?.iconclassName}`}
          style={Props?.iconstyle}
        />
      )}
    </Link>
  );
};

const ButtonNumberOnly_Colored: React.FC<ButtonNumberOnly_ColoredProps> = (
  Props,
) => {
  return (
    <Link
      to={Props.route}
      className={`page-item-new ${Props?.className}`}
      style={Props.style}
      onClick={Props.onClick}
    >
      {Props.label}
    </Link>
  );
};



const ButtonTextOnly_Blueoverlay: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <button className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom d-flex align-justify-center ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} disabled={Props?.disabled} onKeyDown={Props?.onKeydown} type={Props?.type} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
    </button>
  );
}

const ButtonTextOnly_BlueoverlayWithChildren: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <button className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom d-flex align-justify-center ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} disabled={Props?.disabled} onKeyDown={Props?.onKeydown} type={Props?.type} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
      {Props.children}
    </button>
  );
}


const ButtonTextOnly_Whiteoverlay: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <button className={`btn-new btn-primary-new btn-view-new d-flex align-justify-center btn-whiteoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} disabled={Props?.disabled} onKeyDown={Props?.onKeydown} type={Props?.type} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
    </button>
  );
}

const ButtonTextOnly_Custombtn: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <button className={`btn-new btn-view-new d-flex align-justify-center ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} disabled={Props?.disabled} onKeyDown={Props?.onKeydown} type={Props?.type} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
    </button>
  );
}


const ButtonTextOnly_BlueoverlayLinkTag: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <Link className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom d-flex align-justify-center ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} to={Props.route ?? '#'} target={Props?.target} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
    </Link>
  );
}

const ButtonTextOnly_WhiteoverlayLinkTag: React.FC<ButtonTextOnly_ColoredProps> = (Props) => {
  return (
    <Link className={`btn-new btn-primary-new btn-view-new d-flex align-justify-center btn-whiteoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} to={Props.route ?? '#'} target={Props?.target} >
      {
        Props.isLoading ? (<Spinner style={{ width: '1.5rem', height: '1.5rem' }} />) : (Props.label)
      }
    </Link>
  );
}

const ButtonTextandIcon_BlueOverlay: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <button
      className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} onKeyDown={Props?.onKeydown} disabled={Props?.disabled} >
      {Props.label}
      {IconComponent && (<IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </button>
  )
}

const ButtonTextandIcon_WhiteOverlay: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <button
      className={`btn-new btn-primary-new btn-view-new btn-whiteoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} onKeyDown={Props?.onKeydown} disabled={Props?.disabled} >
      {Props.label}
      {IconComponent && (<IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </button>
  )
}

const ButtonTextandIconReversed_BlueOverlay: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <button
      className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} onKeyDown={Props?.onKeydown} disabled={Props?.disabled} >
      {Props.label}
      {IconComponent && (<IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </button>
  )
}

const ButtonTextandIconReversed_BlueOverlayWithChildren: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <button
      className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} onKeyDown={Props?.onKeydown} disabled={Props?.disabled} >
      {Props.label}
      {IconComponent && (<IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
      {Props.children}
    </button>
  )
}

const ButtonTextandIconReversed_WhiteOverlay: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <button
      className={`btn-new btn-primary-new btn-view-new btn-whiteoverlay-custom flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} onKeyDown={Props?.onKeydown} disabled={Props?.disabled} >
      {Props.label}
      {IconComponent && (<IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </button>
  )
}

const ButtonTextandIcon_BlueOverlayLinkTag: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <Link
      to={Props.route ?? '#'} className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} target={Props?.target} rel={Props?.rel}>
      {Props.label}
      {IconComponent && (
        <IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </Link>
  )
}

const ButtonTextandIcon_WhiteOverlayLinkTag: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <Link
      to={Props.route ?? '#'} className={`btn-new btn-primary-new btn-view-new btn-whiteoverlay-custom ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} target={Props?.target} rel={Props?.rel}>
      {Props.label}
      {IconComponent && (
        <IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </Link>
  )
}

const ButtonTextandIconReversed_BlueOverlayLinkTag: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <Link
      to={Props.route ?? '#'} className={`btn-new btn-primary-new btn-view-new btn-blueoverlay-custom flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} target={Props?.target} rel={Props?.rel}>
      {Props.label}
      {IconComponent && (
        <IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </Link>
  )
}

const ButtonTextandIconReversed_WhiteOverlayLinkTag: React.FC<ButtonTextandIcon_ColoredProps> = (Props) => {
  const IconComponent = Icon[Props.icon]
  return (
    <Link
      to={Props.route ?? '#'} className={`btn-new btn-primary-new btn-view-new btn-whiteoverlay-custom flex-reverse ${Props?.className} ${Props?.fullWidth ? 'w-100' : ''}`} style={Props?.style} onClick={Props?.onClick} type={Props?.type} target={Props?.target} rel={Props?.rel}>
      {Props.label}
      {IconComponent && (
        <IconComponent className={`standard-feather-new ms-2-new ${Props?.iconclassName}`} />
      )}
    </Link>
  )
}

export {
  ButtonIconOnly_Colored,
  ButtonNumberOnly_Colored,

  ButtonTextOnly_Blueoverlay,
  ButtonTextOnly_Whiteoverlay,
  ButtonTextOnly_BlueoverlayLinkTag,
  ButtonTextOnly_WhiteoverlayLinkTag,
  ButtonTextandIcon_BlueOverlay,
  ButtonTextandIcon_WhiteOverlay,
  ButtonTextandIconReversed_BlueOverlay,
  ButtonTextandIconReversed_WhiteOverlay,
  ButtonTextandIcon_BlueOverlayLinkTag,
  ButtonTextandIcon_WhiteOverlayLinkTag,
  ButtonTextandIconReversed_BlueOverlayLinkTag,
  ButtonTextandIconReversed_WhiteOverlayLinkTag,
  ButtonTextandIconReversed_BlueOverlayWithChildren,
  ButtonTextOnly_BlueoverlayWithChildren,
  ButtonTextOnly_Custombtn
};