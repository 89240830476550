import React from 'react';
import { useLocation } from 'react-router-dom';
import ProviderHeader from './common/header';
import DashSidebar from './dashboard/sidebar';
import HomeHeader from '../../components/header/home-header';
import ProvidersRoutes from './providers.routes';

const Providers = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  const signupPaths = [
    '/vendors/authentication/signup',
    '/vendors/authentication/signup-payment',
    '/vendors/authentication/signup-subscription',
    '/vendors/profile',
    '/vendors/authentication/business-profile',
  ];

  const sideBarAvoidPaths = [
    '/vendors/subscription-payment-checkout',
    '/vendors/payment-status'
  ]

  const isSignupPath = signupPaths.includes(pathname);
  const isSideBarAvoidPath = sideBarAvoidPaths.includes(pathname);

  return (
    <>
      {isSignupPath ? (
        <> <HomeHeader type={1} /> </>
      ) : (
        <>
          <ProviderHeader />
          {
            !isSideBarAvoidPath &&
            (
              <DashSidebar />
            )
          }
        </>
      )}
      <ProvidersRoutes />
    </>
  );
};

export default Providers;
