import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { GOOGLE_MAPS_GEOCODE_API, GOOGLE_MAPS_PLACES_API } from '../../../constants/constants';
import { useLoadGoogleMapAPI } from '../../../hooks/useLoadGoogleMapAPI';
import './customMapStyle.css';

interface CustomMapProps {
    latitude: number;
    longitude: number;
    zoomLevel?: number;
    fullScreenControl?: boolean;
    mapContainerClassName?: string;
    markerEnabled?: boolean;
    onMapClick?: (lat: number, lng: number, placeName: string) => void;
}

interface GoogleWindow extends Window {
    google?: {
        maps?: {
            Map: any;
            Marker: any;
        }
    }
}

const CustomMap = ({
    latitude,
    longitude,
    zoomLevel,
    fullScreenControl,
    mapContainerClassName,
    markerEnabled,
    onMapClick
}: CustomMapProps) => {
    const googleMaps = (window as GoogleWindow).google?.maps;
    const [marker, setMarker] = useState<any>(null);
    const isLoaded = useLoadGoogleMapAPI(GOOGLE_MAPS_PLACES_API);

    async function mapClick(event: google.maps.MapMouseEvent, mapInstance: google.maps.Map) {
        const clickedLocation = event.latLng;

        if (marker) {
            marker.setPosition(clickedLocation);
        } else {
            if (googleMaps !== undefined) {
                const newMarker = new googleMaps.Marker({
                    position: clickedLocation,
                    map: mapInstance,
                    title: "Selected Location",
                })

                setMarker(newMarker);
            } else {
                console.error("Google Maps API is not available");
            }
        }

        if (clickedLocation !== null) {
            const returnedAddress = await getplaceName(clickedLocation.lat(), clickedLocation.lng());
            onMapClick && returnedAddress !== null && onMapClick(clickedLocation.lat(), clickedLocation.lng(), returnedAddress);
        }
    }

    async function getplaceName(lat: number, lng: number): Promise<string | null> {
        try {
            const response = await axios.get(GOOGLE_MAPS_GEOCODE_API(lat, lng));

            if (response.data.results.length > 0) {
                return response.data.results[0].formatted_address;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    useEffect(() => {
        const initMap = () => {
            const mapElement = document.getElementById('map');

            if (mapElement) {
                if (googleMaps) {
                    const mapInstance = new googleMaps.Map(mapElement, {
                        center: { lat: latitude, lng: longitude },
                        zoom: zoomLevel || 12,
                        mapTypeControl: true,
                        zoomControl: true,
                        fullscreenControl: fullScreenControl ? fullScreenControl : false,
                        streetViewControl: false,
                    });

                    if (markerEnabled) {
                        const intialMarker = new googleMaps.Marker({
                            map: mapInstance,
                            position: { lat: latitude, lng: longitude },
                        })
                        setMarker(intialMarker);

                        mapInstance.addListener("click", (e: google.maps.MapMouseEvent) => mapClick(e, mapInstance));
                    }
                } else {
                    console.error("Google Maps API not found");
                }
            } else {
                console.error("Map element not found");
            }
        };

        if (isLoaded && (window as GoogleWindow).google?.maps) {
            initMap();
        }
    }, [latitude, longitude, zoomLevel, isLoaded, onMapClick]);

    return <div id="map" className={mapContainerClassName || 'map-container'} />;
};

export default CustomMap;


