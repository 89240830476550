import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ButtonTextOnly_Blueoverlay, ButtonTextOnly_Custombtn, ButtonTextOnly_Whiteoverlay } from '../buttons/button';
import './modal.css'
import en from '../../../en/en';
import { CHAR_LIMIT_DESC } from '../../../constants/constants';


interface TKN_modalprops {
  title?: any;
  description?: any;
  okbutton?: any;
  handleshowModal?: boolean;
  cancelbutton?: any;
  closeButton?: boolean;
  handleCloseModal?: any;
  handleSuccessButton?: any;
  handleCancelButton?: any;
}

interface TKN_ModalWithInputProps {
  handleshowModal?: boolean;
  handleCloseModal?: () => void;
  closeButton?: boolean;
  title?: string;
  okbutton?: string;
  cancelbutton?: string;
  handleSuccessButton?: () => void;
  handleCancelButton?: () => void;
  textareaValue?: string;
  handleTextareaChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  limitErrormodal?: string;

}

const TKN_modal: React.FC<TKN_modalprops> = (Props) => {

  return (
    <>
      <Modal show={Props.handleshowModal} onHide={Props.handleCloseModal} backdrop="static" keyboard={false} contentClassName='modal-info-custom' dialogClassName='dialog-info-custom'>
        <div className="w-100">
          <div className="icon-modal-info-custom">
            <i className="fa-solid fa-info info-icon-custom"></i>
          </div>
        </div>
        <Modal.Header closeButton={Props.closeButton} className='border-none' >
          <Modal.Title className='color-title'>{Props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='para-modal-custom'>
          <p>{Props.description}</p>
        </Modal.Body>
        {Props.cancelbutton ? (<Modal.Footer className='modal-footer'>
          <div>
            <ButtonTextOnly_Whiteoverlay label={Props.cancelbutton} fullWidth={false} onClick={Props.handleCancelButton} className='cancel-btnmodal-info-custom check-okcancel-btn-custom' />
          </div>
          <div>
            <ButtonTextOnly_Blueoverlay label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />
          </div>
        </Modal.Footer>) : (
          <Modal.Footer className='border-none modal-footer2'>
            <div>
              <ButtonTextOnly_Blueoverlay label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-info-custom check-okcancel-btn-custom' />
            </div>
          </Modal.Footer>)}
      </Modal >
    </>
  )
};

const TKN_successmodal: React.FC<TKN_modalprops> = (Props) => {

  return (
    <>
      <Modal show={Props.handleshowModal} onHide={Props.handleCloseModal} backdrop="static" keyboard={false} contentClassName='modal-success-custom' dialogClassName='dialog-info-custom'>
        <div className="w-100">
          <div className="icon-modal-custom">
            <i className="fa-solid fa-check check-icon-custom"></i>
          </div>
        </div>
        <Modal.Header closeButton={Props.closeButton} className='border-none'>
          <Modal.Title className='color-title'>{Props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='para-modal-custom'>
          <p>{Props.description}</p>
        </Modal.Body>
        {Props.cancelbutton ? (<Modal.Footer className='modal-footer'>
          <div>
            <ButtonTextOnly_Custombtn label={Props.cancelbutton} fullWidth={false} onClick={Props.handleCancelButton} className='cancel-btnmodal-success-custom check-okcancel-btn-custom' />
          </div>
          <div>
            <ButtonTextOnly_Custombtn label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-custom check-okcancel-btn-custom' />
          </div>
        </Modal.Footer>) : (
          <Modal.Footer className='border-none modal-footer2'>
            <div>
              <ButtonTextOnly_Custombtn label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-custom check-okcancel-btn-custom' />
            </div>
          </Modal.Footer>)}
      </Modal >
    </>
  )
};

const TKN_failedmodal: React.FC<TKN_modalprops> = (Props) => {

  return (
    <>
      <Modal show={Props.handleshowModal} onHide={Props.handleCloseModal} backdrop="static" keyboard={false} contentClassName='modal-failed-custom' dialogClassName='dialog-info-custom'>
        <div className="w-100">
          <div className="icon-modal-failed-custom">
            <i className="fa-solid fa-xmark xmark-icon-failed-custom"></i>
          </div>
        </div>
        <Modal.Header closeButton={Props.closeButton} className='border-none'>
          <Modal.Title className='color-title'>{Props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='para-modal-custom'>
          <p>{Props.description}</p>
        </Modal.Body>
        {Props.cancelbutton ? (<Modal.Footer className='modal-footer'>
          <div>
            <ButtonTextOnly_Custombtn label={Props.cancelbutton} fullWidth={false} onClick={Props.handleCancelButton} className='cancel-btnmodal-failed-custom check-okcancel-btn-custom' />
          </div>
          <div>
            <ButtonTextOnly_Custombtn label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-failed-custom check-okcancel-btn-custom' />
          </div>
        </Modal.Footer>) : (
          <Modal.Footer className='border-none modal-footer2'>
            <div>
              <ButtonTextOnly_Custombtn label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} className='ok-btnmodal-failed-custom check-okcancel-btn-custom' />
            </div>
          </Modal.Footer>)}
      </Modal >
    </>
  )
};

export const TKN_ModalWithInput: React.FC<TKN_ModalWithInputProps> = (Props) => {
  const [error, setError] = useState('');
  const handleSuccessBtnValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = Props.textareaValue
    if (!text) {
      setError(en.CANCELLATIONDETAILSVENDOR);
    }
    else {
      setError('')
      if (Props.handleSuccessButton) {
        Props.handleSuccessButton();
      }
    }
  }
  const handleTextareaChangemodal = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setError('')
    if (Props.handleTextareaChange) {
      Props.handleTextareaChange(e);
    }
  }

  const handleCancelbtnChangemodal = () => {
    setError('')
    if (Props.handleCancelButton) {
      Props.handleCancelButton();
    }
  }
  return (
    <>
      <Modal
        show={Props.handleshowModal}
        onHide={Props.handleCloseModal}
        backdrop="static"
        keyboard={false}
        contentClassName='modal-info-custom' dialogClassName='dialog-info-custom'
      >
        <div className="w-100">
          <div className="icon-modal-info-custom">
            <i className="fa-solid fa-info info-icon-custom"></i>
          </div>
        </div>
        <Modal.Header closeButton={Props.closeButton} className="border-none">
          <Modal.Title className="color-title">{Props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            id="textareaBox"
            value={Props.textareaValue}
            onChange={handleTextareaChangemodal}
            placeholder={en.CANCELLATIONDETAILS}
            rows={2}
            maxLength={CHAR_LIMIT_DESC}
            className='modal-textarea-custom'
          />
          {error !== '' && <div style={{ color: 'red' }}>{error}</div>}
          {Props.limitErrormodal !== '' && <div style={{ color: 'red' }}>{Props.limitErrormodal}</div>}
        </Modal.Body>
        {Props.cancelbutton ? (
          <Modal.Footer className="modal-footer">
            <div>
              <ButtonTextOnly_Whiteoverlay label={Props.cancelbutton}
                fullWidth={false}
                className='check-okcancel-btn-custom cancel-modalwithinput-custom'
                onClick={handleCancelbtnChangemodal} />
            </div>
            <div>
              <ButtonTextOnly_Blueoverlay label={Props.okbutton}
                fullWidth={false}
                onClick={handleSuccessBtnValueChange}
                className='check-okcancel-btn-custom ok-modalwithinput-custom' />
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer className="border-none modal-footer2">
            <div>
              <ButtonTextOnly_Blueoverlay label={Props.okbutton}
                fullWidth={false}
                onClick={handleSuccessBtnValueChange}
                className='check-okcancel-btn-custom ok-modalwithinput-custom' />
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export { TKN_modal, TKN_successmodal, TKN_failedmodal };