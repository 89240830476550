import React, { useEffect, useRef, useState } from "react";
import { PayPalButtons, PayPalButtonsComponentProps } from "@paypal/react-paypal-js";
import apiService from "../../../api/authentication-service";
import { useLocation, useNavigate } from "react-router-dom";
import store, { RootState } from '../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setServiceSubscriptionDetails, setServiceSubscriptionSelect, setVendorDetails } from '../../../core/data/redux/action';
import { all_routes } from "../../../core/data/routes/all_routes";


const PaypalCheckOutButton = () => {

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const requestPlatformRef = useRef<string>("web");
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const platform = params.get('pform');
        if (platform) {

            requestPlatformRef.current = platform;
        }
    }, [location]);

    const SubscriptionSelected = useSelector((state: RootState) => state.serviceSubscriptionSelect);

    const subscriptionIdRef = useRef(0);

    useEffect(() => {
        
        const subscriptionId = SubscriptionSelected?.service_subscription_id;
        if(subscriptionId) {

            subscriptionIdRef.current = subscriptionId;
        }
    }, [SubscriptionSelected]);



    const vendorId = useSelector((state: any) => {
        return state.vendor.id
    });

    const serviceSubscriptionRequestOld = (subscriptionId: string) => ({
        vendor_id: parseInt(vendorId),
        service_subscription_id: parseInt(subscriptionId),
    });

    const serviceSubscriptionRequest = () => ({
        vendor_id: parseInt(vendorId),
        service_subscription_id: subscriptionIdRef.current,
    });


    const routes = all_routes;
    const navigateTo = useNavigate();

    const handleCreateOrder = (): Promise<string> => {

        return new Promise((resolve, reject) => {

            apiService
                .addServiceSubscription(serviceSubscriptionRequest())
                .then((response) => {

                    const responseData = response?.data;

                    console.log("Paypal Create Response", responseData);

                    if (
                        responseData &&
                        responseData.status === true &&
                        responseData.data?.status === 'success'
                    ) {

                        const orderId = responseData.data?.subscription_payment_data?.paypal_order_id;
                        resolve(orderId);

                    } else {

                        reject(new Error("No valid subscription payment data received."));
                    }
                })
                .catch((error) => {

                    console.error("Error creating PayPal order:", error);
                    reject(error);
                });
        });
    };

    const handleCapturePayment = (orderId: string): Promise<void> => {
        // setLoading(true);

        const payload = {
            order_id: orderId,
            vendor_id: vendorId,
            service_subscription_id: subscriptionIdRef.current,
        };

        return new Promise((resolve, reject) => {

            apiService.captureSubscriptionPayment(payload)
                .then((response) => {

                    const responseData = response?.data;

                    console.log("Payment Capture Response:", responseData);

                    if (responseData && responseData.status === true) {

                        setMessage("Payment Completed successfully!");
                        const Newpackage = responseData.data?.current_subscription_data;
                        dispatch(setServiceSubscriptionDetails(Newpackage));

                        if (requestPlatformRef.current == "app") {

                            dispatch(setServiceSubscriptionSelect(''));
                            dispatch(setVendorDetails(''));
                            navigateTo(routes.SubscriptionPurchaseStatusSuccess);
                        } else {

                            navigateTo(routes.vendorPaymentStatus, {
                                state: { status: "success", message: "Payment Completed successfully!" },
                            });
                        }

                        resolve();
                    } else {

                        if (requestPlatformRef.current == "app") {

                            dispatch(setServiceSubscriptionSelect(''));
                            dispatch(setVendorDetails(''));
                            navigateTo(routes.SubscriptionPurchaseStatusFailed);
                        } else {

                            navigateTo(routes.vendorPaymentStatus, {
                                state: { status: "Failed", message: "Payment Failed!" },
                            });
                        }

                        setMessage("Payment Failed!");
                        reject(new Error("Payment capture failed."));
                    }
                })
                .catch((error) => {

                    console.error("Error capturing payment:", error);
                    setMessage("An error occurred during payment capture.");
                    reject(error);
                })
                .finally(() => {

                    setLoading(false);
                });
        });
    };

    return (
        <PayPalButtons
            style={{
                shape: "rect",
                layout: "vertical",
                color: "silver",
                label: "paypal",
            }}
            createOrder={handleCreateOrder}
            onApprove={(data) => {

                if (data.orderID) {

                    return handleCapturePayment(data.orderID);
                } else {

                    return Promise.reject(new Error("No order ID found."));
                }
            }}

        />
    )
};

export default PaypalCheckOutButton;