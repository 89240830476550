import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import * as Icon from 'react-feather';
import { TKN_PhoneInputComponent, TKN_TextareaPlaceholder, TKN_TextInputreqired } from '../inputfields/input';
import { TKN_Danger_message, TKN_Success_message } from '../alert_messages/alert_messages';
import en from '../../../en/en';
import apiService from '../../../api/authentication-service';
import { all_routes } from '../../../core/data/routes/all_routes';
import { validateMobileNumber } from '../../../helper/mobileNumberValidatorHelper';
import { ImageComponentCustom } from '../images/image';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import { GeneralHeaderSection } from '../about_us/about_us_components';
import { ButtonTextOnly_Blueoverlay } from '../buttons/button';

interface ContactUsAddressCardBoxProps {
    icon: keyof typeof Icon;
    title: string;
    paratag?: string;
    atag?: boolean;
    iconclass?: string;
    paratagclass?: string;
    atagclass?: string;
    classname?: string;
}

interface ContactUsAddressTagsProps {
    text?: string;
    mailtext?: string;
    maillink?: string;
    contacttext?: string;
    contactlink?: string;
}

interface ContactUsDetailsComponentProps {
    text?: string;
}

const ContactUsAddressCardBox: React.FC<ContactUsAddressCardBoxProps> = (props) => {
    const IconComponent = Icon[props.icon];
    const atagtext = [
        {
            atag: 'support@takeano.com',
            ataglink: 'support@takeano.com'
        },
        {
            atag: 'sales@takeano.com',
            ataglink: 'sales@takeano.com'
        }
    ]
    return (
        <React.Fragment>
            <div className={`col-md-6 col-lg-4 d-flex ${props.classname}`}>
                <div className="contact-info flex-fill">
                    <span>
                        {IconComponent && <IconComponent className={`icon-custom ${props.iconclass}`} />}
                    </span>
                    <div className="contact-data">
                        <h4 className='custom-text-subheading'>{props.title}</h4>
                        {props.paratag && <ContactUsAddressTags text={props.paratag} />}
                        {props.atag &&
                            <React.Fragment>
                                {atagtext.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <ContactUsAddressTags mailtext={item.atag} maillink={item.ataglink} />
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const ContactUsAddressTags: React.FC<ContactUsAddressTagsProps> = (props) => {
    return (
        <React.Fragment>
            <p className='custom-text-para'>
                {props.text}
                {props.mailtext && <a href={`mailto:${props.maillink}`} className="email-link contactusaddress-color" >
                    {props.mailtext}</a>}
                {props.contacttext && <a href={`tel:${props.contactlink}`} className="email-link contactusaddress-color" >
                    {props.contacttext}</a>}
            </p>
        </React.Fragment>
    )
}

const ContactUsDetailsComponent: React.FC<ContactUsDetailsComponentProps> = (props) => {

    type ErrorMessages = {
        fullName?: string;
        email?: string;
        phone?: string;
    };

    const routes = all_routes;
    const [countryDetails, setCountryDetails] = useState<string>('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<ErrorMessages>({});
    const [successresponsemsg, setSuccessResponseMsg] = useState('');
    const [errorresponsemsg, setErrorResponseMsg] = useState('');
    const [countryName, setCountryName] = useState<string>('');
    const maxLength = 200;
    const [countryCode, setCountryCode] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState('');
    const [phone, setPhone] = useState('');

    const validateForm = () => {
        const newErrors: ErrorMessages = {};
        if (!fullName) newErrors.fullName = 'Full name is required';
        if (!email) {
            newErrors.email = 'Email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }

        if (phone && !validateMobileNumber(countryName, phone)) {
            newErrors.phone = 'Invalid phone number for the selected country';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePhonechange = (value: any, formattedDialCode: any, countryname: any, countrydetails: any) => {
        setPhone(value);
        setCountryCode(formattedDialCode);
        setCountryName(countryname);
        setCountryDetails(countrydetails);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const payload = {
                fullname: fullName,
                email: email,
                phonenumber: phone,
                subject: subject,
                message: message
            }

            apiService.contactUsMail(payload)
                .then((response) => {
                    const responseData = response.data as { status: boolean; message?: string };
                    if (responseData.status === true) {
                        setErrorResponseMsg('');
                        setSuccessResponseMsg("Mail Sent successfully!");
                    } else {
                        setErrorResponseMsg(responseData.message || 'Failed to sent mail. Please try again.');
                    }
                })
                .catch(error => {
                    console.error('Error while sending email:', error);
                    setErrorResponseMsg('An error occurred while processing your request. Please try again.');
                });
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        if (value.length >= maxLength) {
            setDescriptionError(en.CHAR_LIMIT);
        } else {
            setDescriptionError('');
        }
        setMessage(value);
    };

    const handleCloseSuccess_msg = () => {
        setSuccessResponseMsg('');
    }

    const handleCloseDanger_msg = () => {
        setErrorResponseMsg('');
    }
    return (
        <React.Fragment>
            <h2 className='contact-heading custom-text-heading'>Get In Touch</h2>
            {successresponsemsg && <TKN_Success_message text={successresponsemsg} onClick={handleCloseSuccess_msg} />}
            {errorresponsemsg && <TKN_Danger_message text={errorresponsemsg} onClick={handleCloseDanger_msg} />}
            <p className='contact-para custom-text-para'>Whether you are a consumer or vendor, please contact us with any questions or assistance. Don&apos;t hesitate—we&apos;re here to help!</p>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <TKN_TextInputreqired
                            placeholder='Full name'
                            maxLength={100}
                            onChange={(value: string) => setFullName(value)}
                        />
                        {errors.fullName && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.fullName}</div>}

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <TKN_TextInputreqired
                            placeholder='Email'
                            maxLength={100}
                            onChange={(value: string) => setEmail(value)}
                        />
                        {errors.email && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.email}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <TKN_PhoneInputComponent onChange={handlePhonechange} required={false} />
                        {errors.phone && <div className="error" style={{ color: 'red', fontSize: '15px' }}>{errors.phone}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="col-form-label">Subject</label>
                        <TKN_TextInputreqired
                            placeholder='Subject'
                            maxLength={100}
                            onChange={(value: string) => setSubject(value)}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="col-form-label">Message</label>
                        <TKN_TextareaPlaceholder placeholderText="Message" value={message} descriptionInputError="" maxLength={500}
                            onChange={handleDescriptionChange}
                        />
                        {descriptionError && <div className="error" style={{ color: 'red' }}>{descriptionError}</div>}
                    </div>
                </div>
                <div className="col-md-12">
                    <ButtonTextOnly_Blueoverlay label={'Send Message'} fullWidth={false} onClick={handleSubmit} className='button-custom custom-text-button h-44px' type='submit' />
                </div>
            </div>
        </React.Fragment>
    )
}

const ContactUsFormMain: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <ImageComponentCustom classname='contact-img' src='assets/img/contact-us.jpg' alt='image' imgclassname='aspect-ratio-contact' />
                </div>
                <div className="col-md-6">
                    <ContactUsDetailsComponent />
                </div>
            </div>
        </React.Fragment>
    )
}

const ContactUsAddressCardmain: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className="contact-details">
                <div className="row justify-content-center">
                    <ContactUsAddressCardBox title="Email Address" icon={'Mail'} atag={true} />
                    <ContactUsAddressCardBox title="US Office" icon={'MapPin'} paratag='SAFA Logics 6545 Market Ave. North STE 100 North Canton, OH 44721, United States of America' />
                    <ContactUsAddressCardBox title="Indian Office" icon={'MapPin'} paratag='SAFA Software solutions 25/324, Cholomkunnu, Pattambi Rd, Kunnappally, Perinthalmanna, Malappuram-679322, Kerala, India' classname='contact-address-width' />
                </div>
            </div>
        </React.Fragment>
    )
}

const ContactUsMain: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className="content">
                <div className="container">
                    <ContactUsAddressCardmain />
                    <ContactUsFormMain />
                </div>
            </div>
        </React.Fragment>
    )
}

const ContactUsIMG: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className="bg-img">
                <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
                <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
                <ImageWithBasePath
                    src="assets/img/bg/feature-bg-03.png"
                    alt="img"
                    className="bgimg3"
                />
            </div>
        </React.Fragment>
    )
}

const ContactUsComponent: React.FC = (props) => {
    return (
        <React.Fragment>
            <ContactUsIMG />
            <GeneralHeaderSection Pagename={'Contact Us'} />
            <ContactUsMain />
        </React.Fragment>
    )
}
export { ContactUsAddressCardBox, ContactUsAddressTags, ContactUsDetailsComponent, ContactUsFormMain, ContactUsAddressCardmain, ContactUsMain, ContactUsIMG, ContactUsComponent }